import React, { lazy } from 'react'
import { AsyncCandidatesLibraryProps } from './AsyncCandidatesLibrary'
const LazyAsyncCandidatesLibrary = lazy(() => import('./AsyncCandidatesLibrary'))

const AsyncCandidatesLibrary = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AsyncCandidatesLibraryProps,
) => <LazyAsyncCandidatesLibrary {...props} />

export default AsyncCandidatesLibrary
