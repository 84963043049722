import React, { lazy, Suspense } from 'react'
import { PublicShowcaseDisplayProps } from './PublicShowcaseDisplay'
const LazyPublicShowcaseDisplay = lazy(() => import('./PublicShowcaseDisplay'))

const PublicShowcaseDisplay = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & PublicShowcaseDisplayProps,
) => (
    <Suspense fallback={null}>
        <LazyPublicShowcaseDisplay {...props} />
    </Suspense>
)

export default PublicShowcaseDisplay
