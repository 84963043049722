import React, { lazy } from 'react'
import { InterviewDetailsInboxProps } from './InterviewDetailsInbox'
const LazyInterviewDetailsInbox = lazy(() => import('./InterviewDetailsInbox'))

const InterviewDetailsInbox = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsInboxProps,
) => <LazyInterviewDetailsInbox {...props} />

export default InterviewDetailsInbox
