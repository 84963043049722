import React, { lazy, Suspense } from 'react'
import { ShowcaseListProps } from './ShowcaseList'
const LazyShowcaseList = lazy(() => import('./ShowcaseList'))

const ShowcaseList = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ShowcaseListProps,
) => (
    <Suspense fallback={null}>
        <LazyShowcaseList {...props} />
    </Suspense>
)

export default ShowcaseList
