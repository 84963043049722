import React, { lazy, Suspense } from 'react'
import { SettingsProps } from './Settings'
const LazySettings = lazy(() => import('./Settings'))

const Settings = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & SettingsProps,
) => (
    <Suspense fallback={null}>
        <LazySettings {...props} />
    </Suspense>
)

export default Settings
