import { useQueryClient } from '@tanstack/react-query'
import {
    createSearchParams,
    NavigateOptions,
    To,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ContentItem } from '../artifact_service'
import { Feed, FeedChannelInfo } from '../feed_services'
import { RoleRepResponseVideo } from '../role_rep_response_video_service'
import { RoleSearch } from '../role_search_service'
import { useEffect } from 'react'
import { CandidateShowcase } from 'services/showcase_service'

export const EMAIL_REGEX = /[^@\s]+@(([^.@]+[.:])+[^.@]+|localhost)$/i // intentionally overly broad, cause I'd rather risk a false positive than a false negative
export const JOB_BOARD_COMPANY_TOKEN = 'CO_wtJobsX'
export const isValidLinkOrEmpty = (potentialLink: string) => {
    let parsedUrl = { protocol: '' }
    try {
        parsedUrl = new URL(potentialLink)
    } catch (e) {
        // pass - this will happen if the link is missing a protocol entirely
    }
    const looksLikeARealProtocol =
        parsedUrl?.protocol === 'http:' || parsedUrl?.protocol === 'https:'
    return !potentialLink || looksLikeARealProtocol
}

export const sortMaybeNumbers = (c1: string, c2: string) =>
    Number.isInteger(+c1.replace('+', '').split('-')[0]) &&
    Number.isInteger(+c2.replace('+', '').split('-')[0])
        ? Number(c1.replace('+', '').split('-')[0]) - Number(c2.replace('+', '').split('-')[0])
        : c1.localeCompare(c2)

export const capitalizeWords = (s?: string) =>
    s
        ?.replace('_', ' ')
        ?.split(' ')
        ?.map(w => w[0].toUpperCase() + w.slice(1))
        ?.join(' ')
export const camelCaseToHuman = (s: string) =>
    s
        .split(/(?=[A-Z])/)
        .map(s => s.toLowerCase())
        .map(w => w[0].toUpperCase() + w.substring(1))
        .join(' ')
export const bytesToHuman = (size: number) => {
    const i = Math.floor(Math.log(size || 1) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}
export const useWtNavigate = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const wtNaviagte = (to: number | To, options?: NavigateOptions) => {
        if (typeof to === 'number') {
            return navigate(to)
        }
        if (!to.search) {
            const oldParams = {
                u: searchParams.get('u'),
                s: searchParams.get('s'),
                c: searchParams.get('c'),
            }
            const newParams: { [key: string]: string } = {}
            Object.entries(oldParams).forEach(([key, value]) => {
                if (typeof value === 'string') {
                    newParams[key] = value
                }
            })
            to.search = `?${createSearchParams(newParams)}`
        }
        return navigate(to, options)
    }
    return wtNaviagte
}
export const LANG_NAME_BY_CODE: Record<string, string> = {
    en: 'English',
    de: 'German',
    es: 'Spanish',
} as const

export const localizedPercent = (peruni: number, fractionDigits: number = 2) =>
    peruni.toLocaleString(undefined, {
        style: 'percent',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
    })
export const divideWithZeroFallback = (num: number, den: number) => (den === 0 ? 0 : num / den)

export const percentageFormatter = (num: number, den: number) =>
    localizedPercent(divideWithZeroFallback(num, den))

export const getInitials = (owner: { friendly_name?: string; email: string } | null) => {
    const name = owner?.friendly_name
        ?.split(' ')
        ?.map(w => w[0]?.toUpperCase())
        ?.join('')
    const emailNameInitials =
        owner?.email
            .split('@')[0]
            .split('.')
            .map(w => w[0]?.toUpperCase())
            .join('') ?? ''

    const emailDomain = owner ? owner.email.split('@').slice(-1)[0].split('.').slice(-2)[0] : ''
    const emailDomainInitial = emailDomain?.[0]?.toUpperCase() ?? ''
    return (
        name ||
        (emailNameInitials.length > 1
            ? emailNameInitials
            : `${emailNameInitials}@${emailDomainInitial}`) ||
        'None'
    )
}

export const getInitialsBackgroundColor = (initials: string) => {
    if (initials === 'None') {
        return '#bd1e1e'
    }
    const colorChoices = ['#80A6E9', '#CFB756', '#4FBC74', '#B24848']
    const kindOfHash = initials.split('').reduce(
        (memo, next) => {
            let m = (memo << 5) - memo + next.charCodeAt(0)
            m = m & m
            return m
        },

        0,
    )
    return colorChoices[Math.abs(kindOfHash) % colorChoices.length]
}

export const getRandomStringPoorly = () => {
    const array = new Uint32Array(4)
    window.crypto.getRandomValues(array)
    return Array.from(array.values())
        .map(v => v.toString(36))
        .join('')
}

export const getColorByBgColor = (bgColor: string) => {
    // note to callers - make sure the color is a full 6 digit hex.  #fff will not return black, but #ffffff will
    if (!bgColor) {
        return ''
    }
    bgColor = bgColor.replace('#', '')
    const r = parseInt(bgColor.slice(0, 2), 16)
    const g = parseInt(bgColor.slice(2, 4), 16)
    const b = parseInt(bgColor.slice(4, 6), 16)

    // see function in candfe for justification
    const threshold = 150
    return r * 0.299 + g * 0.587 + b * 0.114 > threshold ? '#000000' : '#ffffff'
    // return r + g + b >= (255 * 3) / 3 ? '#000000' : '#ffffff'
}

export function reorderList<T>(list: T[], currentIndex: number, newIndex: number, item: T): T[] {
    const newList = [...list]
    newList.splice(currentIndex, 1)
    newList.splice(newIndex, 0, item)
    return newList
}

export const pollableEndpoint = async <T,>(req: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    let res: AxiosResponse<T> = await axios(req)
    while (res.status === 202) {
        const sleepTime = Number(res.headers['wt-retry-in']) || 2000
        await new Promise(r => setTimeout(r, sleepTime))
        res = await axios({
            ...req,
            headers: {
                ...req.headers,
                'x-wt-polling-request': res.headers['x-wt-polling-request'] || 1,
            },
        })
    }
    return res
}

export const noPaddingModalStyle = (
    <style>
        {
            //applies to wrap class ( wrapClassName="wt-modal-wrap-no-padding" )
            `
                .wt-modal-wrap-no-padding .ant-modal-content{
                    padding: 0;
                }
                `
        }
    </style>
)

export const isRoleRepResponseVideo = (
    item: ContentItem | FeedChannelInfo,
): item is RoleRepResponseVideo => item.token.startsWith('RRRV')

export const isRoleSearch = (item: ContentItem): item is RoleSearch => item.token.startsWith('RS')
export const isFeed = (item: ContentItem): item is Feed => item.token.startsWith('FC')
export const isShowcase = (item: ContentItem): item is CandidateShowcase =>
    item.token.startsWith('CSH')
export const isFeedOrRoleSearch = (item: ContentItem): item is ContentItem & (Feed | RoleSearch) =>
    /^(FC|RS)_/.test(item.token)

export function isNotNull<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined
}

export const DEFAULT_ROLE_RELATIONSHIP_SHORTNAMES = ['Hiring Manager', 'Recruiter', 'Team Lead']

export const dedupeArr = <T extends string | number | symbol>() => {
    const m = {} as Record<T, undefined | boolean>
    return (v: T | undefined) => {
        if (v === undefined || m[v]) {
            return false
        }
        m[v] = true
        return true
    }
}

export const useFavicon = (url?: string) => {
    useEffect(() => {
        if (url) {
            const link: HTMLLinkElement =
                document.querySelector("head>link[rel='icon']") ?? document.createElement('link')
            const prev = link.href

            link.rel = 'icon'
            link.href = url
            document.getElementsByTagName('head')[0].appendChild(link)
            return () => {
                link.href = prev
            }
        }
    })
}
export const useTitle = (title: string | undefined, removeWednesday?: boolean) => {
    const qc = useQueryClient()
    useEffect(() => {
        if (!title) {
            return
        }
        const newTitle = title
        const updateTitle = (titles?: string[]) => {
            if (titles?.length) {
                document.title = (!removeWednesday ? 'Wednesday - ' : '') + titles.join('⁃')
            } else {
                document.title = 'Wednesday'
            }
        }
        const titles = qc.setQueryData<string[]>(['pagetitle'], old => {
            return [...(old ?? []), newTitle].filter(s => s.length)
        })
        updateTitle(titles)
        return () => {
            const titles = qc.setQueryData<string[]>(['pagetitle'], old => {
                return (old ?? []).filter(v => v !== newTitle)
            })
            updateTitle(titles)
        }
    }, [qc, removeWednesday, title])
}
