import { Button } from 'antd'

import InterviewIcon from 'assets/film 1.svg?react'
import LibraryIcon from 'assets/video-gallery-outline.svg?react'
import ChannelsIcon from 'assets/channels-icon.svg?react'
import GridIcon from 'assets/grid.svg?react'
import GridLayoutIcon from 'assets/grid-layout.svg?react'
import BarChartIcon from 'assets/barchart.svg?react'
import ProjectsIcon from 'assets/playlist 1.svg?react'
import { useUserInfo } from './user_service'
import { PlusOutlined } from '@ant-design/icons'
import { useUserIsStaff } from './login_service'
import { Link, useLocation } from 'react-router-dom'
import { useWtNavigate } from './utils/CommonUtils'
import { ReactNode, useCallback, useMemo } from 'react'
import { useCompanyDetails } from './company_service'
import ApplicantsIcon from 'assets/ic24-users.svg?react'
import ListIcon from 'assets/list.svg?react'
import UserFrameIcon from 'assets/user-s-frame-3.svg?react'

interface MenuGroup {
    key: string
    label: ReactNode
    children: MenuOption[]
    type: 'group'
    icon: ReactNode
}

type PermKey = 'outbox' | 'content' | 'channels' | 'applicants'
export interface MenuOption {
    key: string
    permKey?: PermKey
    label: string | ReactNode
    icon: JSX.Element
    quickAddPath?: string
    disabled?: boolean
    children?: MenuOption[]
    staffOnly?: boolean
    betaTab?: boolean
}
export const BaseMenuOptions: MenuOption[] = []
export const CompanyMenuOptions: MenuOption[] = [
    {
        key: 'ccontent/requests',
        label: 'Requests',
        permKey: 'outbox',
        // quickAddPath: 'outbox/requests',
        icon: <InterviewIcon />,
    },
    {
        key: 'ccontent/library',
        permKey: 'content',
        label: 'Videos',
        // quickAddPath: 'content',
        icon: <LibraryIcon />,
    },

    {
        key: 'ccontent/channels',
        permKey: 'channels',
        icon: <ChannelsIcon />,
        label: 'Channels',
        // quickAddPath: 'channels2',
    },
    // {
    //     key: 'ccontent/videoPostings',
    //     permKey: 'channels',
    //     icon: <ChannelsIcon />,
    //     label: 'Video Postings',
    //     // quickAddPath: 'channels2',
    // },
    {
        key: 'projects',
        icon: <GridLayoutIcon />,
        label: 'Projects',
        // quickAddPath: 'projects',
    },
    {
        key: 'analytics',
        label: 'Analytics',
        icon: <BarChartIcon />,
        // quickAddPAth: undefined,
    },
]
export const CandidateMenuOptions: MenuOption[] = [
    {
        key: 'candidates/jobs',
        label: 'Jobs',
        staffOnly: true,
        icon: <ProjectsIcon />,
        quickAddPath: undefined,
        betaTab: true,
    },
    {
        key: 'candidates/list',
        label: 'Interviews',
        staffOnly: true,
        icon: <ListIcon />,
        betaTab: true,
    },
    {
        key: 'candidates/applicants2',
        label: 'Responses',
        staffOnly: true,
        icon: <UserFrameIcon />,
        quickAddPath: undefined,
        betaTab: true,
    },
    {
        key: 'candidates/showcases',
        label: 'Shortlists',
        // disabled: true,
        staffOnly: true,
        icon: <GridIcon />,
        quickAddPath: undefined,
        betaTab: true,
    },
    {
        key: 'candidates/applicants',
        label: `Talent`,
        permKey: 'applicants',
        icon: <ApplicantsIcon />,
    },
]

export const MenuOptions = [...CompanyMenuOptions, ...CandidateMenuOptions, ...BaseMenuOptions]
const keys = MenuOptions.map(f => f.key)
export type MenuOptionKey = (typeof keys)[number]

export const useMenuOptions = () => {
    const navigate = useWtNavigate()
    //TODO user reconcile old tabPermissions new preferences&permissions; I thinkkk they mostly overlap so we can probably re-use the same field
    const { data: userInfo } = useUserInfo()
    const isStaff = useUserIsStaff()
    const location = useLocation()
    const { data: companyDetails } = useCompanyDetails()
    const modalOpenSearch = useMemo(() => {
        const search = location.search
        const params = new URLSearchParams(search)
        params.append('add', '1')
        return params.toString()
    }, [location])

    const expandMenuOption = useCallback(
        (mo: MenuOption) =>
            ({
                key: mo.key,
                icon: mo.icon,
                label: (
                    <Link
                        to={{
                            pathname: '/' + mo.key,
                            search: window.location.search,
                        }}
                    >
                        <div
                            className={
                                mo.staffOnly &&
                                !(
                                    mo.betaTab &&
                                    companyDetails?.settings?.activeBetaTabs?.includes(mo.key)
                                )
                                    ? 'staff-only'
                                    : ''
                            }
                            style={{
                                display: 'inline-flex',
                                width: 'calc(100% - 16px - 16px)',
                            }}
                        >
                            <span>{mo.label}</span>

                            {mo.quickAddPath ? (
                                <Button
                                    icon={<PlusOutlined />}
                                    type="text"
                                    style={{
                                        position: 'absolute',
                                        right: '16px',
                                    }}
                                    className="show-on-hover"
                                    onClick={e => {
                                        // this is here rather than wrapping the button
                                        // in a link because the entire menu item
                                        // is also wrapped in a link, and react
                                        // throws a warning about nexting <a>
                                        // inside <a> being invalid html
                                        e.preventDefault()
                                        navigate({
                                            pathname: mo.quickAddPath,
                                            search: modalOpenSearch,
                                        })
                                    }}
                                />
                            ) : null}
                        </div>
                    </Link>
                ),
            } as MenuOption),
        [],
    )

    const filterPerm = useCallback(
        ({ key, permKey }: MenuOption): boolean | { pinPrecedence: number } => {
            if (companyDetails?.settings?.hiddenTabNames?.includes(permKey ?? key)) {
                return false
            }
            return userInfo?.tab_permissions[permKey ?? key] ?? (isStaff ? true : true)
        },
        [companyDetails?.settings?.hiddenTabNames, isStaff, userInfo?.tab_permissions],
    )
    const contentMenu: MenuGroup = {
        key: 'ccontent',
        label: 'Content',
        // quickAddPath: 'content',
        type: 'group',
        children: CompanyMenuOptions.filter(
            (mo: MenuOption) =>
                isStaff ||
                !mo?.staffOnly ||
                (mo.betaTab && companyDetails?.settings?.activeBetaTabs?.includes(mo.key)),
        )
            .filter(filterPerm)
            .map(expandMenuOption),
        icon: <LibraryIcon />,
    }

    const candidateMenu: MenuGroup = {
        key: 'candidate',
        label: 'Candidates',
        type: 'group',
        children: CandidateMenuOptions.filter(
            (mo: MenuOption) =>
                isStaff ||
                !mo?.staffOnly ||
                (mo.betaTab && companyDetails?.settings?.activeBetaTabs?.includes(mo.key)),
        )
            .filter(filterPerm)
            .map(expandMenuOption),
        icon: <InterviewIcon style={{ transform: 'scale(-1,1)' }} />,
    }

    const baseMenu: MenuOption[] = BaseMenuOptions.filter(
        (mo: MenuOption) => isStaff || !mo?.staffOnly,
    )
        .filter(mo => userInfo?.tab_permissions[mo.key] ?? (isStaff ? true : true))

        .map(expandMenuOption)

    return { contentMenu, candidateMenu, baseMenu }
}
