import React, { lazy, Suspense } from 'react'
import { PublishedContentProps } from './PublishedContent'
const LazyPublishedContent = lazy(() => import('./PublishedContent'))

const PublishedContent = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PublishedContentProps,
) => (
    <Suspense fallback={null}>
        <LazyPublishedContent {...props} />
    </Suspense>
)

export default PublishedContent
