import axios from 'axios'
import { RoleSearch } from 'services/role_search_service'
import { useQuery, useQueries, useQueryClient, useMutation } from '@tanstack/react-query'
import { useSpoofCompanyId } from 'hooks/useSpoofCompanyId'
import { useMemo } from 'react'

export class ExternalService {
    // The available options here should match the ExternalIntegrationServices options in ats_backend/consts.py
    // see https://stackoverflow.com/a/51398471/3923772 - i basically wanted an enum with a complex value, this is close
    // add new consts as here
    static readonly GREENHOUSE_JOB_BOARD = new ExternalService(
        'GREENHOUSE_JOB_BOARD',
        'Greenhouse Job Board',
        true,
        false,
    )
    static readonly GREENHOUSE_HARVEST = new ExternalService(
        'GREENHOUSE_HARVEST',
        'Greenhouse Harvest',
        true,
        false,
    )
    static readonly LEVER = new ExternalService('LEVER', 'Lever', true, true)
    static readonly GREENHOUSE_INGEST = new ExternalService(
        'GREENHOUSE_INGEST',
        'Greenhouse Ingestion API',
        true,
        true,
    )
    static readonly MERGE_HRIS = new ExternalService('MERGE_HRIS', 'HRIS', true, false)
    static readonly MERGE_ATS = new ExternalService('MERGE_ATS', 'Other ATS', true, true)
    static readonly WORKDAY = new ExternalService('WORKDAY', 'Workday', true, false)
    static readonly XML_FEED = new ExternalService('XML_FEED', 'XML Feed', true, false)
    static readonly JSON_API = new ExternalService('JSON_API', 'JSON API', true, false)

    // and leave this alone
    private constructor(
        private readonly _id: string,
        private readonly _name: string,
        private readonly _interactive: boolean,
        private readonly _canSubmitCandidates: boolean,
    ) {}

    public get name() {
        return this._name
    }
    public get id() {
        return this._id
    }
    public get interactive() {
        return this._interactive
    } // this maps to something like 'can i do something in wednesday after i hook it up, like import jobs' (vs something that is only a place to put candidates, for example)

    public get canSubmitCandidates() {
        return this._canSubmitCandidates
    }

    public static getEnumByID(id: ExternalService['id']) {
        return (
            Object.keys(ExternalService)
                // @ts-ignore
                .map(key => ExternalService[key])
                .find(val => val?.id === id)
        )
    }
}
export interface ExternalIntegration {
    service: ExternalService
}

const integrationUsersQueryArg = (serviceId: ExternalService['id']) => {
    const _get = async (serviceId: ExternalService['id']) => {
        const res = await axios.get<{
            users: { label: string; value: string; email?: string; site_admin: boolean }[]
        }>('/api/integrations/users/' + serviceId)
        return res.data.users.map(u => ({ ...u, serviceId }))
    }
    return {
        queryKey: ['integrationUsers', serviceId],
        queryFn: () => _get(serviceId),
    }
}
export const useIntegrationUsers = (serviceId: ExternalService['id']) => {
    const query = useQuery(integrationUsersQueryArg(serviceId))
    return query
}
export const useAllIntegrationUsers = () => {
    const integrations = useConfiguredExternalIntegrations()
    const users = useQueries({
        queries: integrations.data?.map(i => ({ ...integrationUsersQueryArg(i.service.id) })) ?? [],
        combine: results => results.flatMap(q => q.data ?? []),
    })
    return users
}

export const useUpdateIntegration = () => {
    const _patch = async (serviceId: ExternalService['id'], newInfo: Record<string, any>) => {
        const res = await axios.patch('/api/external_integrations/' + serviceId, { newInfo })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            serviceId,
            newInfo,
        }: {
            serviceId: ExternalService['id']
            newInfo: Record<string, any>
        }) => _patch(serviceId, newInfo),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['external_integrations_for_company'],
            })
            await queryClient.cancelQueries({
                queryKey: ['company_details_for_company'],
            })
            await queryClient.cancelQueries({
                queryKey: ['integrationUsers'],
            })
        },

        onSettled: (newIntegration: ExternalIntegration | undefined) => {
            queryClient.invalidateQueries({
                queryKey: ['external_integrations_for_company'],
            })
            // need to rereun this to fetch jobs from the new integration
            queryClient.invalidateQueries({
                queryKey: ['external_jobs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['integrationUsers'],
            })
        },
    })
    return mutation
}

export const addIntegration = async (creds: any, service: ExternalService | null) => {
    const res = await axios.post<{
        service_name: ExternalService['name']
        service_id: ExternalService['id']
        creds: Record<string, any>
    }>('/api/external_integrations', { creds, service: service?.id ?? null })
    return { service: ExternalService.getEnumByID(res.data.service_id) }
}
const getIntegrationInfo = async (): Promise<ExternalIntegration[]> => {
    const res = await axios.get<
        {
            service_name: ExternalService['name']
            service_id: ExternalService['id']
        }[]
    >(`/api/external_integrations`)
    return res.data.map(ei => ({
        service: ExternalService.getEnumByID(ei.service_id),
    }))
}

export const useConfiguredExternalIntegrations = (enabled = true) => {
    const spoofCompanyId = useSpoofCompanyId()
    const query = useQuery({
        queryKey: ['external_integrations_for_company', spoofCompanyId],
        queryFn: getIntegrationInfo,
        enabled,
    })
    return query
}

export const getAvailableIntegrations = async () => {
    const res = await axios.get<{ integrations: string[] }>(`/api/available_integrations`)
    return res.data.integrations.map(i => ({
        service: ExternalService.getEnumByID(i),
    }))
}

export interface JobListJob {
    name: string
    job_id: string
    internal_id: string
    service: ExternalService
    location: string
    department: string
    team: string
    external_url: string
    updated_at: string // iso string
    req_number: string
}
export const isJobListJob = (item?: Record<string, any>): item is JobListJob =>
    !!item?.service && !!item?.job_id

interface _JobListJob {
    name: string
    job_id: string
    internal_id: string
    service: string
    external_url: string
    location: string
    team: string
    department: string
    updated_at: string // iso string
    req_number: string
}
const getJobsList = async (): Promise<JobListJob[]> => {
    const res = await axios.get<{ jobs: _JobListJob[] }>('/api/integrations/jobs')
    return res.data.jobs.map(j => ({
        ...j,
        service: ExternalService.getEnumByID(j.service),
    }))
}

export const useExternalJobsList = () => {
    const query = useQuery({
        queryKey: ['external_jobs'],
        queryFn: getJobsList,
        refetchOnWindowFocus: false,
    })
    return query
}

export const useImportExternalJobMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ externalJob, asFeed }: { externalJob: JobListJob; asFeed?: boolean }) =>
            importRoleSearch(
                {
                    service_id: externalJob.service.id,
                    job_id: externalJob.job_id,
                    internal_id: externalJob.internal_id,
                },
                asFeed ?? false,
            ),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
            await queryClient.cancelQueries({
                queryKey: ['external_jobs'],
            })
            await queryClient.cancelQueries({
                queryKey: ['knownExternalJobIds'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSuccess: (newRoleSearch: RoleSearch) => {
            const oldList: RoleSearch[] = queryClient.getQueryData(['roleSearches']) ?? []
            const newList = [...oldList, newRoleSearch]
            queryClient.setQueryData(['roleSearches'], newList)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
            queryClient.invalidateQueries({
                queryKey: ['external_jobs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['knownExternalJobIds'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export interface ImportableJobInfo {
    service_id: ExternalService['id']
    job_id: string
    internal_id: string
}

export const importRoleSearch = async (
    job: ImportableJobInfo,
    asFeed: boolean,
    startAsV8?: boolean,
) => {
    const res = await axios.post<RoleSearch>(`/api/integrations/import_job`, {
        service_id: job.service_id,
        job_id: { job_id: job.job_id, internal_id: job.internal_id },
        asFeed,
        startAsV8,
    })
    return res.data
}

const getOAuthInitUrl = async (service_id: ExternalService['id']) => {
    const res = await axios.get<{ url: string }>(
        `/api/integrations/get_oauth_init_url/${service_id}`,
    )
    return res.data.url
}

export const useOAuthInitUrl = (service_id: ExternalService['id']) => {
    const spoofCompanyId = useSpoofCompanyId()
    const query = useQuery({
        queryKey: ['get_oauth_url', spoofCompanyId, service_id],

        queryFn: () => getOAuthInitUrl(service_id),
    })
    return query
}

export const initMergeLink = async (service_id: ExternalService['id']) => {
    const res = await axios.get<{ linkToken: string }>(
        '/api/integrations/init_merge_link/' +
            (service_id === ExternalService.MERGE_HRIS.id ? 'hris' : 'ats'),
    )
    return res.data.linkToken
}

export const finishMergeLink = async (service_id: ExternalService['id'], publicToken: string) => {
    const res = await axios.get(
        '/api/integrations/complete_merge_link/' +
            (service_id === ExternalService.MERGE_HRIS.id ? 'hris' : 'ats') +
            `/${publicToken}`,
    )
    return res.data
}

export const useLinkedinOauthLink = () => {
    const getLink = async () => {
        const res = await axios.get<{ url: string }>('/api/get_init_linkedin_oauth')
        return res.data.url
    }
    const query = useQuery({
        queryKey: ['linkedinOauthInit'],
        queryFn: getLink,
    })
    return query.data ?? ''
}

export interface InterviewStage {
    ident: string
    name: string
}
export const useInterviewStages = (externalJobId?: string) => {
    const _get = async () => {
        const res = await axios.get<{ stages: InterviewStage[] }>(
            '/api/integrations/stages/' + (externalJobId || ''),
        )
        return res.data.stages
    }
    const query = useQuery({ queryKey: ['interviewStages', externalJobId], queryFn: _get })
    return query
}

export const useAddIntegration = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ creds, service }: { creds: any; service: ExternalService | null }) =>
            addIntegration(creds, service),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['external_integrations_for_company'],
            })
            await queryClient.cancelQueries({
                queryKey: ['integrationUsers'],
            })
            await queryClient.cancelQueries({
                queryKey: ['company_details_for_company'],
            })
        },

        onSettled: (newIntegration: ExternalIntegration | undefined) => {
            queryClient.invalidateQueries({
                queryKey: ['external_integrations_for_company'],
            })
            // need to rereun this to fetch jobs from the new integration
            queryClient.invalidateQueries({
                queryKey: ['external_jobs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['integrationUsers'],
            })
        },
    })
    return mutation
}
