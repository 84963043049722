import React, { lazy, Suspense } from 'react'
import { ChannelMetricsProps } from './ChannelMetrics'
const LazyChannelMetrics = lazy(() => import('./ChannelMetrics'))

const ChannelMetrics = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelMetricsProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelMetrics {...props} />
    </Suspense>
)

export default ChannelMetrics
