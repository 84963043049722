import { ReactNode, lazy, useState } from 'react'
import { logout, useUserIsStaff } from 'services/login_service'
import { useUserInfo } from 'services/user_service'
import { ClientPermissions } from 'services/permission_service'

import {
    useNavigate,
    useLocation,
    Link,
    useSearchParams,
    createSearchParams,
} from 'react-router-dom'
import { Layout, Dropdown } from 'antd'
import styled, { css } from 'styled-components'
import { DownOutlined } from '@ant-design/icons'

import { useCareersPageExists } from 'services/BackstageUtils'
import { useCompanyDetails } from 'services/company_service'

import Favicon from 'assets/favicon-dark.svg?react'
import { NavLink } from 'react-router-dom'
import { Spacer } from 'components/Spacer'

import { useUserPermissions } from 'hooks/useHasPermission'
import { JsxElement } from 'typescript'
import { MainMenuOptions, MainMenuIcons } from './MainMenuOptions'
import { CustomMenu } from './HeaderIcon'

const NotificationCenter = lazy(() =>
    import('components/NotificationCenter/NotificationCenter').then(m => ({
        default: m.NotificationCenter,
    })),
)
const { Header: AntHeader } = Layout

type MainMenuItemName =
    | 'home'
    | 'channels'
    | 'channels2'
    | 'applicants'
    | 'people'
    | 'interviews'
    | 'settings'
    | 'library'
    | 'collections'
    | 'outbox'
    | 'analytics'
    | 'contributors'
    | 'artifacts'
    | 'content'
export interface MenuItem {
    name: MainMenuItemName
    displayName: string
    staffOnly?: boolean
    icon?: ReactNode
    requiredPermission?: ClientPermissions
    defaultVisibility: boolean
    component?: JsxElement
    accountToggleable?: boolean
}

export const MenuOptionByName = Object.fromEntries(MainMenuOptions.map(mo => [mo.name, mo]))
const TopBar = styled(AntHeader)<{ $shouldBeWhite?: boolean; $shouldBeFixed: boolean }>`
    background: #f4f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    height: 61px;
    @media screen and (max-width: 900px) {
        padding: 0 4px;
        .ant-avatar {
            display: none;
        }
    }

    .ant-avatar {
        margin-right: 8px;
        z-index: -1;
    }

    .ant-dropdown-link {
        color: #000;

        & > .anticon {
            margin-left: 10px;
        }
    }

    ${({ $shouldBeWhite }) =>
        $shouldBeWhite &&
        css`
            background: #fff;
            box-shadow: 0px 1px 0px #d5d6d8;
        `}

    ${({ $shouldBeFixed }) =>
        $shouldBeFixed &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            width: 100vw;
        `}
`

interface HeaderProps {
    centerComponent?: React.ReactElement
}

const RoleMenu = ({}) => {
    const isStaff = useUserIsStaff()
    const { data: userPermissions } = useUserPermissions()
    const { data: userInfo } = useUserInfo()
    const { data: companyDetails } = useCompanyDetails()

    // const _companyMenuData = companyMenuUnfiltered.filter(({ requiredPermission: reqPerm }) => {
    //     return reqPerm ? userPermissions?.includes(reqPerm) ?? false : true
    // })
    let menuData = MainMenuOptions
        // .concat(_companyMenuData)
        .filter(({ staffOnly }) => isStaff || !staffOnly)
        .filter(
            ({ name, defaultVisibility }) =>
                userInfo?.tab_permissions[name] ?? (isStaff ? true : defaultVisibility),
        )
        .filter(({ requiredPermission: reqPerm }) => {
            return reqPerm ? (userPermissions?.includes(reqPerm) ?? false) || isStaff : true
        })
        .filter(({ name }) => !companyDetails?.settings?.hiddenTabNames?.includes(name))
        .filter(({ name }) =>
            isStaff
                ? userInfo?.settings?.staffPermissions?.visibleTabsByCompany?.[
                      companyDetails?.token ?? ''
                  ]?.[name] ?? true
                : true,
        )
    if (menuData.length <= 1) {
        menuData = []
    }
    const location = useLocation()
    const path_0 = location.pathname.split('/')[1]
    const selectedKeys = menuData.map(({ name }) => name).filter(name => name === path_0)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const [hovering, setHovering] = useState(false)

    return (
        <>
            <CustomMenu
                mode="horizontal"
                selectedKeys={selectedKeys}
                onClick={e => {
                    const sp = createSearchParams(searchParams)
                    navigate({
                        pathname: '/' + e.key,
                        search: `?${sp}`,
                    })
                }}
                onMouseEnter={() => {
                    setHovering(true)
                }}
                onMouseLeave={() => {
                    setHovering(false)
                }}
                items={
                    menuData.map(({ name, displayName, staffOnly, icon }) => ({
                        key: name,
                        className: staffOnly ? 'ant-menu-item-staff' : undefined,
                        style: { fontWeight: 600 },
                        label: (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '3px',
                                    alignItems: 'center',
                                }}
                            >
                                {icon} {displayName}
                            </div>
                        ),
                    })) ?? []
                }
            />
        </>
    )
}

const Header = ({ centerComponent }: HeaderProps) => {
    let navigate = useNavigate()
    const location = useLocation()
    const { data: userInfo } = useUserInfo()
    const [searchParams] = useSearchParams()
    const isStaff = useUserIsStaff()
    const { data: userPermissions } = useUserPermissions()

    // const shouldBeFixed = fixedRoutes.find(w => window.location.href.includes(w))
    const shouldBeFixed = true

    const careersPageExists = useCareersPageExists()
    const { data: companyDetails } = useCompanyDetails()

    const menu = [
        {
            label: (
                <NavLink to={'user_settings/change_password?' + searchParams.toString()}>
                    Change Password
                </NavLink>
            ),
            key: '1',
        },
        isStaff
            ? {
                  key: '3',
                  label: (
                      <NavLink
                          to={'user_settings/otp_config?' + searchParams.toString()}
                          style={{ color: 'yellowgreen' }}
                      >
                          Configure MFA
                      </NavLink>
                  ),
              }
            : null,
        {
            key: '2',
            label: (
                <Link
                    to="#"
                    onClick={async () => {
                        await logout()
                        navigate('/')
                        window.location.reload() // necessary because we are hopping routers
                    }}
                >
                    Logout
                </Link>
            ),
        },
    ]

    return (
        <>
            <TopBar
                $shouldBeWhite
                $shouldBeFixed
                data-testid="Header"
            >
                <div
                    style={{
                        minWidth: '60%',
                    }}
                >
                    <Link
                        to={'/' + location.search}
                        style={{
                            color: 'black',
                            float: 'left',
                            marginRight: '10px',
                            width: '61px',
                        }}
                    >
                        <div
                            style={{
                                borderRight: '1px solid #ededed',
                                width: '63px',
                                height: '61px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Favicon
                                style={{
                                    height: '18px',
                                    width: '40px',
                                }}
                            />
                        </div>
                    </Link>
                    <RoleMenu />
                </div>
                <div
                    style={{
                        minWidth: '15%',
                        paddingLeft: 8,
                        lineHeight: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'right',
                            gap: '15px',
                            alignItems: 'center',
                        }}
                    >
                        {MainMenuIcons.filter(obj =>
                            obj.userPermissionsAllowed(userPermissions ?? [], isStaff),
                        )
                            .filter(
                                obj =>
                                    !(companyDetails?.settings?.hiddenTabNames ?? []).includes(
                                        obj.name,
                                    ) || isStaff,
                            )
                            .filter(obj =>
                                isStaff
                                    ? userInfo?.settings?.staffPermissions?.visibleTabsByCompany?.[
                                          companyDetails?.token ?? ''
                                      ]?.[obj.name] ?? true
                                    : true,
                            )
                            .map(obj =>
                                obj.component(
                                    location,
                                    navigate,
                                    searchParams,
                                    careersPageExists,
                                    companyDetails,
                                ),
                            )}

                        {isStaff ? (
                            userInfo?.settings?.staffPermissions?.visibleTabsByCompany?.[
                                companyDetails?.token ?? ''
                            ]?.['notificationCenter'] ?? true ? (
                                <NotificationCenter />
                            ) : null
                        ) : (
                            <NotificationCenter />
                        )}
                        <Dropdown
                            menu={{ items: menu }}
                            trigger={['click']}
                        >
                            {/* the href hack is for the accessibility warning; This actually makes it accessible 🤷 */}
                            <a
                                className="ant-dropdown-link"
                                onClick={e => e.preventDefault()}
                                href="##"
                            >
                                {/*<Avatar size={32} icon={<UserOutlined />} />{' '}*/}
                                {userInfo?.friendly_name} <DownOutlined />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </TopBar>
            {shouldBeFixed && <Spacer height={64} />}
        </>
    )
}

export default Header
