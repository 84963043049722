import React, { lazy } from 'react'
import { InterviewDetailsProps } from './InterviewDetails'
const LazyInterviewDetails = lazy(() => import('./InterviewDetails'))

const InterviewDetails = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsProps,
) => <LazyInterviewDetails {...props} />

export default InterviewDetails
