import React, { lazy, Suspense } from 'react'
import { PagesDistributeProps } from './PagesDistribute'
const LazyPagesDistribute = lazy(() => import('./PagesDistribute'))

const PagesDistribute = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PagesDistributeProps,
) => (
    <Suspense fallback={null}>
        <LazyPagesDistribute {...props} />
    </Suspense>
)

export default PagesDistribute
