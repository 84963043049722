import React, { lazy } from 'react'
import { CreateAccountProps } from './CreateAccount'
const LazyCreateAccount = lazy(() => import('./CreateAccount'))

const CreateAccount = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & CreateAccountProps,
) => <LazyCreateAccount {...props} />

export default CreateAccount
