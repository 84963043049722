import React, { lazy, Suspense } from 'react'
import { ChannelDistributeProps } from './ChannelDistribute'
const LazyChannelDistribute = lazy(() => import('./ChannelDistribute'))

const ChannelDistribute = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelDistributeProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelDistribute {...props} />
    </Suspense>
)

export default ChannelDistribute
