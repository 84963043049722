import React, { lazy } from 'react'
import { ResponsesListProps } from './ResponsesList'
const LazyResponsesList = lazy(() => import('./ResponsesList'))

const ResponsesList = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ResponsesListProps,
) => <LazyResponsesList {...props} />

export default ResponsesList
