import axios from 'axios'
import { ExternalService, useExternalJobsList } from 'services/external_integrations_service'
import { useParams } from 'react-router-dom'
import { useQuery, useQueries, useQueryClient, useMutation } from '@tanstack/react-query'
import { UserInfo } from 'services/user_service'
import { Moment } from 'moment'
import {
    ModifiedRoleSearch,
    SortInfo,
} from 'pages/RolesearchList/pages/ChannelsTable/ChannelsTable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { HEADER_ANALYTICS_TIME_PERIODS_TYPE } from 'pages/CompanyAnalytics/CompanyAnalytics'

export const RS_TOKEN_REGEX = /RS_[\w_-]+/
export const PAGE_TOKEN_REGEX = /FC_[\w_-]+/

export enum CTAActions {
    DEFAULT = 1, // our standard yes/no/maybe
    HIDDEN = 2,
    CUSTOM_LINK = 3, // single button that opens an external link
}

export enum CandidateDeliveryMethod {
    EMAIL = 1,
    SLACK = 2,
    ATS = 3,
}

export interface CandidateDeliveryMethods {
    accepted?: CandidateDeliveryMethod[]
    declined?: CandidateDeliveryMethod[]
    disqualified?: CandidateDeliveryMethod[]
    autoSendToATS?: boolean
}
export type RoleSearchOwner = Pick<UserInfo, 'email' | 'friendly_name'>
export interface RoleSearch {
    token: string
    name: string
    linked_external_integrations: Record<
        ExternalService['id'],
        { url: string; job_id: { job_id: string; internal_id: string } }
    >
    role_rep_default_due_date?: string
    info: {
        description: string
        compensation: string
        benefits: string[]
        responsibilities: string
        compensationType?: 'Salary' | 'Hourly' | 'Contract'
        compensationRange: string[]
        roleType?: 'Full-time' | 'Part-time' | 'Contract'
        jobPostingWidgetUrl?: string
        additionalExternalUrls?: string[]
        useExternalPostingAsDetailsPage?: boolean
        candidateAppDefaultCTA?: CTAActions
        candidateAppCustomCTATargetUrl?: string
        candidateAppCTAPositiveText?: string
        candidateAppCustomCTAPositiveText?: string
        lang?: string
        hideChannelDescription?: boolean
        channelType?: string
        hideOnCareersSite?: boolean
        isReel?: boolean
        branding?: {
            removeWednesdayBranding?: boolean
            accentColor?: string
            pageColor?: string
            templateToken?: string
        }
        wv2?: boolean
        wv8?: boolean
        wv16?: 'grid' | 'playlist' | 'grid-playlist'
        groupByContributor?: boolean
        layout?: 'page' | 'playlist'
    }
    candidate_delivery_methods: CandidateDeliveryMethods
    is_active: boolean
    active_since: string | null // isoformatted string, if it exists
    editable: boolean
    owner: RoleSearchOwner
    num_qual_questions?: number
    viewss?: number[]
    num_candidate_app_selected_videos?: number
    digests: RoleSearchDigestInfo
    created: string // iso string
    pause_sending_reminders: boolean
    first_launched: string // isostring (or empty string), this is the first time a channel was published
    last_update: string // isostring (or empty string), this is the most recent time a channel was published
    readonly num_videos: number // how many rrrvs are there that are fk'd to this rs
    archived: boolean
    location: string
    team: string
    readonly logo_url: string
    readonly poster_url: string
    projects?: string[]
}
export interface PaginatedResult<T> {
    results: T[]
    count: number
    next?: string
    previous?: string
}

export const useRoleSearchByToken = () => {
    const { data: roleSearches } = useRoleSearchList()
    const roleSearchesByToken = useMemo(() => {
        if (roleSearches === undefined) {
            return undefined
        }
        return roleSearches.reduce((memo, rs) => ({ ...memo, [rs.token]: rs }), {}) as Record<
            string,
            RoleSearch
        >
    }, [roleSearches])
    return roleSearchesByToken
}

export const useRoleSearchesForVideo = (videoToken: string) => {
    const roleSearchesQuery = useQuery({
        queryKey: ['roleSearches', 'video', videoToken],

        queryFn: async () => {
            const res = await axios.get<RoleSearch[]>('/api/role_search', {
                params: {
                    videoToken,
                    page_size: 'null', // this is _probably_ few enough to not ever have to worry about paging.
                },
            })
            return res.data
        },
    })
    return roleSearchesQuery
}
export const useRoleSearchList = (tokens?: string[], enabled?: boolean) => {
    const roleSearchesQuery = useQuery({
        queryKey: ['roleSearches', (tokens ?? []).sort().join(',')],

        queryFn: async () => {
            const res = await axios.get<RoleSearch[]>(
                '/api/role_search',
                { params: { page_size: 'null', tokens: tokens?.join(',') } }, // leaving this option so we don't have to switch every usage to respect pages right away
            )
            return res.data
        },

        enabled: enabled ?? true,
    })
    return roleSearchesQuery
}

export const ROLE_SEARCH_PAGE_SIZE = 16

export const useMemoArray = <T>(arr?: T[]) => {
    const ref = useRef<T[] | undefined>(arr)
    const isEqual = (arr1?: any[], arr2?: any[]) => {
        if (!arr1 || !arr2) {
            return false
        }
        // depth 1
        return arr1.length === arr2.length && arr1.every((e, i) => e === arr2[i])
    }
    if (!isEqual(arr, ref.current)) {
        ref.current = arr
    }
    return ref.current
}

export const useRoleSearchListPaged = (
    page: number,
    ordering?: string,
    search?: string,
    filters?: Record<string, string>,
    showArchivedChannels: boolean = false,
    enabled: boolean = true,
) => {
    const roleSearchesQueries = useQueries({
        queries: [...Array(page)].map((_, page) => {
            // return [1].map((_, page) => {
            page += 1
            return {
                enabled,
                queryKey: [
                    'roleSearches',
                    'page',
                    page,
                    ordering,
                    search,
                    filters,
                    showArchivedChannels,
                ],
                queryFn: async () => {
                    const res = await axios.get<PaginatedResult<RoleSearch>>('/api/role_search', {
                        params: {
                            page_size: ROLE_SEARCH_PAGE_SIZE,
                            page,
                            ordering,
                            search,
                            showArchivedChannels,
                            ...filters,
                        },
                    })
                    return {
                        ...res.data,
                        numPages: Math.round(res.data.count / ROLE_SEARCH_PAGE_SIZE + 0.5),
                    }
                },
            }
        }),
    })

    const status: 'error' | 'loading' | 'idle' | 'success' | 'unknown' = (() => {
        // interestingly ts can infer this type correctly, but then later widens it to 'string'
        switch (true) {
            case roleSearchesQueries.some(x => x.status === 'error'):
                return 'error'
            case roleSearchesQueries.some(x => x.status === 'pending'):
                return 'loading'
            case roleSearchesQueries.every(x => x.status === 'success'):
                return 'success'
            default:
                // mixed idle and success???
                return 'unknown'
        }
    })()
    const dataPages = useMemoArray(roleSearchesQueries.map(qr => qr.data))

    const combinedResult = useMemo(() => {
        return dataPages?.reduce((x, y) => {
            return x.concat(y?.results || []) // I guess we should really wait for them all;  and maybe also blow up if they disagree on total count
        }, [] as RoleSearch[])
    }, [dataPages])
    return useMemo(() => {
        const x = {
            ...dataPages?.slice(-1)[0],
            results: combinedResult,
            numPages: dataPages?.reverse().find(x => x?.numPages)?.numPages, // try to get the latest one that's finished
            count: dataPages?.reverse().find(x => x?.count)?.count, // try to get the latest one that's finished
            status,
        }
        x.results = combinedResult
        return x
    }, [combinedResult, dataPages, status])
}

const getFilterObject = ({ filters }: SortInfo) => {
    if (!filters) return {}
    const filterKeyMap: Record<string, string | undefined> = {
        owner: 'owner__email',
        contributors: 'contributors__isnull',
    }
    const filterValueTransform: Record<string, undefined | ((a: any) => any)> = {
        contributors: v => !v,
    }
    //@ts-ignore
    return Object.fromEntries(
        //@ts-ignore
        Object.entries(filters)
            .map(([k, v]) => {
                if (!v) {
                    return undefined
                }
                return [
                    filterKeyMap[k] ?? k,
                    v
                        .map(filterValueTransform[k] || (v => v))
                        // .map(encodeURIComponent)
                        .join(','),
                ]
            })
            .filter(x => x !== undefined),
    )
}
const getOrderingString = ({ key, order }: SortInfo) => {
    if (!key) {
        return undefined
    }
    const field =
        {
            launched: 'recent_digests__first_launched',
            updated: 'recent_digests__last_update',
            owner: 'owner__email',
        }[key] || key
    return (order === 'descend' ? '-' : '') + field
}
export const useMergedRoleSearchListPaged = (
    pageNumber: number,
    sortInfo: SortInfo,
    search?: string,
    showArchivedChannels: boolean = false,
    showExternalJobs?: boolean,
) => {
    const lastPageNumberRef = useRef(pageNumber)
    const ordering = getOrderingString(sortInfo)
    const filters = useMemo(() => getFilterObject(sortInfo), [sortInfo])
    const pageSize = ROLE_SEARCH_PAGE_SIZE
    const [remotePage, setRemotePage] = useState(1)
    const dontShowRolesearches = (showExternalJobs ?? false) === true
    const dontShowExternalJobs = (showExternalJobs ?? true) === false
    const {
        results: roleSearches,
        count: rsCount,
        numPages,
        status,
    } = useRoleSearchListPaged(
        remotePage,
        ordering,
        search,
        filters,
        showArchivedChannels,
        !dontShowRolesearches,
    ) || {}
    const fakeChannels = useFakeRoleChannels(search, !dontShowExternalJobs)
    const merged = useMemoArray(
        (dontShowRolesearches ? true : roleSearches) && (dontShowExternalJobs ? true : fakeChannels)
            ? (dontShowRolesearches ? [] : (roleSearches as ModifiedRoleSearch[])).concat(
                  dontShowExternalJobs ? [] : fakeChannels,
              )
            : undefined,
    )
    const { sorted, total } = useMemo(() => {
        const statusFilters = sortInfo.filters?.status ?? []
        const notYetChanFilter = statusFilters.includes('Not yet a Channel')
        // const LOCAL_STATUS_FILTERS = ['Not yet a Channel', 'No Longer Live In ATS']
        // const otherStatusFilters = statusFilters.some(k => LOCAL_STATUS_FILTERS.every(x => x !== k))
        const otherFilterCatsApplied = Object.entries(sortInfo.filters ?? {}).some(([k, v]) => {
            return k !== 'status' && v?.length
        })
        const fakeCount = !statusFilters.length || notYetChanFilter ? fakeChannels.length : 0
        const filtered = merged?.filter((rs, idx) => {
            if (Object.keys(filters).length) {
                const rsNotYetChan = !!rs.externalJobInfo
                // const rsNoLongerLive =
                //     Object.keys(rs?.linked_external_integrations).length &&
                //     !externalJobIdByRsToken?.[rs.token]
                if (otherFilterCatsApplied && rsNotYetChan) {
                    // most filters exclude external-only jobs
                    return false
                }
                if (statusFilters.length) {
                    if (notYetChanFilter && !rsNotYetChan && statusFilters.length === 1) {
                        return false
                    } else if (!notYetChanFilter && rsNotYetChan) {
                        return false
                    }
                }
                return true
            } else return true
        })
        const sorted = filtered?.sort((r1, r2) => {
            if (sortInfo.sorterFn) {
                if (sortInfo.order === 'ascend') return sortInfo.sorterFn(r1, r2)
                else return sortInfo.sorterFn(r2, r1)
            } else {
                //@ts-ignore // really I guess this should be using dataIndex;  doesn't really mattter I guess since we don't currently have just `sort: true` any any cols
                return (r1[sortInfo.key] < r2[sortInfo.key]) as any as number
            }
        })
        return { sorted, total: (rsCount ?? 0) + fakeCount }
    }, [fakeChannels.length, filters, merged, rsCount, sortInfo])
    useEffect(() => {
        if (lastPageNumberRef.current > pageNumber) {
            setRemotePage(1) // turn the page down; the other effect will turn it back up if needed
        }
        lastPageNumberRef.current = pageNumber
    }, [pageNumber])

    useEffect(() => {
        if (!sorted) return
        if (status === 'loading') return // don't keep incrementing while loading
        const nextSlice = sorted.slice(pageSize * pageNumber - 1) // ish (also looking at end of curslice)
        if (nextSlice.some(r => !r?.externalJobInfo)) {
            return
        }
        // if there aren't any db rs' beyond target page then
        // more data from the backend needed to ensure our target page is filled correctly
        const targetPage =
            remotePage + Math.round((pageNumber * pageSize - sorted.length) / pageSize)
        const clippedPageNum = Math.min(Math.max(remotePage + 1, targetPage), numPages ?? 1)
        if (clippedPageNum > remotePage) {
            setRemotePage(clippedPageNum)
        }
    }, [numPages, pageNumber, pageSize, remotePage, sorted, status])
    return useMemo(() => {
        const x = {
            total,
            results: sorted?.slice(pageSize * (pageNumber - 1), pageSize * pageNumber),
            status,
        }
        return x
    }, [fakeChannels.length, filters, pageNumber, pageSize, rsCount, sortInfo, sorted, status])
}
export const useKnownExternalIds = (mode?: 'channels' | 'asyncJobs') => {
    return useQuery({
        queryKey: ['knownExternalJobIds', mode],

        queryFn: async () => {
            return (
                await axios.get<{
                    knownExternalJobIds: Record<string, true | undefined>
                    knownExternalInternalIds: Record<string, true | undefined>
                    externalJobIdByRsToken: Record<string, string | undefined>
                }>('/api/known_external_jobs?mode=' + (mode ?? 'channels'))
            ).data
        },
    })
}
export const useFakeRoleChannels = (search?: string, enabled: boolean = true) => {
    const externallyDefinedJobs = useExternalJobsList().data
    const { knownExternalJobIds, knownExternalInternalIds } = useKnownExternalIds().data || {}
    const ret = useMemo(() => {
        const trueSearch = search?.toLowerCase()?.trim()
        return (externallyDefinedJobs || [])
            .filter(externalJob => {
                const job_id_found = knownExternalJobIds?.[externalJob.job_id]
                const internal_id_found = knownExternalInternalIds?.[externalJob.internal_id]
                return !job_id_found && !internal_id_found
            })
            .map(externalJob => ({
                token: externalJob.external_url,
                name: externalJob.name,
                linked_external_integrations: {},
                candidate_delivery_methods: {},
                is_active: false,
                active_since: null,
                editable: true,
                info: {
                    description: '',
                    compensation: '',
                    benefits: [],
                    responsibilities: '',
                    compensationRange: [],
                },
                owner: null,
                externalJobInfo: externalJob,
                digests: { preview: '', published: '', db: '' },
                created: '',
                pause_sending_reminders: false,
                first_launched: '',
                last_update: '',
                num_videos: 0,
                archived: false,
                team: externalJob.team,
                location: externalJob.location,
                logo_url: '',
                poster_url: '',
                // department: externalJob.department
            }))
            .filter(externalJob =>
                trueSearch !== undefined
                    ? externalJob.name.toLowerCase().includes(trueSearch) ||
                      externalJob?.externalJobInfo?.req_number
                          ?.toLowerCase()
                          ?.includes(trueSearch) ||
                      externalJob.externalJobInfo?.location?.toLowerCase()?.includes(trueSearch) ||
                      externalJob.externalJobInfo?.team?.toLowerCase()?.includes(trueSearch) ||
                      externalJob.externalJobInfo?.department?.toLowerCase()?.includes(trueSearch)
                    : true,
            )
    }, [externallyDefinedJobs, knownExternalInternalIds, knownExternalJobIds, search])
    if (!enabled) {
        return []
    }
    return ret
}
export const useExternalJobByRsToken = () => {
    const { externalJobIdByRsToken } = useKnownExternalIds().data || {}
    const externallyDefinedJobs = useExternalJobsList().data
    const externallyDefinedJobByJobId = useMemo(() => {
        if (!externallyDefinedJobs) {
            return undefined
        }
        return Object.fromEntries(externallyDefinedJobs?.map(j => [j.job_id, j]))
    }, [externallyDefinedJobs])
    return useMemo(() => {
        if (!externalJobIdByRsToken || !externallyDefinedJobByJobId) {
            return undefined
        }
        return Object.fromEntries(
            Object.entries(externalJobIdByRsToken).map(([token, job_id]) => [
                token,
                externallyDefinedJobByJobId[job_id as string],
            ]),
        )
    }, [externalJobIdByRsToken, externallyDefinedJobByJobId])
}

export const useRoleSearchOwnerEmails = () => {
    return useQuery({
        queryKey: ['roleSearches', 'ownerEmails'],

        queryFn: async () => {
            return (await axios.get<{ data: string[] }>('/api/role_search_owner_emails')).data.data
        },
    })
}
export const useChannelTypes = () => {
    return useQuery({
        queryKey: ['roleSearches', 'channelTypes'],

        queryFn: async () => {
            const types = (
                await axios.get<{ data: string[] }>('/api/channel_types')
            ).data.data.concat([
                'Recruiting',
                'Employer Brand',
                'DE&I',
                'ERG',
                'Social',
                'Internal',
            ])
            const deduped = new Set(types)
            return deduped
        },
    })
}

type RoleSearchWithToken = Partial<RoleSearch> & Pick<RoleSearch, 'token'>
export const updateRoleSearch = async (
    roleSearch: RoleSearchWithToken,
    newData: Partial<RoleSearch>,
) => {
    const res = await axios.patch<RoleSearch>(`/api/role_search/${roleSearch.token}`, {
        ...newData,
    })
    return res.data
}
export const useRoleSearchMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ rs, patch }: { rs: RoleSearchWithToken; patch: Partial<RoleSearch> }) =>
            updateRoleSearch(rs, patch),
        onMutate: async ({
            rs,
            patch,
        }: {
            rs: RoleSearchWithToken
            patch: Partial<RoleSearch>
        }) => {
            await queryClient.cancelQueries({
                queryKey: ['roleSearch', rs.token],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: (
            data: any,
            error: any,
            { rs, patch }: { rs: RoleSearchWithToken; patch: Partial<RoleSearch> },
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['roleSearch', rs.token],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_search_digest'],
            })
        },
    })
    return mutation
}

export const addRoleSearch = async (roleSearchName: string) => {
    const res = await axios.post<RoleSearch>('/api/role_search', {
        name: roleSearchName,
    })
    return res.data
}

export const setRoleSearchIsActive = async (
    roleSearchToken: string,
    desiredIsActive: boolean,
    notes?: string,
) => {
    const res = await axios.post<string>(`/api/toggle_role_search_is_active/${roleSearchToken}`, {
        desiredState: desiredIsActive,
        notes: notes ?? '',
    })
    return res.data
}

export function useRoleSearchFromUrl() {
    const roleSearchQuery = useRoleSearchQuery()
    const roleSearch = roleSearchQuery.data || ({} as RoleSearch)
    return roleSearch
}
export const roleSearchQueryArg = (token?: string) => ({
    queryKey: ['roleSearch', token],
    queryFn: async () => {
        const res = await axios.get<RoleSearch>(`/api/role_search/${token}`)
        return res.data
    },
    enabled: token?.startsWith('RS_'),
})
export function useRoleSearchQuery(token?: string) {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    return useQuery(roleSearchQueryArg(tokenToUse))
}

interface RoleSearchDigestInfo {
    db: string
    preview: string
    published: string
    pendingUpdate?: boolean
}

const getDigests = async (token: string) => {
    const res = await axios.get<{ digests: RoleSearchDigestInfo }>(
        `/api/get_role_search_digests/${token}`,
    )
    return res.data.digests
}

export const useDigests = (token?: string) => {
    const { data: roleSearch } = useRoleSearchQuery()
    const tokenToUse = token ?? roleSearch?.token ?? ''
    const query = useQuery({
        queryKey: ['role_search_digest', tokenToUse],
        queryFn: () => getDigests(tokenToUse),

        // on the list page importable channels have a faked token that is a url - dont bother trying to check for their digest
        enabled: !!tokenToUse && RS_TOKEN_REGEX.test(tokenToUse),
    })
    return query
}

interface CandidateTypeCount {
    candidates?: number
    non_candidates?: number
}

export interface ToplineChannelAnalytics {
    total_sessions: number
    check_fits: number
    applicants: number
    clicks_per_topic: number
    bounce_count: number
    medianInteractionTime: number // median is of time spent (in seconds) between 1st and last event, per session, of nonbounce sessions
}

interface _TopLineRawType {
    analytics_info_by_source: Record<
        string,
        {
            total_sessions: number
            total_unique_visitors: number
            total_applicants: {
                // note these keys are strings, not null [value]/False/True
                null?: number
                true?: number
                false?: number
            }
            topLineValues: {
                clicks_per_topic: number
                check_fits: number
                applicants: number
            }
            bounceCount: number
            medianTime: number
        }
    >
}
const getToplineChannelAnalytics = async (token: string) => {
    const res = await axios.get<_TopLineRawType>(
        `/api/get_analytics_info/get_top_line_values/` + (token ? `${token}/` : ''),
    )
    const data = res.data
    const repackagedData: Record<string, ToplineChannelAnalytics> = {}
    Object.entries(data.analytics_info_by_source).forEach(([s, d]) => {
        const newD = {
            total_sessions: d?.total_sessions ?? 0,
            check_fits: d.topLineValues?.check_fits ?? 0,
            applicants: Object.values(d?.total_applicants ?? {}).reduce(
                (memo, next) => memo + next,
                0,
            ),
            clicks_per_topic: d.topLineValues?.clicks_per_topic ?? {},
            bounce_count: d.bounceCount,
            medianInteractionTime: d.medianTime,
        }
        repackagedData[s] = newD
    })

    return { analyticsBySource: repackagedData }
}

export const useHeaderAnalytics = (timePeriod: HEADER_ANALYTICS_TIME_PERIODS_TYPE) => {
    const _get = async (timePeriod: HEADER_ANALYTICS_TIME_PERIODS_TYPE) => {
        const res = await axios.get<{
            data: {
                numContributors: number
                contributorsGraphPoints: number[]
                numVideosTotal: number
                totalVideosGraphPoints: number[]
                numPublishedVideos: number
                publishedVideoGraphPoints: number[]
                numArtifacts: number
                artifactsGraphPoints: number[]
            }
        }>('/api/company_analytics_header_data', { params: { timePeriod } })
        return res.data.data
    }

    const query = useQuery({
        queryKey: ['header_analytics', timePeriod],
        queryFn: () => _get(timePeriod),
    })
    return query
}

export const useToplineChannelAnalytics = (token?: string) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''

    const query = useQuery({
        queryKey: ['channel_analytics', 'topLine', tokenToUse],

        queryFn: () => getToplineChannelAnalytics(tokenToUse),
    })
    return query
}

export const useCheckFitsAnalytics = (token?: string) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''

    const query = useQuery({
        queryKey: ['channel_analytics', 'check_fit', tokenToUse],

        queryFn: () => getCheckFitsAnalytics(tokenToUse),
    })
    return query
}
export interface CheckFitsAnalytics {
    'check-my-fit-yes'?: CandidateTypeCount
    // 'check-my-fit-maybe'?: CandidateTypeCount
    'external-apply-link'?: CandidateTypeCount
    // 'not-interested'?: CandidateTypeCount
}
export interface TopicAnalytics {
    total?: CandidateTypeCount
    checked_fit?: CandidateTypeCount
}
const getCheckFitsAnalytics = async (token?: string) => {
    const res = await axios.get<{ analytics_info_by_source: Record<string, CheckFitsAnalytics> }>(
        `/api/get_analytics_info/check_fit_analytics/` + (token ? `${token}/` : ''),
    )
    return res.data.analytics_info_by_source
}

export const useTopicAnalytics = (token?: string) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''

    const query = useQuery({
        queryKey: ['channel_analytics', 'topics', tokenToUse],

        queryFn: () => getTopicAnalytics(tokenToUse),
    })
    return query
}

const getTopicAnalytics = async (token?: string) => {
    const res = await axios.get<{
        analytics_info_by_source: Record<string, Record<string, TopicAnalytics>>
    }>(`/api/get_analytics_info/change_topic_analytics/` + (token ? `${token}/` : ''))
    return res.data.analytics_info_by_source
}

export const useVideoAnalytics = (token?: string) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''

    const query = useQuery({
        queryKey: ['channel_analytics', 'videos', tokenToUse],

        queryFn: () => getVideoAnalytics(tokenToUse),
    })
    return query
}

export interface VideoAnalytics {
    info: {
        topic: string
        role_rep_email?: string
        role_search_token?: string
        role_search_name?: string
    }
    total?: CandidateTypeCount
    checked_fit?: CandidateTypeCount
}

const getVideoAnalytics = async (token?: string) => {
    const res = await axios.get<{
        analytics_info_by_source: Record<string, Record<string, VideoAnalytics>>
    }>(`/api/get_analytics_info/video_ended_analytics/` + (token ? `${token}/` : ''))
    return res.data.analytics_info_by_source
}
// export const useApplicantAnalytics = (token?: string) => {
//     const { token: urlToken } = useParams()
//     const tokenToUse = token || urlToken || ''

//     const query = useQuery(['channel_analytics', 'applicants', tokenToUse], () =>
//         getApplicantAnalytics(tokenToUse),
//     )
//     return query
// }

// const getApplicantAnalytics = (token?: string) => {}

export const useMultipleChannelAnalytics = (tokens: string[], mode: 'topLine') => {
    const queries = useQueries({
        queries: tokens.map(token => ({
            queryKey: ['channel_analytics_multi', token], // huge shame that this isnt sharing a cache with useChannelAnalytics.  I think we just throw token in the data return from the backend and then unify these
            queryFn: async () => ({ token, analytics: await getToplineChannelAnalytics(token) }),
        })),
    })
    return queries
}

export interface CompanyAnalyticsGraphData {
    impressions_count_by_date: Record<string, number>
    channel_interactions_count_by_date: Record<string, number>
    video_interactions_by_date: Record<string, number>
    video_completes_by_date: Record<string, number>
    median_interaction_time_by_date: Record<string, number>
    cta_clicks_by_date: Record<string, number>
}

export const TIME_SERIES_RESOLUTION = ['Day', 'Week', 'Month', 'Year'] as const
export type TIME_SERIES_RESOLUTION_TYPE = (typeof TIME_SERIES_RESOLUTION)[number]

export const useCompanyAnalyticsGraphData = (
    resolution: TIME_SERIES_RESOLUTION_TYPE,
    sourceFilter: string,
    baseTokens?: string[],
) => {
    const _get = async () => {
        const ret = await axios.post<{ data: CompanyAnalyticsGraphData }>(
            '/api/company_analytics_graph_data',
            { resolution, sourceFilter, baseTokens },
        )
        return ret.data.data
    }

    const query = useQuery({
        queryKey: [
            'company_analytics_graph_data',
            ...(baseTokens ?? []).sort(),
            resolution,
            sourceFilter,
        ],
        queryFn: _get,
    })
    return query
}

export interface TopLineAnalyticsData {
    numImpressions: number
    numInteractions: number
    medianInteractionTime: number
    numVideoImpressions: number // ie number of sessions that started at least 1 video
    numVideoCompletes: number // ie number of sessions that completed at least 1 video
    numConversions: number // ie number of sessions that clicked an external apply link
    numConversionsDenominator: number // rough attempt at only looking at the num conversions out of how many sessions were even shown a cta button.  Not totally accurate, because this only considers channels that have a _default_ external cta, but doesnt consider ones that dont but a session saw a url that configured it
}
export const analyticsTopLineDataQueryArg = (baseToken?: string) => ({
    queryFn: async () =>
        (
            await axios.get<{
                data: TopLineAnalyticsData
            }>('/api/company_analytics_top_line_data/' + (baseToken ? baseToken : ''))
        ).data.data,
    queryKey: ['topLineAnalytics', baseToken],
})
export const useCompanyAnalyticsTopLineData = () => {
    return useQuery(analyticsTopLineDataQueryArg())
}
export const useSpecificAnalyticsTopLineData = (tokens: string[]) => {
    const queries = useQueries({
        queries: tokens.map(token => {
            const q = analyticsTopLineDataQueryArg(token)
            return {
                queryKey: q.queryKey,
                queryFn: async () => ({ token, data: await q.queryFn() }),
            }
        }),
    })
    return queries
}

export const useCompanyAnalyticsTableData = () => {
    const _get = async () => {
        const res = await axios.get<
            Record<string, { numImpressions: number; numInteractions: number; medianTime: number }>
        >('/api/company_analytics_table_view')
        return res.data
    }
    const query = useQuery({
        queryKey: ['analyticsTable'],
        queryFn: _get,
    })
    return query
}

export const useAvailableSources = (targetTokens?: string[]) => {
    // this endpoint will return the sources (ie ?s= sources)
    // that the target objects have events related to
    // intended to populate the dropdown of a graph offering to cut by source
    const _get = async (targetTokens?: string[]) => {
        const ret = await axios.post<{ sources: string[] }>('/api/company_analytics_source_list', {
            targetTokens: targetTokens,
        })
        return ret.data.sources
    }
    const query = useQuery({
        queryKey: ['available_sources', JSON.stringify((targetTokens ?? []).sort().join(','))],
        queryFn: () => _get(targetTokens),
    })
    return query
}

type topicName = string
interface eventTimeseriesResponse {
    data: rsTsDataPoint[]
    topics: topicName[]
}
type rsTsDataPoint =
    | {
          time_bucket: string
          total_events: number
          check_fit: number
          external_apply: number
          not_interested: number
          visit_app: number
      }
    | Record<topicName, number>
export const timeResolutions = ['day', 'hour', 'week'] as const
export const timeFormatByResolution: Record<(typeof timeResolutions)[number], string> = {
    day: 'MM/DD',
    hour: 'MM/DD HH:00',
    week: 'MM/DD',
}
export const useChannelEventsTimeseries = ({
    token,
    startDate,
    endDate,
    resolution,
}: {
    token?: string
    startDate: Moment
    endDate: Moment
    resolution: (typeof timeResolutions)[number]
}) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    const query = useQuery({
        queryKey: ['channel_analytics', tokenToUse, 'timeseries', resolution, startDate, endDate],

        queryFn: async () => {
            const res = await axios.get<eventTimeseriesResponse>(
                `/api/event_timeseries/${tokenToUse}/`,
                {
                    params: {
                        res: resolution,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                    },
                },
            )
            return res.data
        },

        enabled: !!tokenToUse,
    })
    if (!query.data) {
        return undefined
    }
    return {
        data: query.data.data.map(d => ({
            ...d,
            time_bucket: new Date(d.time_bucket).getTime(), // TODO localized? vary precision by the input resolution
        })),
        topics: query.data.topics,
    }
}

interface eventBySourceTimeseriesResponse {
    data: rsBySourceTsDataPoint[]
    topics: string[]
    topicNames: string[]
}
type rsBySourceTsDataPoint =
    | {
          time_bucket: string
      }
    | Record<string, string>
export const candidateEventTypes = ['VISIT_APP', 'CHECK_FIT', 'VIEW_ROLE_DETAILS'] as const
export const useChannelEventsBySourceTimeseries = ({
    token,
    startDate,
    endDate,
    resolution,
    eventType,
}: {
    token?: string
    startDate: Moment
    endDate: Moment
    resolution: (typeof timeResolutions)[number]
    eventType: string
}) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    const query = useQuery({
        queryKey: [
            'channel_analytics',
            tokenToUse,
            'timeseries',
            resolution,
            startDate,
            endDate,
            eventType,
        ],

        queryFn: async () => {
            const res = await axios.get<eventBySourceTimeseriesResponse>(
                `/api/event_by_source_timeseries/${tokenToUse}/`,
                {
                    params: {
                        res: resolution,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        et: eventType,
                    },
                },
            )
            return res.data
        },

        enabled: !!tokenToUse,
    })
    if (!query.data) {
        return undefined
    }
    return {
        data: query.data.data.map(d => ({
            ...d,
            time_bucket: new Date(d.time_bucket).getTime(), // TODO localized? vary precision by the input resolution
        })),
        topics: query.data.topics,
        topicNames: query.data.topicNames,
    }
}

interface ElapsedTimeBucket {
    applied: number
    not_applied: number
    bucket: string
}
interface ElapsedTimeDistributionResponse {
    data: ElapsedTimeBucket[]
}
export const useElapsedTimeDistribution = ({
    token,
    startDate,
    endDate,
    resolution,
    eventType,
}: {
    token?: string
    startDate: Moment
    endDate: Moment
    resolution: number
    eventType: string
}) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    const query = useQuery({
        queryKey: [
            'channel_analytics',
            tokenToUse,
            'timeseries',
            resolution,
            startDate,
            endDate,
            eventType,
        ],

        queryFn: async () => {
            const res = await axios.get<ElapsedTimeDistributionResponse>(
                `/api/elapsed_time_distribution/${tokenToUse}/`,
                {
                    params: {
                        res: resolution,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        et: eventType,
                    },
                },
            )
            return res.data
        },

        enabled: !!tokenToUse,
    })
    if (!query.data) {
        return undefined
    }
    return {
        data: query.data.data.map(d => ({
            ...d,
            // time_bucket: new Date(d.time_bucket).getTime(), // TODO localized? vary precision by the input resolution
        })),
        // topics: query.data.topics,
        // topicNames: query.data.topicNames,
    }
}

export const usePreviewIsGenerating = (token: string) => {
    const { data: tokens } = useQuery({
        queryKey: ['tokensBeingGenerated'],
        queryFn: () => [] as string[],
    })
    return (tokens ?? []).includes(token)
}
export const useSetPreviewGeneratingStatus = () => {
    const queryClient = useQueryClient()
    return (token: string, isGenerating: boolean) => {
        const curTokens = queryClient.getQueryData<string[]>(['tokensBeingGenerated']) ?? []
        let newTokens: string[]
        if (isGenerating) {
            newTokens = [...curTokens, token]
        } else {
            newTokens = curTokens.filter(t => t !== token)
        }
        queryClient.setQueryData(['tokensBeingGenerated'], newTokens)
    }
}

export interface RoleSearchLite {
    name: string
    token: string
    candidateCount: number
    isActive: boolean
    ownerEmail: string
    team: string
    location: string
    recent_digests: RoleSearchDigestInfo
}
export const allRoleSearchesLiteQueryArg = <T extends keyof RoleSearchLite>(
    fields: T[],
    filterMode?: 'applicants' | 'notArchived',
    videoToken?: string,
    projectToken?: string,
) => ({
    queryKey: ['roleSearches', 'lite', fields.sort(), filterMode, videoToken, projectToken],
    queryFn: async () => {
        type x = Pick<RoleSearchLite, T | 'token'>
        return (
            await axios.get<{
                data: x[]
            }>('/api/role_search_lite', {
                params: {
                    fields: fields.join(','),
                    filterMode,
                    videoToken,
                    projectToken,
                },
            })
        ).data.data
    },
})

export const useAllRoleSearchesLite = <T extends keyof RoleSearchLite>(
    fields: T[],
    filterMode?: 'applicants' | 'notArchived',
    videoToken?: string,
    projectToken?: string,
) => {
    return useQuery(allRoleSearchesLiteQueryArg(fields, filterMode, videoToken, projectToken))
}

export const useUpdateChannels = () => {
    const queryClient = useQueryClient()
    const _update = async (tokens: string[]) => {
        const res = await axios.post('/api/update_multiple_channels', { tokens })
        return res.data
    }

    const mutation = useMutation({
        mutationFn: (tokens: string[]) => _update(tokens),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
        },
    })
    return mutation
}
