import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

interface HeaderBarFilters {
    search: string
    programToken?: string
    tags: string[]
    date?: string
}
export const useHeaderBarFilters = (extraKey?: string) => {
    const qc = useQueryClient()
    const curFilters = useQuery({
        queryKey: ['headerBarFilters', extraKey],

        queryFn: () =>
            ({
                search: '',
                tags: [],
            } as HeaderBarFilters),

        initialData: { search: '', tags: [] },
        staleTime: Infinity,
    })
    const setCurFilters = useCallback(
        (patch: Partial<HeaderBarFilters>) => {
            qc.setQueryData<HeaderBarFilters>(['headerBarFilters', extraKey], oldVal => {
                const newVal = {
                    search: '',
                    tags: [],
                    ...oldVal,
                    ...patch,
                }
                return newVal
            })
        },
        [extraKey, qc],
    )
    return [curFilters.data, setCurFilters] as const
}
