import React, { lazy } from 'react'
import { BrowseCandidatesProps, CandidateDetailsProps } from './JobBoard'

const LazyBrowseCandidates = lazy(() =>
    import('./JobBoard').then(m => ({ default: m.BrowseCandidates })),
)
export const BrowseCandidates = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & BrowseCandidatesProps,
) => <LazyBrowseCandidates {...props} />

const LazyCandidateDetails = lazy(() =>
    import('./JobBoard').then(m => ({ default: m.CandidateDetails })),
)
export const CandidateDetails = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CandidateDetailsProps,
) => <LazyCandidateDetails {...props} />
