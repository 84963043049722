import React, { lazy } from 'react'
import { AsyncSelfSignupConfirmEmailProps } from './AsyncSelfSignupConfirmEmail'
const LazyAsyncSelfSignupConfirmEmail = lazy(() => import('./AsyncSelfSignupConfirmEmail'))

const AsyncSelfSignupConfirmEmail = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AsyncSelfSignupConfirmEmailProps,
) => <LazyAsyncSelfSignupConfirmEmail {...props} />

export default AsyncSelfSignupConfirmEmail
