import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useCompanyDetails, BrandTemplate } from 'services/company_service'

export const DEFAULT_PAGE_COLOR = '#FFFFFF'
export const DEFAULT_ACCENT_COLOR = '#00BD4D'

export const useBrandTemplates = () => {
    return useQuery({
        queryKey: ['brand_templates'],

        queryFn: async () => {
            return (await axios.get<BrandTemplate[]>('/api/brand_templates')).data
        },
    })
}
export const useBrandTemplate = (token?: string) => {
    const brandTemplates = useBrandTemplates().data
    const companyDetails = useCompanyDetails().data as BrandTemplate | undefined
    return brandTemplates?.find(bt => bt.token === token) ?? companyDetails
}
