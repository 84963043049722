import React, { lazy, Suspense } from 'react'
import { PasswordConfirmProps } from './PasswordResetConfirm'

const LazyLoginPage = lazy(() => import('./LoginPage'))

const LoginPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
    <Suspense fallback={null}>
        <LazyLoginPage {...props} />
    </Suspense>
)

export default LoginPage

const LazyCaptchaImage = lazy(() => import('./LoginPage').then(m => ({ default: m.CaptchaImage })))

export const CaptchaImage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
    <Suspense fallback={null}>
        <LazyCaptchaImage {...props} />
    </Suspense>
)

const LazyPasswordConfirm = lazy(() =>
    import('./PasswordResetConfirm').then(m => ({ default: m.PasswordConfirm })),
)

export const PasswordConfirm = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PasswordConfirmProps,
) => (
    <Suspense fallback={null}>
        <LazyPasswordConfirm {...props} />
    </Suspense>
)
