import axios from 'axios'
import { RoleRepPromptQuestion } from 'services/role_rep_prompt_service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
    useRoleSearchQuery,
    VideoAnalytics,
    PaginatedResult,
    RoleSearch,
} from 'services/role_search_service'

import { storageDomain } from './BackstageUtils'
import { LimitFunction } from 'p-limit'
import { pollableEndpoint } from './utils/CommonUtils'
import { CalendarOutlined, StarOutlined } from '@ant-design/icons'
import CommentIcon from 'assets/Comment.svg?react'
import { styled } from 'styled-components'
import { getNewSessionId } from './session_utils'
const POST_TO_CF = (import.meta.env?.VITE_POST_TO_CF ?? 'true').toLowerCase().startsWith('t')

export interface RoleRepResponseVideo {
    role_rep_email: string
    role_rep_name: string
    role_rep_title: string
    role_rep_token: string | null
    prompt_question_info: RoleRepPromptQuestion
    url: string
    library_video: boolean
    token: string
    role_search_token?: string
    archived: boolean
    lang?: string
    created: string // iso string
    tags: string[]
    campaign_name: string
    video_options: VideoOptions
    channels_selected_in: string[] // tokens of channels that have this video somewhere in its cars
    my_rating: number | null
    average_rating: number | null
    net_sentiment?: number
    public_url?: string
    employee_termed: boolean
    screen_capture_infos?: { url: string; key: string }[]
    transcript?: string
    duration?: number
    is_intro_video: boolean
    num_ratings: number
}
interface RoleRepResponseVideoDetails extends RoleRepResponseVideo {
    total_impressions: number
}
interface VideoOptions {
    playbackRange?: [number, number]
    appliedPlaybackRange?: [number, number]
    screenCaptures?: string[]
    videoDimensions?: [number, number]
    screenRecording?: boolean
    audioOnly?: boolean
    objectFit?: 'cover' | 'contain'
    scale?: number
    translate?: [number, number]
}

export interface OrderedTopicGroup {
    merge_videos?: boolean
    is_intro_topic?: boolean
    topic: string
    videos: (RoleRepResponseVideo & {
        coverText?: string
        labelLineOne?: string
        labelLineTwo?: string
    })[]
}
export interface CandidateAppResponseSet {
    role_search_token: string
    response_config: {
        ordered_topic_groups: OrderedTopicGroup[]
    }
}

export interface ProcessVideoOptions {
    process_video_options:
        | { blur_bg: number; blur_edge: number }
        | {
              virtualBackgroundKey: string
              confidenceCutoff: number
              enableLightWrap?: boolean
              enableConfidenceAlpha?: boolean
          }
}
export const updateRoleRepResponseVideo = async (
    roleRepResponseVideoToken: string,
    patch: Partial<RoleRepResponseVideo & ProcessVideoOptions>,
    copy: boolean = false,
) => {
    const res = await (copy
        ? axios.post<RoleRepResponseVideo>(
              `/api/role_rep_response_videos/${roleRepResponseVideoToken}`,
              patch,
          )
        : axios.patch<RoleRepResponseVideo>(
              `/api/role_rep_response_videos/${roleRepResponseVideoToken}`,
              patch,
          ))

    return res.data
}

type VideoEventType = 'created' | 'comment' | 'rating'
interface VideoEvent {
    time: string
    user: string
    type: VideoEventType
    label?: string
}

const IconWrap = styled.div`
    & {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
    }
`

export const videoEventIcon = (e: VideoEvent) => {
    return e.type === 'created' ? (
        <IconWrap>
            <CalendarOutlined />
        </IconWrap>
    ) : e.type === 'comment' ? (
        <IconWrap>
            <CommentIcon />
        </IconWrap>
    ) : e.type === 'rating' ? (
        <IconWrap>
            <StarOutlined />
        </IconWrap>
    ) : null
}

export const useVideoEvents = (token: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<{
            events: VideoEvent[]
        }>('/api/get_video_timeline_events/' + token)
        return res.data.events
    }

    const query = useQuery({
        queryKey: ['videoEvents', token],
        queryFn: () => _get(token),
    })
    return query
}

interface ChangeContributorOptions {
    videoTokens: string[]
    roleRepToken?: string
    contributorToken?: string
    newContributor?: {
        name?: string
        email: string
        title?: string
    }
}
export const useChangeContributor = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: ChangeContributorOptions) => {
            return axios.patch('/api/change_rrrv_contributor', data)
        },
        onSettled: (d, e, v) => {
            return Promise.allSettled([
                queryClient.invalidateQueries({
                    queryKey: ['role_rep_response_library'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['roleReps'],
                }),
                ...v.videoTokens.map(t =>
                    queryClient.invalidateQueries({
                        queryKey: ['roleSearches', 'video', t],
                    }),
                ),
            ])
        },
    })
}
interface RRRVMutationArgs {
    video: RoleRepResponseVideo
    patch: Partial<RoleRepResponseVideo & ProcessVideoOptions>
    copy?: boolean
}
export const useUpdateRoleRepResponseVideoMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ video, patch, copy = false }: RRRVMutationArgs) =>
            updateRoleRepResponseVideo(video.token, patch, copy),
        onMutate: async ({ video }: { video: RoleRepResponseVideo }) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
            await queryClient.cancelQueries({
                queryKey: ['contentList', 'filterCategoryData'],
            })
            await queryClient.cancelQueries({
                queryKey: ['videoEvents'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_responses'],
            })
        },
        onSettled: (data: any, error: any, { video }: RRRVMutationArgs) => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
            queryClient.invalidateQueries({
                queryKey: ['contentList', 'filterCategoryData'],
            })
            queryClient.invalidateQueries({
                queryKey: ['videoEvents'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_responses'],
            })
        },
    })
}
export const idxOfTopicGroupContainingVideo = (
    roleRepResponseVideo: RoleRepResponseVideo,
    candidateAppResponseSet: CandidateAppResponseSet,
) => {
    let idx_of_topic_group_containing_video = -1
    candidateAppResponseSet.response_config.ordered_topic_groups.forEach((tg, idx) =>
        tg.videos.forEach(video => {
            if (video.token === roleRepResponseVideo.token) {
                idx_of_topic_group_containing_video = idx
            }
        }),
    )
    return idx_of_topic_group_containing_video
}

export const mergeInCandidateAppResponseSet = async (
    toToken: string,
    fromTokens: string[],
    mode: 'copy' | 'merge',
) => {
    const res = await axios.post(`/api/merge_candidate_app_response_sets/${toToken}`, {
        fromTokens,
        mode,
    })
    return res.data
}

export const updateCandidateAppResponseSet = async (
    roleSearchToken: string,
    patch: CandidateAppResponseSetPatch,
) => {
    const res = await axios.patch<CandidateAppResponseSet>(
        `/api/candidate_app_response_set_for_role_search/${roleSearchToken}`,
        patch,
    )
    return res.data
}

type CandidateAppResponseSetPatch = Partial<
    Omit<CandidateAppResponseSet, 'response_config'>
> & {
    response_config: Partial<
        Omit<CandidateAppResponseSet['response_config'], 'ordered_topic_groups'> & {
            ordered_topic_groups: (Omit<
                CandidateAppResponseSet['response_config']['ordered_topic_groups'][number],
                'videos'
            > & {
                videos: { token: string }[]
            })[]
        }
    >
}

export const useImportVideosIntoChannel = () => {
    const _post = async (
        token: string,
        sourceTokens: string[],
        import_settings: { retainSections: boolean },
    ) => {
        const res = await axios.post('/api/import_videos_into_channel', {
            token,
            import_from: sourceTokens,
            import_settings,
        })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            token,
            sourceTokens,
            import_settings,
        }: {
            token: string
            sourceTokens: string[]
            import_settings: { retainSections: boolean }
        }) => _post(token, sourceTokens, import_settings),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['feeds'],
            })
        },
    })

    return mutation
}

export const useCandidateAppResponseSetMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            roleSearchToken,
            patch,
        }: {
            roleSearchToken: string
            patch: CandidateAppResponseSetPatch
        }) => updateCandidateAppResponseSet(roleSearchToken, patch),
        onMutate: async ({
            roleSearchToken,
            patch,
        }: {
            roleSearchToken: string
            patch: CandidateAppResponseSetPatch
        }) => {
            await queryClient.cancelQueries({
                queryKey: ['candidate_app_response_set_for_role_search', roleSearchToken],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
            const rs = queryClient.getQueryData<RoleSearch>(['roleSearch', roleSearchToken])
            if (rs) {
                rs.digests.pendingUpdate = true
                queryClient.setQueryData<RoleSearch>(['roleSearch', roleSearchToken], rs)
            }
        },
        onSettled: (
            data: any,
            error: any,
            {
                roleSearchToken,
                patch,
            }: {
                roleSearchToken: string
                patch: CandidateAppResponseSetPatch
            },
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['candidate_app_response_set_for_role_search', roleSearchToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearch', roleSearchToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export const rrrvQuery = (token: string) => ({
    queryKey: ['role_rep_response_library', token],
    queryFn: async () =>
        (await axios.get<RoleRepResponseVideoDetails>(`/api/role_rep_response_videos/${token}`))
            .data,
    enabled: !!token,
})
export const useRoleRepResponseVideo = (token?: string, enabled?: boolean) => {
    const { token: urlToken } = useParams()
    const tokenToUse = token || (urlToken?.startsWith('RRRV_') ? urlToken : '') || ''
    const query = useQuery(rrrvQuery(tokenToUse))
    return query
}

export const useIntroVideos = () => {
    const query = useQuery({
        queryKey: ['role_rep_response_library', 'intro_videos'],

        queryFn: () =>
            getLibraryRoleRepResponseVideos(undefined, undefined, undefined, {
                is_intro_video: true,
            }),
    })
    return query
}

export const useRoleRepResponseVideoLibraryPaged = (
    pageNumber: number,
    pageSize: 'null' | number = 20,
    sourceToken?: string,
    forBuilder?: boolean,
    options?: { enabled: boolean },
    filters?: Partial<
        StaticVideoFilters & {
            tags: string[]
            starRating: number[]
            hideArchived: boolean
            is_intro_video: boolean
            mode: ('Candidates' | 'Contributors')[]
        }
    >,
    searchQuery?: string,
) => {
    const _get = async () => {
        const res = await axios.get<PaginatedResult<RoleRepResponseVideo>>(
            `/api/role_rep_response_library/${sourceToken ? sourceToken : ''}`,
            {
                params: {
                    page_size: pageSize,
                    page: pageNumber,
                    forBuilder: forBuilder ? 'true' : undefined,
                    ...(filters ?? {}),
                    search: searchQuery,
                },
            },
        )
        return { data: res.data.results, totalNumVideos: res.data.count }
    }

    const query = useQuery({
        queryKey: [
            'role_rep_response_library',
            'paged',
            forBuilder ?? false,
            pageNumber,
            pageSize,
            JSON.stringify(Object.entries(filters ?? {}).sort()),
            searchQuery,
            sourceToken,
        ],

        queryFn: _get,
    })
    return { data: query.data, status: query.status }
}

export const useRoleRepResponseVideoLibrary = (
    sourceToken?: string,
    forBuilder?: boolean,
    options?: { enabled: boolean },
    videoTokens?: string[],
    crqTokens?: string[],
) => {
    const queryKey = [
        'role_rep_response_library',
        forBuilder ?? false,
        (videoTokens ?? []).sort().join(','),
        (crqTokens ?? []).sort().join(','),
    ]
    if (sourceToken) {
        queryKey.push(sourceToken)
    }

    const query = useQuery({
        queryKey: queryKey,

        queryFn: () =>
            getLibraryRoleRepResponseVideos(
                sourceToken,
                forBuilder,
                videoTokens,
                undefined,
                crqTokens,
            ),

        ...(options ?? {}),
    })
    return query
}

interface StaticVideoFilters {
    channelFilters: { name: string; token?: string }[]
    emails: string[]
    topics: string[]
    prompts: string[]
    langs: string[]
    campaignFilters: { name: string; token?: string }[]
    feedFilters: { name: string; token: string }[]
}
export const useStaticVideoFilters = ({
    sourceToken,
    hideArchived,
    forBuilder,
}: {
    sourceToken?: string
    hideArchived?: boolean
    forBuilder?: boolean
}) => {
    const getStaticFilters = async (
        sourceToken?: string,
        hideArchived?: boolean,
        forBuilder?: boolean,
    ) => {
        const res = await axios.post<{ filters: StaticVideoFilters }>(
            '/api/get_static_video_filters',
            { sourceToken, hideArchived, forBuilder },
        )
        return res.data.filters
    }
    const query = useQuery({
        queryKey: ['static_video_filters', sourceToken, hideArchived, forBuilder],

        queryFn: () => getStaticFilters(sourceToken, hideArchived, forBuilder),
    })
    return query
}

export const useVideoTagFilters = ({
    sourceToken,
    hideArchived,
    forBuilder,
}: {
    sourceToken?: string
    hideArchived?: boolean
    forBuilder?: boolean
}) => {
    const getStaticFilters = async (
        sourceToken?: string,
        hideArchived?: boolean,
        forBuilder?: boolean,
    ) => {
        const res = await axios.post<{ tags: string[] }>('/api/get_video_filters_tags', {
            sourceToken,
            hideArchived,
            forBuilder,
        })
        return res.data.tags
    }
    const query = useQuery({
        queryKey: ['video_filters_tags', sourceToken, hideArchived, forBuilder],

        queryFn: () => getStaticFilters(sourceToken, hideArchived, forBuilder),
    })
    return query
}

const getLibraryRoleRepResponseVideos = async (
    sourceToken?: string,
    forBuilder?: boolean,
    videoTokens?: string[],
    filters?: Partial<
        StaticVideoFilters & {
            tags: string[]
            starRating: number[]
            hideArchived: boolean
            is_intro_video: boolean
        }
    >,
    crqTokens?: string[],
) => {
    // for a role search, get all library videos from _other_ role searches; otherwise just get them all
    const res = await axios.get<RoleRepResponseVideo[]>(
        `/api/role_rep_response_library/${sourceToken ? sourceToken : ''}`,
        {
            params: {
                forBuilder: forBuilder ? 'true' : undefined,
                page_size: 'null',
                tokens: videoTokens?.join(','),
                contributionRequestToken: crqTokens?.join(','),
                ...(filters ?? {}),
            },
        },
    )
    return res.data
}

const getCandidateAppResponseSet = async (roleSearchToken: string) => {
    // this will get all videos that will be included in this candidate app - note there will be overlap between these videos, the library videos, and the thisRoleSearch videos
    const res = await axios.get<CandidateAppResponseSet>(
        `/api/candidate_app_response_set_for_role_search/${roleSearchToken}`,
    )
    return res.data
}

export const useCandidateAppResponseSet = (roleSearchToken?: string) => {
    const { data: roleSearch } = useRoleSearchQuery(roleSearchToken)
    const query = useQuery({
        queryKey: ['candidate_app_response_set_for_role_search', roleSearch?.token],
        queryFn: () => getCandidateAppResponseSet(roleSearch?.token ?? ''),
        enabled: !!roleSearch?.token,
    })
    return query
}

function isFile(c: any): c is File {
    const f = c as File
    return f.arrayBuffer !== undefined && f.name !== undefined
}
export const addLibraryRoleRepResponseVideo = async (
    form: FormData,
    companyToken?: string,
    userToken?: string,
    limit?: LimitFunction,
) => {
    const video = form.get('video')
    try {
        if (!POST_TO_CF) {
            const post = () =>
                axios.post<RoleRepResponseVideo>('/api/role_rep_response_videos/', form, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
            const res = await (limit ? limit(post) : post())
            return res.data
        } else {
            const sessionIdentifier = getNewSessionId()
            const baseUploadUrl = `${storageDomain}/upload/${companyToken}/${userToken}/${sessionIdentifier}`
            if (!isFile(video)) {
                throw new Error('Video should be a file')
            }
            const uploadUrl = `${baseUploadUrl}/${video.name}`
            await axios.put(uploadUrl, video)
            form.delete('video')
            form.append('videourl', uploadUrl)
            const post = () =>
                axios.post<RoleRepResponseVideo>('/api/role_rep_response_videos/', form, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
            const res = await (limit ? limit(post) : post())
            return res.data
        }
    } catch {
        throw new Error((video as File | undefined)?.name ?? '')
    }
}

const getLibraryAnalytics = async () => {
    const res = await axios.get<{ views_per_video: Record<string, VideoAnalytics> }>(
        '/api/get_library_analytics',
    )
    return res.data
}

export const useLibraryAnalytics = () => {
    const query = useQuery({
        queryKey: ['libraryAnalytics'],
        queryFn: getLibraryAnalytics,
    })
    return query
}

export const useAllCandidateAppResponseSets = (
    { enabled }: { enabled: boolean } = { enabled: true },
) => {
    const query = useQuery({
        queryKey: ['getAllCandidateAppResponseSets'],

        queryFn: async () =>
            (await axios.get<CandidateAppResponseSet[]>('/api/get_all_candidate_app_response_sets'))
                .data,

        enabled,
    })
    return query
}

export const replaceVideoInCarss = async (
    videoTokenToReplace: string,
    videoTokensToReplaceWith: string[],
) => {
    const res = await axios.post(`/api/replace_video_in_carss/${videoTokenToReplace}`, {
        videoTokensToReplaceWith,
    })
    return res
}
export const removeVideosFromCarss = async (videoTokensToRemove?: string[]) => {
    const res = await axios.post('/api/remove_videos_from_carss', {
        videoTokensToRemove,
    })
    return res.data
}

export const addVideoToCARSS = async (videoTokenToAdd: string, roleSearchTokens: string[]) => {
    const res = await axios.post(`/api/add_video_to_carrs/${videoTokenToAdd}`, { roleSearchTokens })
    return res
}
export interface ResponseVideoQueryResultType extends RoleRepResponseVideo {
    headline: string
}
export const useQueryRolerepResponseVideos = ({ query }: { query?: string }) => {
    return useQuery({
        queryKey: ['role_rep_response_library', 'search', query],

        queryFn: async () => {
            return (
                await axios.get<{ data: ResponseVideoQueryResultType[] }>(
                    '/api/rolerep_response_search',
                    { params: { query } },
                )
            ).data.data
        },
    })
}

export const useVideoInfoByFileName = ({ enabled }: { enabled: boolean } = { enabled: true }) => {
    //currently this is only used for the staff-only contributors page; we should look into moving that logic to the backend if/when we want to de-staffify that feature
    const { data: candidateAppResponseSets } = useAllCandidateAppResponseSets({ enabled })
    const _videoInfoByFileName: Record<string, RoleRepResponseVideo[]> = {}
    candidateAppResponseSets?.forEach(cars => {
        cars.response_config.ordered_topic_groups.forEach(tg => {
            tg.videos.forEach(v => {
                const fileName = v.url.split('/').slice(-1).pop()?.split('?')?.[0] ?? ''
                _videoInfoByFileName[fileName] = [
                    ...(_videoInfoByFileName?.[fileName] ?? []),
                    { ...v, role_search_token: cars.role_search_token },
                ]
            })
        })
    })
    return _videoInfoByFileName
}

const toggleRoleRepResponseVideoInclusion = (
    roleRepResponseVideo: RoleRepResponseVideo,
    candidateAppResponseSet: CandidateAppResponseSet,
) => {
    const updatedCandidateResponseSet = { ...candidateAppResponseSet }
    const idx = idxOfTopicGroupContainingVideo(roleRepResponseVideo, candidateAppResponseSet)
    if (idx === -1) {
        // this video was not selected, so select it
        // does a topic for this video _exist_ already?
        const topicIdx = updatedCandidateResponseSet.response_config.ordered_topic_groups.findIndex(
            tg => tg.topic === roleRepResponseVideo.prompt_question_info.topic,
        )
        if (topicIdx === -1) {
            // no, so make a new topic object
            const newTopicObj: OrderedTopicGroup = {
                videos: [roleRepResponseVideo],
                topic: roleRepResponseVideo.prompt_question_info.topic,
                is_intro_topic:
                    roleRepResponseVideo.prompt_question_info.topic === 'Team Member Introduction'
                        ? true
                        : undefined, // TODO: get this string from backend?
            }
            updatedCandidateResponseSet.response_config.ordered_topic_groups.push(newTopicObj)
        } else {
            // yes, so just add this video to the end of that one
            updatedCandidateResponseSet.response_config.ordered_topic_groups[topicIdx].videos.push(
                roleRepResponseVideo,
            )
        }
    } else {
        // this video was selected, so unselect it
        updatedCandidateResponseSet.response_config.ordered_topic_groups[idx].videos =
            updatedCandidateResponseSet.response_config.ordered_topic_groups[idx].videos.filter(
                v => v.token !== roleRepResponseVideo.token,
            )
        updatedCandidateResponseSet.response_config.ordered_topic_groups =
            updatedCandidateResponseSet.response_config.ordered_topic_groups.filter(
                tg => tg.videos.length > 0,
            )
    }

    return updatedCandidateResponseSet
}

export const useBulkMakeVideosAvailable = () => {
    // this mutation makes all (unarchived) responses to a channel available to other channels
    const bulkAvailable = async (role_search_token: string) => {
        const res = await axios.get(`/api/bulk_make_videos_available/${role_search_token}`)
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (role_search_token: string) => bulkAvailable(role_search_token),
        onMutate: async (token: string) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
        },
        onSettled: (data: any, error: any, token: string) => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
        },
    })
    return mutation
}

export const useBulkAddVideosToCARSMutation = () => {
    // this mutation adds all (unarchived) responses to a channel to that channel
    const bulkAdd = async (channelToken: string) => {
        const res = await axios.get(`/api/bulk_add_all_responses_to_cars/${channelToken}`)
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (channelToken: string) => bulkAdd(channelToken),
        onMutate: async (channelToken: string) => {
            await queryClient.cancelQueries({
                queryKey: ['candidate_app_response_set_for_role_search', channelToken],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearch', channelToken],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: (data: any, error: any, channelToken: string) => {
            queryClient.invalidateQueries({
                queryKey: ['candidate_app_response_set_for_role_search', channelToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearch', channelToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
        },
    })
    return mutation
}

export const useToggleVideoInclusionInCARSMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            video,
            cars,
        }: {
            video: RoleRepResponseVideo
            cars: CandidateAppResponseSet
        }) => {
            const updatedCARS = toggleRoleRepResponseVideoInclusion(video, cars)
            return updateCandidateAppResponseSet(updatedCARS.role_search_token, {
                response_config: updatedCARS.response_config,
            })
        },
        onMutate: async ({
            video,
            cars,
        }: {
            video: RoleRepResponseVideo
            cars: CandidateAppResponseSet
        }) => {
            await queryClient.cancelQueries({
                queryKey: ['candidate_app_response_set_for_role_search', cars.role_search_token],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_videos_for_role_search', cars.role_search_token],
            })

            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearch', cars.role_search_token],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSettled: (
            data: any,
            error: any,
            {
                video,
                cars,
            }: {
                video: RoleRepResponseVideo
                cars: CandidateAppResponseSet
            },
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['candidate_app_response_set_for_role_search', cars.role_search_token],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_videos_for_role_search', cars.role_search_token],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearch', cars.role_search_token],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export const useAddVideosToChannels = () => {
    const _post = async (rsToken: string, videoTokens: string[]) => {
        const res = await axios.post('/api/add_videos_to_channel/' + rsToken, { videoTokens })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ rsToken, videoTokens }: { rsToken: string; videoTokens: string[] }) =>
            _post(rsToken, videoTokens),
        onMutate: async ({ rsToken, videoTokens }: { rsToken: string; videoTokens: string[] }) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_videos_for_role_search', rsToken],
            })

            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearch', rsToken],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: (
            data: any,
            error: any,
            {
                rsToken,
                videoTokens,
            }: {
                rsToken: string
                videoTokens: string[]
            },
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_videos_for_role_search', rsToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearch', rsToken],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
        },
    })
    return mutation
}

export const useVideoWatchedData = (
    videoTokens: string[],
    sourceTokens?: string[],
    candidateSources?: string[],
) => {
    const _get = async () => {
        const res = await axios.post<{
            heatmapData: Record<string, Record<number, number>>
            stoppedAtData: Record<string, Record<number, number>>
        }>('/api/video_watched_histogram_data', { videoTokens, sourceTokens, candidateSources })
        return res.data
    }

    const query = useQuery({
        queryKey: [
            videoTokens.sort(),
            (sourceTokens ?? []).sort(),
            (candidateSources ?? []).sort(),
        ],
        queryFn: _get,
    })
    return query
}

export const usePublishVideoMutation = () => {
    const queryClient = useQueryClient()
    const _post = async (token: string) => {
        const res = await axios.post('/api/publish_video/' + token, {})
        return res.data
    }
    const mutation = useMutation({
        mutationFn: (token: string) => _post(token),
        onMutate: async (token: string) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['videoIsPublished', token],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSettled: async (data: any, error: any, token: string) => {
            await queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.invalidateQueries({
                queryKey: ['videoIsPublished', token],
            })
            await queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export const usePublicUrlMutation = () => {
    const queryClient = useQueryClient()
    const _get = async (token: string) => {
        const res = await axios.post('/api/get_public_url', { token })
        return res.data
    }
    const mutation = useMutation({
        mutationFn: (token: string) => _get(token),
        onMutate: async (token: string) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['videoIsPublished', token],
            })
        },
        onSettled: (data: any, error: any, token: string) => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['videoIsPublished', token],
            })
        },
    })
    return mutation
}

export const postToLinkedIn = async (videoToken: string) => {
    const res = await axios.post<{ url?: string }>('/api/post_video_to_linkedin', { videoToken })
    return res.data?.url ?? '' // if the access token is expired, we will send back the reauth url
}

export type GraphResolution = 'day' | 'week' | 'month' | 'year'

export const useVideoCountData = (resolution: GraphResolution, companyNameFilter?: string) => {
    const _get = async (resolution: GraphResolution, companyNameFilter?: string) => {
        const res = await axios.post<{
            data: { date: string; count: number; cum_count: number }[]
        }>('/api/video_graph_counts', { resolution, companyNameFilter })
        return res.data.data
    }

    const query = useQuery({
        queryKey: ['video_count_graph_data', resolution, companyNameFilter ?? ''],

        queryFn: () => _get(resolution, companyNameFilter),
    })
    return query
}

export const useDownloadRrrvAtAspectRatio = () => {
    return useMutation({
        mutationFn: async ({
            token,
            aspectRatio,
            withoutWatermark,
            mode,
        }: {
            token: string
            aspectRatio: string
            withoutWatermark: boolean
            mode?: string
        }) => {
            const res = await pollableEndpoint<{ url: string }>({
                url: `/api/videos/download/${token}/${aspectRatio}/${
                    withoutWatermark ? 'clean' : ''
                }`,
                params: {
                    mode,
                },
            })
            const anchor = document.createElement('a')
            let ready = false
            while (!ready) {
                await new Promise(r => setTimeout(r, 256))
                ready =
                    (
                        await axios.get(res.data.url, {
                            headers: {
                                Range: 'bytes=0-0',
                            },
                            validateStatus: s => s < 500,
                        })
                    ).status < 400
            }

            anchor.href = res.data.url
            anchor.setAttribute('download', `${token}_${aspectRatio}_${new Date().toDateString()}`)
            anchor.setAttribute('filename', `${token}_${aspectRatio}_${new Date().toDateString()}`)
            anchor.setAttribute('target', '_blank')
            anchor.click()
            anchor.remove()
            return res.data
        },
    })
}

export const useRoleRepResponseVideoClips = (token?: string) => {
    return useQuery({
        queryKey: ['role_rep_response_library', 'clips', token],

        queryFn: async () => {
            const res = await axios.get<RoleRepResponseVideo[]>(
                `/api/role_rep_response_video_clips/${token}`,
            )
            return res.data
        },

        enabled: !!token,
    })
}

export const useArchiveRoleRepResponseVideos = () => {
    const qc = useQueryClient()
    return useMutation({
        mutationFn: async ({ tokens }: { tokens: string[] }) => {
            const res = await axios.post(`/api/archive_role_rep_response_videos/`, { tokens })
            return res.data
        },
        onSettled: () => {
            return Promise.allSettled([
                qc.invalidateQueries({
                    queryKey: ['artifacts'],
                }),
                qc.invalidateQueries({
                    queryKey: ['role_rep_response_library'],
                }),
            ])
        },
    })
}

export const useAddTagsToVideos = () => {
    const qc = useQueryClient()
    return useMutation({
        mutationFn: ({ newTags, videoTokens }: { newTags: string[]; videoTokens: string[] }) => {
            return axios.post('/api/add_tags_to_videos', {
                newTags,
                videoTokens,
            })
        },
        onSettled: () => {
            return Promise.allSettled([
                qc.invalidateQueries({
                    queryKey: ['artifacts'],
                }),
                qc.invalidateQueries({
                    queryKey: ['role_rep_response_library'],
                }),
                qc.invalidateQueries({
                    queryKey: ['video_filters_tags'],
                }),
            ])
        },
    })
}
