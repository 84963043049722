import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

export enum ClientPermissions {
    CAN_EDIT_ALL_CHANNELS = 1,
    CAN_CREATE_CHANNEL = 2,
    CAN_VIEW_SETTINGS = 3,
    CAN_VIEW_ALL_CHANNELS = 4,
    CAN_MARK_CANDIDATES_ALL_CHANNELS = 5,
    CAN_VIEW_LIBRARY = 6,
}
export const getClientPermissionFromNumber = (numToMatch: number) =>
    Object.entries(ClientPermissions).find(([key, value]) => value === numToMatch)?.[1]

export enum AttachedRoleSearchPermissions {
    CAN_EDIT = 1,
    CAN_MARK_CANDIDATES = 2,
    CAN_VIEW = 3,
}
export const getAttachedRoleSearchPermissionsFromNumber = (numToMatch: number) =>
    Object.entries(AttachedRoleSearchPermissions).find(([key, value]) => value === numToMatch)?.[1]

export const getUserPermissions = async () => {
    const _userPermissionsList = await axios.get<{
        permissions: ClientPermissions[]
    }>('/auth/get_permissions')
    const userPermissions = _userPermissionsList.data.permissions
        .map((num: number) => getClientPermissionFromNumber(num))
        .filter(perm => perm !== undefined) as ClientPermissions[]
    return userPermissions
}
