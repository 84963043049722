import React, { lazy, Suspense } from 'react'
import { PagePublishButtonsProps, PagesDetailProps } from './PagesDetail'

const LazyPagesDetail = lazy(() => import('./PagesDetail'))

const PagesDetail = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PagesDetailProps,
) => (
    <Suspense fallback={null}>
        <LazyPagesDetail {...props} />
    </Suspense>
)

export default PagesDetail

const LazyPagePublishButons = lazy(() =>
    import('./PagesDetail').then(m => ({ default: m.PagePublishButtons })),
)

export const PagePublishButtons = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PagePublishButtonsProps,
) => (
    <Suspense fallback={null}>
        <LazyPagePublishButons {...props} />
    </Suspense>
)
