import axios from 'axios'
import {
    ClientPermissions,
    getClientPermissionFromNumber,
    AttachedRoleSearchPermissions,
} from 'services/permission_service'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { MainMenuOptions, MainMenuIcons } from 'components/Header/MainMenuOptions'
import { MenuOptionKey } from './main_menu'

export interface AttachedRoleSearchInfo {
    role_search_token: string
    user_token: string
    permissions: AttachedRoleSearchPermissions[]
}
export const DefaultTabPermissions = {}

export type TabPermissions = Partial<
    Record<
        (typeof MainMenuOptions)[number]['name'] | MenuOptionKey,
        boolean | undefined | { pinPrecedence: number }
    >
>
export type IntegrationLinkedUsers = Record<string, number | string | undefined> // todo type better to externalservice id
interface InterviewPermissions {
    mode: 'ado' | 'all' | 'ats'
}
export interface UserInfo {
    token: string
    email: string
    friendly_name?: string
    companyName?: string
    isStaff?: boolean
    password_set?: boolean
    permissions: ClientPermissions[]
    tab_permissions: TabPermissions
    last_login?: string
    invited?: boolean
    attached_role_searches: AttachedRoleSearchInfo[]
    channels_table_columns: string[] | null
    is_active: boolean

    settings?: {
        readonly unverified?: boolean
        contentFilters?: {
            tags?: string[]
            campaigns?: string[]
            requesterEmails?: string[]
            contributorEmails?: string[]
        }
        linkedin_access_token?: string
        sidebarMode?: 'recruiter' | 'manager'
        defaultLayoutMode?: 'sidebar' | 'old'
        intakeQuestions?: { jobTypes?: string[]; industries?: string[]; companySizes?: string[] }
        integrationLinkedUsers?: IntegrationLinkedUsers
        interviewPermissions?: InterviewPermissions
        staffPermissions?: {
            spoofAll?: boolean
            spoofAllJobBoard?: boolean
            projectAll?: boolean
            allowedSpoofTokens?: string[]
            allowedProjectTokens?: string[]
            editSettings?: boolean
            canCreateCompany?: boolean
            visibleTabsByCompany?: Record<
                string,
                Partial<
                    Record<
                        | (typeof MainMenuOptions)[number]['name']
                        | (typeof MainMenuIcons)[number]['name']
                        | 'notificationCenter',
                        boolean | undefined
                    >
                >
            >
        }
    }
}

export const userInfoQueryArg = () => {
    return {
        queryKey: ['userInfo'],
        queryFn: getUserInfo,
    }
}
export const useUserInfo = (enabled?: boolean) => {
    const query = useQuery({
        ...userInfoQueryArg(),
        enabled: enabled === undefined ? true : enabled,
    })
    return query
}
const getUserInfo = async () => {
    const res = await axios.get<UserInfo>('/auth/user_info')
    return res.data
}

export const useClientUsers = <T extends keyof UserInfo>(fields?: T[], token?: string) => {
    type retType = Pick<UserInfo, T>
    const queryKey = ['users']
    if (fields) {
        queryKey.push(JSON.stringify(fields))
    }
    if (token) {
        queryKey.push(token)
    }
    const query = useQuery({
        queryKey: queryKey,

        queryFn: async () => {
            if (fields) {
                // had a hard time getting the types to work correctly defaulting the undefined case to all keys
                const res = await axios.get<retType[]>('/api/users' + (token ? `/${token}` : ''), {
                    params: { fields: fields.join(',') },
                })
                return res.data
            } else {
                const res = await axios.get<UserInfo[]>('/api/users' + (token ? `/${token}` : ''))
                return res.data
            }
        },
    })
    return query
}

export const useAddClientUser = () => {
    const addClientUser = async ({
        email,
        friendly_name,
        settings,
    }: {
        email: string
        friendly_name: string
        settings?: UserInfo['settings']
    }) => {
        const res = await axios.post<UserInfo>('/api/users', { email, friendly_name, settings })
        const userInfo = res.data
        userInfo.permissions = (userInfo?.permissions ?? [])
            .map(p => getClientPermissionFromNumber(p))
            .filter(p => p !== undefined) as ClientPermissions[]
        return userInfo
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            email,
            friendly_name,
            settings,
        }: {
            email: string
            friendly_name: string
            settings?: UserInfo['settings']
        }) => addClientUser({ email, friendly_name, settings }),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: ['users'] })
            await queryClient.cancelQueries({ queryKey: ['company_details_for_company'] })
            await queryClient.cancelQueries({ queryKey: ['password_reset_links'] })
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
            queryClient.invalidateQueries({ queryKey: ['company_details_for_company'] })
        },
    })
    return mutation
}

export const updateClientUser = async (userToken: string, patch: Partial<UserInfo>) => {
    const res = await axios.patch<UserInfo>(`/api/users/${userToken}`, patch)
    return res.data
}

export const getResetPasswordLinks = async (emails: string[]) => {
    const res = await axios.post<{ links_by_email: Record<string, string> }>(
        '/auth/get_reset_password_links',
        { emails },
    )
    return res.data.links_by_email
}

export const useSendLoginEmail = () => {
    const sendLoginEmail = async (token: string) => {
        const res = await axios.get(`/api/send_login_email/${token}`)
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: sendLoginEmail,
        onMutate: () => queryClient.cancelQueries({ queryKey: ['users'] }),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['users'] })
        },
    })
    return mutation
}

export const useStaffUsers = () => {
    const getStaffUsers = async () => {
        const res = await axios.get<UserInfo[]>('/api/users?staffUsers=true')
        return res.data
    }
    const query = useQuery({
        queryKey: ['staffUsers'],
        queryFn: getStaffUsers,
    })
    return query
}

export const useAddStaffUser = () => {
    const addUser = async (email: string) => {
        const res = await axios.post<UserInfo>('/api/users?staffUsers=true', { email })
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (email: string) => addUser(email),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['staffUsers'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['staffUsers'],
            })
        },
    })
    return mutation
}

export const useUserBookmarkTokens = () => {
    const _get = async () => {
        const res = await axios.get<{ data: string[] }>('/api/user_bookmarks')
        return res.data.data
    }
    const query = useQuery({
        queryKey: ['user_bookmarks'],
        queryFn: _get,
    })
    return query
}

export const useUpdateUserBookmark = () => {
    const queryClient = useQueryClient()
    const _post = async (token: string, beChecked: boolean) => {
        const res = await axios.post('/api/user_bookmarks', { token, beChecked })
        return res.data
    }

    const mutation = useMutation({
        mutationFn: ({ token, beChecked }: { token: string; beChecked: boolean }) =>
            _post(token, beChecked),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['user_bookmarks'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library', 'paged'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_searches', 'paged'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['user_bookmarks'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library', 'paged'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_searches', 'paged'],
            })
        },
    })
    return mutation
}

export const useUpdateUser = (staff: boolean) => {
    const key = staff ? ['staffUsers'] : ['users']
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async ({ token, patch }: { token: string; patch: Partial<UserInfo> }) => {
            return await updateClientUser(token, patch)
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: key })
            await queryClient.cancelQueries({ queryKey: ['users'] })
            await queryClient.cancelQueries({
                queryKey: ['userInfo'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: key })
            queryClient.invalidateQueries({ queryKey: ['users'] })
            queryClient.invalidateQueries({
                queryKey: ['userInfo'],
            })
        },
    })
    return mutation
}

export const useResendVerificationEmail = () => {
    const _get = async () => {
        const res = await axios.get('/auth/resend_verification_email')
        return res.data
    }
    const mutation = useMutation({
        mutationFn: _get,
    })
    return mutation
}
