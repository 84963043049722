import React, { lazy } from 'react'
import { InterviewsListProps } from './InterviewsList'
const LazyInterviewsList = lazy(() => import('./InterviewsList'))

const InterviewsList = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewsListProps,
) => <LazyInterviewsList {...props} />

export default InterviewsList
