import React, { lazy, Suspense } from 'react'
import { ShowcaseDetailsProps } from './ShowcaseDetails'
const LazyShowcaseDetails = lazy(() => import('./ShowcaseDetails'))

const ShowcaseDetails = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ShowcaseDetailsProps,
) => (
    <Suspense fallback={null}>
        <LazyShowcaseDetails {...props} />
    </Suspense>
)

export default ShowcaseDetails
