import React, { lazy, Suspense } from 'react'
import {
    ChannelBuilderProps,
    ChannelSettingsDrawerProps,
    DraggableVideoCardProps,
    AccentColorProps,
} from './ChannelBuilder'
const LazyChannelBuilder = lazy(() => import('./ChannelBuilder'))

const ChannelBuilder = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelBuilderProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelBuilder {...props} />
    </Suspense>
)

export default ChannelBuilder

const LazyChannelSettingsDrawer = lazy(() =>
    import('./ChannelBuilder').then(m => ({ default: m.ChannelSettingsDrawer })),
)

export const ChannelSettingsDrawer = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelSettingsDrawerProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelSettingsDrawer {...props} />
    </Suspense>
)

const LazyDraggableVideoCard = lazy(() =>
    import('./ChannelBuilder').then(m => ({ default: m.DraggableVideoCard })),
)

export const DraggableVideoCard = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & DraggableVideoCardProps,
) => (
    <Suspense fallback={null}>
        <LazyDraggableVideoCard {...props} />
    </Suspense>
)
const LazyAccentColor = lazy(() =>
    import('./ChannelBuilder').then(m => ({ default: m.AccentColor })),
)

export const AccentColor = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & AccentColorProps,
) => (
    <Suspense fallback={null}>
        <LazyAccentColor {...props} />
    </Suspense>
)
