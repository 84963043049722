import React, { lazy } from 'react'
import { InterviewDetailsJobsProps } from './InterviewDetailsJobs'
const LazyInterviewDetailsJobs = lazy(() => import('./InterviewDetailsJobs'))

const InterviewDetailsJobs = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsJobsProps,
) => <LazyInterviewDetailsJobs {...props} />

export default InterviewDetailsJobs
