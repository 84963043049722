import axios from 'axios'
import React from 'react'
import { UserStatus } from 'services/login_service'
import { Navigate } from 'react-router-dom'
import { useCompanyDetails } from './company_service'
import { useQuery } from '@tanstack/react-query'
import { EmailInfo } from 'services/role_rep_service'
export const getAdminUrl = (urlSuffix: string) => {
    let adminUrl = `${window.location.host.replace(':3000', ':8000')}/admin/common/${urlSuffix}`
    if (!window.location.host.startsWith('localhost')) {
        adminUrl = 'https://' + adminUrl
    }
    return adminUrl
}

export const storageDomain =
    import.meta.env.VITE_STORAGE_DOMAIN || 'https://wt-dev-media.s3.amazonaws.com'

export const useCareersPageExists = () => {
    const { data: companyDetails, status: companyStatus } = useCompanyDetails()
    const companySlug = companyDetails?.slug_name ?? ''
    const status = useQuery({
        queryKey: ['careersSite', companySlug],

        queryFn: async () => {
            const url = `${storageDomain}/careersSiteData/${companySlug}/jobData.json`
            const res = await axios.get(url, { params: '', withCredentials: false, headers: {} })
            return res
        },

        retry: false,
        enabled: companyStatus === 'success',
    }).status
    switch (status) {
        case 'success':
            return true
        case 'error':
            return false
        default:
            return undefined
    }
}

export const StaffOnlyElementWrapper = (
    component: React.ReactElement,
    userStatus: UserStatus,
    redirectPath: string,
) =>
    userStatus === UserStatus.LoggedInStaff ? (
        component
    ) : (
        <Navigate
            replace
            to={redirectPath + window.location.search}
        />
    )

export const emailPersonalizationRegex = /\*\|.*?\|\*/g
const additionalBodyPersonalizations = [
    '*|roleRepScheduleLink|*',
    '*|langLink|*',
    '*|roleRepName|*',
    '*|dueDate|*',
    '*|fromName|*',
]
export const unknownPersonalizations = (
    defaultPersonalizations: { subject: string[]; body: string[] },
    template: EmailInfo,
) => {
    // a match will return a list of matched items; no match returns null
    const bodyPersonalizations = template.html_body.match(emailPersonalizationRegex) || []
    const subjectPersonalizations = template.subject.match(emailPersonalizationRegex) || []

    const badBodyPersonalizations = bodyPersonalizations.filter(
        bp => ![...defaultPersonalizations.body, ...additionalBodyPersonalizations].includes(bp),
    )
    const badSubjectPersonalizations = subjectPersonalizations.filter(
        sp => !defaultPersonalizations.subject.includes(sp),
    )

    return { subject: badSubjectPersonalizations, body: badBodyPersonalizations }
}

export const getCSReportData = async () => {
    const res = await axios.get('/api/get_cs_report')
    return res.data
}

export const getContributorReportData = async () => {
    const res = await axios.get('/api/get_contributor_report')
    return res.data
}

export const getSalesEnrichmentData = async (form: FormData) => {
    const res = await axios.post('/api/enrich_sales_prospects', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data
}
