import React, { ReactNode } from 'react'
import styled from 'styled-components'

const BaseWrap = styled.div`
    background: #f4f5f5;
    min-height: 100vh;
    width: 100%;
    display: flex;
`

export const BasePage = ({
    children,
    ...rest
}: React.ComponentProps<typeof BaseWrap> & { children: ReactNode }) => {
    return <BaseWrap {...rest}>{children}</BaseWrap>
}
export const Base = styled(BasePage)`
    flex-direction: column;
    min-width: 1000px;
`
