import React, { lazy, Suspense } from 'react'
import { ProspectProps } from './Prospect'

const LazyProspect = lazy(() => import('./Prospect'))

const Prospect = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ProspectProps,
) => (
    <Suspense fallback={null}>
        <LazyProspect {...props} />
    </Suspense>
)

export default Prospect
