import React, { lazy, Suspense } from 'react'

const LazyUserSettings = lazy(() => import('./UserSettings'))

const UserSettings = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode },
) => (
    <Suspense fallback={null}>
        <LazyUserSettings {...props} />
    </Suspense>
)

export default UserSettings
