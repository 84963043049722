import React, { lazy, Suspense } from 'react'
import {
    AddContentModalProps,
    DropZoneSectionProps,
    PagesBuilderProps,
    PageSettingsDrawerProps,
} from './PagesBuilder'

const LazyPagesBuilder = lazy(() => import('./PagesBuilder'))

const PagesBuilder = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PagesBuilderProps,
) => (
    <Suspense fallback={null}>
        <LazyPagesBuilder {...props} />
    </Suspense>
)

export default PagesBuilder

const LazyDropZoneSection = lazy(() =>
    import('./PagesBuilder').then(m => ({ default: m.DropZoneSection })),
)

export const DropZoneSection = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & DropZoneSectionProps,
) => (
    <Suspense fallback={null}>
        <LazyDropZoneSection {...props} />
    </Suspense>
)

const LazyAddContentModal = lazy(() =>
    import('./PagesBuilder').then(m => ({ default: m.AddContentModal })),
)

export const AddContentModal = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & AddContentModalProps,
) => (
    <Suspense fallback={null}>
        <LazyAddContentModal {...props} />
    </Suspense>
)

const LazyPageSettingsDrawer = lazy(() =>
    import('./PagesBuilder').then(m => ({ default: m.PageSettingsDrawer })),
)

export const PageSettingsDrawer = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PageSettingsDrawerProps,
) => (
    <Suspense fallback={null}>
        <LazyPageSettingsDrawer {...props} />
    </Suspense>
)
