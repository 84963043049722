import React, { lazy } from 'react'
import { AsyncCandidatesApplicationsProps } from './AsyncCandidatesApplications'
const LazyAsyncCandidatesApplications = lazy(() => import('./AsyncCandidatesApplications'))

const AsyncCandidatesApplications = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AsyncCandidatesApplicationsProps,
) => <LazyAsyncCandidatesApplications {...props} />

export default AsyncCandidatesApplications
