import React, { lazy, Suspense } from 'react'
import { RolesearchDetailProps } from './RolesearchDetail'

const LazyRolesearchDetail = lazy(() => import('./RolesearchDetail'))

const RolesearchDetail = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & RolesearchDetailProps,
) => (
    <Suspense fallback={null}>
        <LazyRolesearchDetail {...props} />
    </Suspense>
)

export default RolesearchDetail
