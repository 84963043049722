import React, { lazy, Suspense } from 'react'
import {
    CollectionBuilderModalProps,
    ContentProps,
    ContributorCardProps,
    ItemCardProps,
    VideoCardProps,
    VideosProps,
    Wv8BuilderContributorPickerPopoverProps,
} from './Content'
import { Contributor, RoleRep } from 'services/role_rep_service'

const LazyContent = lazy(() => import('./Content'))

const Content = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ContentProps,
) => (
    <Suspense fallback={null}>
        <LazyContent {...props} />
    </Suspense>
)

export default Content

const LazyVideos = lazy(() => import('./Content').then(m => ({ default: m.Videos })))

export const Videos = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideosProps,
) => <LazyVideos {...props} />

const LazyItemCard = lazy(() => import('./Content').then(m => ({ default: m.ItemCard })))

export const ItemCard = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ItemCardProps,
) => (
    <Suspense fallback={null}>
        <LazyItemCard {...props} />
    </Suspense>
)

const LazyCollectionBuilderModal = lazy(() =>
    import('./Content').then(m => ({ default: m.CollectionBuilderModal })),
)

export const CollectionBuilderModal = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CollectionBuilderModalProps,
) => (
    <Suspense fallback={null}>
        <LazyCollectionBuilderModal {...props} />
    </Suspense>
)

const LazyContributorCard = lazy(() =>
    import('./Content').then(m => ({ default: m.ContributorCard })),
)

export const ContributorCard = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ContributorCardProps,
) => (
    <Suspense fallback={null}>
        <LazyContributorCard {...props} />
    </Suspense>
)

const LazyVideoCard = lazy(() => import('./Content').then(m => ({ default: m.VideoCard })))

export const VideoCard = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoCardProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoCard {...props} />
    </Suspense>
)

const LazyWv8BuilderContributorPickerPopover = lazy(() =>
    import('./Content').then(m => ({ default: m.Wv8BuilderContributorPickerPopover })),
)

export const Wv8BuilderContributorPickerPopover = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & Wv8BuilderContributorPickerPopoverProps<Contributor | RoleRep>,
) => (
    <Suspense fallback={null}>
        <LazyWv8BuilderContributorPickerPopover {...props} />
    </Suspense>
)
