// though intercoms example event names are camelCase, they mangle that in their UI.  snake_case replaces _ with ' ' so thats alright

export const createdJobPostingEvent = (token: string, unlinked: boolean) => {
    window?.Intercom?.('trackEvent', 'create_job_posting', {
        token: token,
        importedFromATS: !unlinked,
    })
}

export const madeJobPostingActiveEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'toggled_job_active', {
        token: token,
    })
}

export const madeJobPostingInactiveEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'toggled_job_inactive', {
        token: token,
    })
}

export const createChannelEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'created_channel', {
        token: token,
    })
}

export const publishedChannelEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'published_channel', {
        token,
    })
}

export const createInterviewEvent = (name: string, mode: 'candidate' | 'contributor') => {
    window?.Intercom?.('trackEvent', 'created_inteview', {
        name,
        mode,
    })
}

export const createRoleRepsEvent = (count: number, sentEmail: boolean) => {
    window?.Intercom?.('trackEvent', 'created_crqs', {
        count,
        sentEmail,
    })
}

export const viewedCRQResponseDetailEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'viewed_crq_response_detail', {
        token,
    })
}

export const viewedCandidateResponseDetailEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'viewed_candidate_response_detail', {
        token,
    })
}

export const createdShowcaseEvent = (token: string) => {
    window?.Intercom?.('trackEvent', 'created_showcase', {
        token,
    })
}
