import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
    Button,
    ConfigProvider,
    Input,
    Menu,
    Select,
    Spin,
    InputRef,
    Tag,
    Tooltip,
    Popover,
} from 'antd'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useMatches, resolvePath, UIMatch, matchPath } from 'react-router-dom'
import { Program, isProgram, useCreateProgram, usePrograms } from 'services/program_service'
import { useRoleRepTags } from 'services/role_rep_service'
import { getColorByBgColor } from 'services/utils/CommonUtils'
import styled, { css } from 'styled-components'
import { useQueryClient } from '@tanstack/react-query'
import { useHeaderBarFilters } from './useHeaderBarFilters'
import { useVideoTagFilters } from 'services/role_rep_response_video_service'
import { MenuOption, useMenuOptions } from 'services/main_menu'
import {
    ExternalService,
    JobListJob,
    isJobListJob,
    useExternalJobsList,
    useImportExternalJobMutation,
} from 'services/external_integrations_service'
import { Feed, useCreateFeed, useFeedTags } from 'services/feed_services'
import { RoleSearch, useKnownExternalIds } from 'services/role_search_service'
import { useUserIsStaff } from 'services/login_service'
import React from 'react'
import { useUserInfo } from 'services/user_service'
import { useCompanyDetails } from 'services/company_service'

export const JobFilter = ({
    mode,
    onChange,
}: {
    mode?: 'Candidate' | 'Contributor'
    onChange?: () => void
}) => {
    const createProgram = useCreateProgram()
    const [filters, setFilters] = useHeaderBarFilters()
    const [open, setOpen] = useState(false)
    const [jobSearch, setJobSearch] = useState('')
    const { data: programs } = usePrograms()
    const [selectTopCardNow, setSelectTopCardNow] = useState(false)
    const filterCallback = useCallback(
        (selectedProgram: Program) => {
            setFilters({ ...filters, programToken: selectedProgram.token })
            setSelectTopCardNow(false)
            setOpen(false)
            setJobSearch(selectedProgram.name)
            onChange?.()
        },
        [filters, onChange, setFilters],
    )

    return (
        <>
            <Popover
                trigger="click"
                open={open}
                destroyTooltipOnHide
                onOpenChange={setOpen}
                placement="bottomLeft"
                content={
                    <JobPickerPopover
                        searchOverride={jobSearch}
                        filterCallback={filterCallback}
                        selectTopCardNow={selectTopCardNow}
                    />
                }
            >
                {/*wrap in an extra div because Select doesn't pass refs through in the way that Popover likes (so it falls back to findDOMNode, which is deprecated) */}
                <div>
                    <Select
                        style={{ width: 150 }}
                        placeholder="Project"
                        open={false}
                        value={filters.programToken}
                        showSearch
                        onSearch={setJobSearch}
                        searchValue={jobSearch}
                        options={programs?.map(p => ({ value: p.token, label: p.name }))}
                        allowClear
                        onClear={() => {
                            setFilters({ ...filters, programToken: undefined })
                            onChange?.()
                        }}
                        onKeyUp={e => {
                            if (e.code === 'Enter') {
                                setSelectTopCardNow(true)
                            }
                        }}
                        onClick={() => {
                            setOpen(true)
                        }}
                    />
                </div>
            </Popover>
        </>
    )
}
export const JobFilterOld = ({ mode }: { mode?: 'Candidate' | 'Contributor' }) => {
    const [filters, setFilters] = useHeaderBarFilters()
    const { data: extJobs } = useExternalJobsList()
    const { data: programs } = usePrograms()

    const [jobSearch, setJobSearch] = useState('')
    const createProgram = useCreateProgram()
    return (
        <Select
            placeholder="Project"
            style={{
                width: 150,
            }}
            loading={programs === undefined}
            popupMatchSelectWidth={false}
            showSearch
            onSearch={v => setJobSearch(v)}
            value={filters.programToken}
            allowClear
            onChange={(token, option) => {
                if (token === 'create') {
                    createProgram.mutate(
                        {
                            name: jobSearch,
                            owner: undefined,
                            options: {},
                        },
                        {
                            onSuccess: p => {
                                setFilters({ programToken: p.token })
                            },
                        },
                    )
                } else if (token?.startsWith('ext-')) {
                    createProgram.mutate(
                        {
                            name: (Array.isArray(option) ? option[0] : option).label as string, // we expect string based on the value; as of now 'create' is the only one who has element; TODO type better
                            owner: undefined,
                            options: {},
                        },
                        {
                            onSuccess: p => {
                                setFilters({ programToken: p.token })
                            },
                        },
                    )
                } else {
                    return setFilters({ programToken: token })
                }
            }}
            filterOption={(iv, opt) =>
                opt?.value === 'create' ||
                (opt?.label as string)?.toLowerCase().includes(iv.toLowerCase()) ||
                false
            }
            options={[
                ...(programs?.length || extJobs?.length || jobSearch
                    ? [
                          {
                              label: 'Jobs',
                              value: 'jobs',
                              options: (
                                  programs?.map(p => ({
                                      label: p.name as string | JSX.Element,
                                      value: p.token,
                                  })) ?? []
                              )
                                  .concat(
                                      extJobs?.map(ej => ({
                                          value: 'ext-' + ej.job_id,
                                          label: ej.name,
                                          key: ej.job_id,
                                      })) ?? [],
                                  )
                                  .concat(
                                      jobSearch && !programs?.find(p => p.name === jobSearch)
                                          ? [
                                                {
                                                    value: 'create',
                                                    label: (
                                                        <>
                                                            <Button
                                                                icon={<PlusOutlined />}
                                                                loading={createProgram.isPending}
                                                                onClick={e => {
                                                                    createProgram.mutate(
                                                                        {
                                                                            name: jobSearch,
                                                                            owner: undefined,
                                                                            options: {},
                                                                        },
                                                                        {
                                                                            onSuccess: p => {
                                                                                setFilters({
                                                                                    programToken:
                                                                                        p.token,
                                                                                })
                                                                            },
                                                                        },
                                                                    )
                                                                    e.stopPropagation()
                                                                }}
                                                            >
                                                                {jobSearch}
                                                            </Button>
                                                        </>
                                                    ),
                                                },
                                            ]
                                          : [],
                                  ),
                          },
                      ]
                    : []),
            ]}
        />
    )
}

const Col = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px;
`

export const CreateChannelPopover = ({
    onFinish,
    startAsVersion,
    defaultName,
    includeExternal,
    excludeLinkedPrograms,
    forcedProgramToken,
}: {
    onFinish?: (c: Feed | RoleSearch | undefined) => void
    startAsVersion?: 8 | 16
    defaultName: string
    includeExternal?: boolean
    excludeLinkedPrograms?: boolean
    forcedProgramToken?: string
}) => {
    const filters = useHeaderBarFilters()[0]
    const programs = usePrograms().data
    const curProgram = programs?.find(p => p.token === filters.programToken)
    return (
        <JobPickerPopover
            startAsVersion={startAsVersion}
            defaultName={defaultName}
            includeExternal={includeExternal}
            excludeLinkedPrograms={excludeLinkedPrograms}
            defaultSearch={curProgram?.name}
            onFinishCreate={onFinish}
            forcedProgramToken={forcedProgramToken}
        />
    )
}

export const ExternalJobPicker = ({
    onSelectCard,
}: {
    onSelectCard: (job: JobListJob) => void
}) => {
    const { data: extJobs } = useExternalJobsList()
    const [search, setSearch] = useState('')
    const { data: spentIds } = useKnownExternalIds('asyncJobs')
    const externalJobCreateNew: JobListJob = useMemo(
        () => ({
            name: search,
            job_id: '',
            internal_id: '',
            service: undefined as unknown as ExternalService, // shhh
            external_url: '',
            location: 'Create Unlinked',
            team: '',
            department: '',
            updated_at: '',
            req_number: '',
        }),
        [search],
    )

    const filteredJobs = useMemo(() => {
        return (extJobs ?? [])
            .filter(job => {
                const cleanSearch = search.trim().toLowerCase()
                const nameMatch = job.name.toLowerCase().includes(cleanSearch)

                const locationMatch = (job?.location ?? '').toLowerCase().includes(cleanSearch)
                const teamMatch = (job?.team ?? '').toLowerCase().includes(cleanSearch)
                const departmentMatch = (job?.team ?? '').toLowerCase().includes(cleanSearch)
                const reqMatch = (job?.req_number ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(cleanSearch)
                const idMatch = (job.job_id || job.internal_id)
                    .toString()
                    .toLowerCase()
                    .includes(cleanSearch)
                return (
                    nameMatch ||
                    locationMatch ||
                    teamMatch ||
                    departmentMatch ||
                    reqMatch ||
                    idMatch
                )
            })
            .filter(job => {
                const jobId = String(job.job_id)
                const internalId = String(job.internal_id)
                // TODO: also worry about the cross ones?
                return (
                    !Object.keys(spentIds?.knownExternalJobIds ?? []).includes(jobId) &&
                    !Object.keys(spentIds?.knownExternalInternalIds ?? []).includes(internalId)
                )
            })
    }, [extJobs, search, spentIds])

    const { data: user } = useUserInfo()
    const { data: companyDetails } = useCompanyDetails()

    const userCanCreateJob = useMemo(() => {
        if (user?.isStaff) {
            return true
        }
        if (companyDetails?.settings?.selfSignup) {
            return (user?.settings?.interviewPermissions?.mode ?? 'ado') !== 'ado'
        }
        return true
    }, [user, companyDetails])

    return (
        <Popover
            trigger={['click']}
            content={
                <Col style={{ width: 300 }}>
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search"
                        onChange={e => {
                            setSearch(e.target.value)
                        }}
                        value={search}
                    />
                    <ProgramList>
                        {filteredJobs?.map(i => (
                            <ProgramCard
                                selectCard={true}
                                key={i.job_id || i.internal_id}
                                item={i}
                                onSelect={() => {
                                    onSelectCard(i)
                                }}
                            />
                        ))}
                        {search ? (
                            <ProgramCard
                                selectCard={true}
                                item={externalJobCreateNew}
                                onSelect={() => {
                                    onSelectCard(externalJobCreateNew)
                                }}
                            />
                        ) : null}
                    </ProgramList>
                </Col>
            }
        >
            <Button
                type="primary"
                disabled={!userCanCreateJob}
            >
                <PlusOutlined /> Import Job
            </Button>
        </Popover>
    )
}

export const JobPickerPopover = ({
    startAsVersion,
    defaultName,
    includeExternal,
    excludeLinkedPrograms,
    searchOverride,
    defaultSearch,
    onFinishCreate,
    filterCallback,
    selectTopCardNow,
    forcedProgramToken,
}: {
    startAsVersion?: 8 | 16
    defaultName?: string
    includeExternal?: boolean
    excludeLinkedPrograms?: boolean
    searchOverride?: string
    defaultSearch?: string
    onFinishCreate?: (c: Feed | RoleSearch | undefined) => void
    filterCallback?: (selectedProgram: Program) => void
    selectTopCardNow?: boolean
    forcedProgramToken?: string
}) => {
    const queryClient = useQueryClient()
    const { data: extJobs } = useExternalJobsList()
    const { data: programs } = usePrograms()
    const extJobIdsUsedInProgramsByService = useMemo(() => {
        const ejiuips: Record<
            ExternalService['id'],
            Program['linked_external_integrations'][string][number]['job_id'][] | undefined
        > = {}
        programs?.forEach(p => {
            Object.entries(p.linked_external_integrations).forEach(([k, v]) => {
                ejiuips[k] = [...(ejiuips[k] ?? []), ...v.map(ej => ej.job_id)]
            })
        })
        return ejiuips
    }, [programs])
    const [_jobSearch, setJobSearch] = useState(defaultSearch ?? '')
    const jobSearch = searchOverride ?? _jobSearch
    const itemList: (Program | JobListJob)[] = useMemo(() => {
        const ls = jobSearch.toLowerCase()
        return [
            ...((includeExternal
                ? extJobs?.filter(ej => {
                      return !extJobIdsUsedInProgramsByService[ej.service.id]?.find(
                          // these ids should probably mostly be strings, but some shapes of them are numbers
                          // eslint-disable-next-line eqeqeq
                          ji => ji.internal_id == ej.internal_id || ji.job_id == ej.job_id,
                      )
                  })
                : null) ?? []),
            ...((excludeLinkedPrograms
                ? programs?.filter(
                      p => !Object.entries(p.linked_external_integrations ?? {}).length,
                  )
                : programs) ?? []),
        ].filter(p => {
            if (!ls) {
                return true
            }

            const nameMatch = p.name.toLowerCase().includes(ls)
            const jlj = isJobListJob(p)
                ? p
                : isProgram(p)
                  ? p.linked_external_job_details?.[0]
                  : undefined
            if (jlj) {
                const locationMatch = (jlj?.location ?? '').toLowerCase().includes(ls)
                const teamMatch = (jlj?.team ?? '').toLowerCase().includes(ls)
                const departmentMatch = (jlj?.team ?? '').toLowerCase().includes(ls)
                const reqMatch = (jlj?.req_number ?? '').toString().toLowerCase().includes(ls)
                const idMatch = (jlj.job_id || jlj.internal_id)
                    .toString()
                    .toLowerCase()
                    .includes(ls)
                return (
                    nameMatch ||
                    locationMatch ||
                    teamMatch ||
                    departmentMatch ||
                    reqMatch ||
                    idMatch
                )
            }
            return nameMatch
        })
    }, [
        excludeLinkedPrograms,
        extJobIdsUsedInProgramsByService,
        extJobs,
        includeExternal,
        jobSearch,
        programs,
    ])
    const inputRef = useRef<InputRef>(null)

    useEffect(() => {
        if (inputRef.current?.focus) {
            inputRef.current.focus()
            inputRef.current.select()
        } else {
            setTimeout(() => {
                inputRef.current?.focus?.()
                inputRef.current?.select?.()
            }, 10)
        }
    }, [inputRef.current?.input]) // inputRef.current is not stable across renders; bbut his .input is

    const createFeedMutation = useCreateFeed()
    const importJobMutation = useImportExternalJobMutation()

    useEffect(() => {
        if (selectTopCardNow && filterCallback) {
            const firstValidItem = itemList.find(isProgram)
            if (firstValidItem) {
                filterCallback(firstValidItem)
            }
        }
    }, [selectTopCardNow, itemList, filterCallback])

    return (
        <Col style={{ width: 300 }}>
            {searchOverride === undefined ? (
                <Row>
                    <Input
                        ref={inputRef}
                        placeholder="Name"
                        onChange={e => setJobSearch(e.currentTarget.value.trim())}
                        defaultValue={jobSearch}
                        disabled={createFeedMutation.isPending}
                        onPressEnter={() => {
                            const name = jobSearch || defaultName
                            if (!name) {
                                return
                            }
                            createFeedMutation.mutate(
                                {
                                    name,
                                    startAsV8: startAsVersion === 8,
                                    startAsV16: startAsVersion === 16,
                                    programs: forcedProgramToken ? [forcedProgramToken] : undefined,
                                },
                                {
                                    onSettled: r => {
                                        queryClient.invalidateQueries({
                                            queryKey: ['programs'],
                                        })
                                        return onFinishCreate?.(r)
                                    },
                                },
                            )
                        }}
                    />
                    <Tooltip
                        title={`Create New ${startAsVersion === 8 ? 'Video Posting' : 'Channel'}`}
                    >
                        <Button
                            icon={<PlusOutlined />}
                            disabled={!jobSearch.length}
                            loading={createFeedMutation.isPending}
                            onClick={() => {
                                const name = jobSearch || defaultName
                                if (!name) {
                                    return
                                }
                                createFeedMutation.mutate(
                                    {
                                        name,
                                        startAsV8: startAsVersion === 8,
                                        startAsV16: startAsVersion === 16,
                                    },
                                    {
                                        onSettled: r => {
                                            queryClient.invalidateQueries({
                                                queryKey: ['programs'],
                                            })
                                            return onFinishCreate?.(r)
                                        },
                                    },
                                )
                            }}
                        />
                    </Tooltip>
                </Row>
            ) : null}
            {!forcedProgramToken ? (
                <ProgramList>
                    {itemList.map(i => (
                        <ProgramCard
                            selectCard={!!filterCallback}
                            key={isProgram(i) ? i.token : i.job_id || i.internal_id}
                            item={i}
                            onSelect={() => {
                                if (filterCallback) {
                                    if (isProgram(i)) {
                                        filterCallback(i)
                                    }
                                    return
                                }
                                if (isJobListJob(i)) {
                                    importJobMutation.mutate(
                                        {
                                            externalJob: i,
                                            asFeed: true,
                                        },
                                        {
                                            onSettled: r => {
                                                queryClient.invalidateQueries({
                                                    queryKey: ['programs'],
                                                })
                                                return onFinishCreate?.(r)
                                            },
                                        },
                                    )
                                } else {
                                    createFeedMutation.mutate(
                                        {
                                            name: defaultName || '',
                                            startAsV8: startAsVersion === 8,
                                            startAsV16: startAsVersion === 16,
                                            programs: [i.token],
                                        },
                                        {
                                            onSettled: r => {
                                                queryClient.invalidateQueries({
                                                    queryKey: ['programs'],
                                                })
                                                return onFinishCreate?.(r)
                                            },
                                        },
                                    )
                                }
                            }}
                        />
                    ))}
                    {jobSearch.trim().length || defaultName ? (
                        <ProgramCard
                            newName={jobSearch.trim().length ? jobSearch : defaultName}
                            onSelect={() => {
                                createFeedMutation.mutate(
                                    {
                                        name:
                                            (jobSearch.trim().length ? jobSearch : defaultName) ||
                                            '',
                                        startAsV8: startAsVersion === 8,
                                        startAsV16: startAsVersion === 16,
                                        programs: ['new'],
                                    },
                                    {
                                        onSettled: r => {
                                            queryClient.invalidateQueries({
                                                queryKey: ['programs'],
                                            })
                                            return onFinishCreate?.(r)
                                        },
                                    },
                                )
                            }}
                        />
                    ) : undefined}
                </ProgramList>
            ) : undefined}
        </Col>
    )
}

const getProgramDetails = (item: Program | JobListJob) => {
    const jobListJob = isProgram(item) ? item.linked_external_job_details?.[0] : item
    if (jobListJob) {
        return [
            ...new Set([
                jobListJob.location,
                jobListJob.team ?? jobListJob.department,
                jobListJob.req_number,
                jobListJob.job_id ?? jobListJob.internal_id,
            ]),
        ]
            .filter(x => !!x)
            .join(' • ')
    }
}
const ProgramCardWrap = styled.div<{ $selectCard?: boolean }>`
    background: #0001;
    border-radius: 8px;
    padding: 2px 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & h4 {
        margin: 0;
    }
    & .ant-list-item-meta-description {
        text-wrap: nowrap;
        overflow: clip;
    }
    & ${Col} {
        flex: 1;
        overflow-x: clip;
    }
    & button {
        flex-shrink: 0;
    }
    ${({ $selectCard }) =>
        $selectCard &&
        css`
            cursor: pointer;
        `}
`
const ProgramList = styled(Col)`
    gap: 4px;
    flex: 1;
    max-height: 400px;
    overflow-y: auto;
    margin: 0 -12px -12px;
    & ${ProgramCardWrap}:first-child {
        background: #0002;
    }
`
const IconWrap = styled(Tag)`
    display: flex;
    height: 32px;
    align-items: center;
    margin-right: 2px;
`
const ProgramCard = ({
    item,
    newName,
    onSelect,
    selectCard,
}: {
    item?: Program | JobListJob
    newName?: string
    onSelect: () => void
    selectCard?: boolean
}) => {
    const extJobInfo = isProgram(item)
        ? Object.entries(item?.linked_external_integrations ?? {})
              ?.find(i => !!i?.[1])?.[1]
              ?.find(v => !!v)
        : undefined
    const externalUrl = isJobListJob(item)
        ? item.external_url
        : extJobInfo
          ? extJobInfo.url
          : undefined
    return (
        <ProgramCardWrap
            $selectCard={selectCard}
            onClick={() => {
                if (selectCard) {
                    onSelect()
                }
            }}
        >
            <Col>
                <h4>{item?.name ?? newName}</h4>
                <div>{(item ? getProgramDetails(item) : 'Create in New Project') || ' '}&nbsp;</div>
            </Col>
            {externalUrl ? (
                externalUrl ? (
                    <Tooltip title="View External Posting">
                        <Button
                            icon={<EyeOutlined />}
                            href={externalUrl}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                            target="_blank"
                            rel="noreferrer"
                        />
                    </Tooltip>
                ) : null
            ) : null}

            {selectCard ? undefined : (
                <Tooltip title={item ? 'Add to Project' : 'Create in new Project'}>
                    <Button
                        // style={{ position: 'absolute', right: 8, bottom: '50%', transform: 'translate(0, 50%)' }}
                        // loading={forceLoading ?? onSelectMutation.isLoading}
                        icon={<PlusOutlined />}
                        onClick={() => {
                            onSelect()
                        }}
                    />
                </Tooltip>
            )}
        </ProgramCardWrap>
    )
}

const SelectNoWrap = styled(Select)`
    & .ant-select-selection-overflow {
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 2px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`
export const TagFilter = ({
    mode,
    extraKey,
    allowNew,
    onChange,
}: {
    mode?: 'rep' | 'video' | 'channel'
    extraKey?: string
    allowNew?: boolean
    onChange?: () => void
}) => {
    const [filters, setFilters] = useHeaderBarFilters(extraKey)
    const { data: repTags } = useRoleRepTags('All')
    const { data: videoTags } = useVideoTagFilters({
        sourceToken: '',
        hideArchived: false,
        forBuilder: false,
    })
    const { data: feedTags } = useFeedTags()
    const tagsByMode = useMemo(
        () =>
            ({
                rep: repTags ?? [],
                video: videoTags ?? [],
                channel: feedTags ?? [],
            }) as const,
        [feedTags, repTags, videoTags],
    )
    const allTags = useMemo(
        () => new Set(Object.values(tagsByMode).flatMap(v => [...v])),
        [tagsByMode],
    )
    const enabledTags: Set<string> = mode
        ? new Set([...tagsByMode[mode], ...filters.tags])
        : new Set(allTags)
    const unenabledTags = [...allTags].filter(t => !enabledTags.has(t))
    return (
        <SelectNoWrap
            style={{
                width: 130,
            }}
            placeholder="Tags"
            mode={allowNew ? 'tags' : 'multiple'}
            showSearch
            value={filters.tags}
            onChange={(v, opts) => {
                setFilters({ tags: Array.isArray(v) ? v : [v] })
                onChange?.()
            }}
            options={[
                ...[...enabledTags].map(t => ({ label: t, value: t })),
                ...unenabledTags.map(t => ({ label: t, value: t, disabled: true })),
            ]}
        />
    )
}

type SubMenuItem = Omit<MenuOption, 'icon' | 'label'> & {
    icon?: MenuOption['icon']
    label?: MenuOption['label']
} & { staffOnly?: boolean }
interface HeaderBarProps {
    mode: 'Candidates' | 'Contributors' | null
    label?: ReactNode
    useCrumbs?: boolean
    subMenu?: {
        selectedKeys?: string[]
        subMenuItems?: SubMenuItem[]
    }
}

interface RouteHandleInfo {
    headerBar?: HeaderBarProps
    crumb?: (a: any) => string
}
export const HeaderBar = ({ mode, subMenu: _subMenu, label }: HeaderBarProps) => {
    const location = useLocation()
    const matches = useMatches() as UIMatch<unknown, RouteHandleInfo>[]
    const matchData = matches.reduce(
        (acc, val) => ({ ...acc, ...val?.handle?.headerBar }),
        {} as Partial<HeaderBarProps>,
    )
    const subMenu = _subMenu ?? matchData.subMenu
    const subMenuMatch = matches.findLast(m => m.handle?.headerBar?.subMenu)
    const isStaff = useUserIsStaff()
    const getTo = useCallback(
        (key: string) =>
            resolvePath({ pathname: key, search: location.search }, subMenuMatch?.pathname),
        [location.search, subMenuMatch?.pathname],
    )
    const selectedKey =
        subMenu?.subMenuItems?.find(mo => !!matchPath(getTo(mo.key).pathname, location.pathname))
            ?.key ??
        subMenu?.subMenuItems?.reduce(
            (prev: { maxIndex: number; mo: SubMenuItem } | undefined, mo) => {
                const moToPath = getTo(mo.key).pathname
                const maxIndex = matches.findLastIndex(m => !!matchPath(moToPath, m.pathname))
                if (maxIndex >= 0 && (!prev || prev.maxIndex < maxIndex)) {
                    return { maxIndex, mo }
                }
                return prev
            },
            undefined,
        )?.mo?.key ??
        ''

    const mainColor =
        mode === null
            ? '#fff'
            : mode === 'Candidates'
              ? CANDIDATES_ZONE_COLOR
              : CONTRIBUTOR_ZONE_COLOR
    const menuTextColor = useMemo(
        () => (mode === null ? '#000' : mode === 'Candidates' ? '#000' : '#fff'),
        [mode],
    )
    const menuOptions = useMenuOptions()
    const relevantMenuItem = useMemo(
        () => (mode === 'Candidates' ? menuOptions.candidateMenu : menuOptions.contentMenu),
        [mode, menuOptions],
    )
    const crumbyMatches = matchData.useCrumbs ? matches.filter(m => !!m.handle?.crumb) : undefined
    const crumbLabel = crumbyMatches?.map((m, idx) => {
        const s = m.handle.crumb?.(m.data)
        const ck = `${s}-${idx}`
        if (idx === crumbyMatches.length - 1) {
            return <span key={ck}>{s}</span>
        } else {
            return (
                <React.Fragment key={ck}>
                    <Link
                        style={{ color: 'unset' }}
                        to={{ pathname: m.pathname, search: location.search }}
                    >
                        {s}
                    </Link>
                    <span>/</span>
                </React.Fragment>
            )
        }
    })
    console.groupEnd()

    const activeLabel = crumbLabel ?? label ?? matchData?.label

    if (!relevantMenuItem) {
        return <Spin />
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: getColorByBgColor(mainColor),
                    lineWidth: 2,
                },
                components: {},
            }}
        >
            <HeaderBarWrap
                $color={mainColor}
                $height={subMenu?.subMenuItems?.length ? 88 : 44}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                            alignItems: 'center',
                            color: menuTextColor,
                            fontSize: '20px',
                            fontWeight: 500,
                            paddingTop: '8px',
                        }}
                    >
                        {activeLabel ? (
                            activeLabel
                        ) : (
                            <>
                                {relevantMenuItem.icon} {relevantMenuItem.label}
                            </>
                        )}
                    </div>
                    {mode !== null ? (
                        <HeaderBarTag>
                            {mode === 'Candidates' ? 'Candidates' : 'Company Content'}
                        </HeaderBarTag>
                    ) : null}
                </div>
                {subMenu?.subMenuItems ? (
                    <StyledMenu
                        $color={menuTextColor}
                        mode="horizontal"
                        selectedKeys={[selectedKey]}
                        items={subMenu.subMenuItems
                            .filter(mo => isStaff || !mo.staffOnly)
                            .map((item, idx) => ({
                                icon: <></>,
                                label: item.key[0].toLocaleUpperCase() + item.key.slice(1),
                                // key: `${item.key}-${item.label}-${idx}`,
                                ...item,
                            }))
                            .map(item => ({
                                ...item,
                                label: item.disabled ? (
                                    item.label
                                ) : (
                                    <Link
                                        to={getTo(item.key)}
                                        style={{
                                            backgroundColor: item.staffOnly
                                                ? 'aliceblue'
                                                : undefined,
                                        }}
                                    >
                                        {item.label}
                                    </Link>
                                ),
                            }))
                            .map(mo => {
                                const { staffOnly, ...rest } = mo
                                return rest
                            })}
                    />
                ) : null}
            </HeaderBarWrap>
        </ConfigProvider>
    )
}

export const CANDIDATES_ZONE_COLOR = '#FFCB77'
export const CONTRIBUTOR_ZONE_COLOR = '#227c9d'

const StyledMenu = styled(Menu)<{ $color: string }>`
    background: unset;
    width: 100%;
    border-bottom: unset;
    position: absolute;
    bottom: -6px;
    font-size: 16px;

    & .ant-menu-item {
        ${({ $color }) => css`
            color: ${$color}7 !important;
        `}
        &::after {
            ${({ $color }) => css`
                border-bottom-color: ${$color}0 !important;
            `}
        }
    }

    & .ant-menu-item-active {
        &::after {
            ${({ $color }) => css`
                border-bottom-color: ${$color}0 !important;
            `}
        }
    }

    & .ant-menu-item-selected {
        ${({ $color }) => css`
            color: ${$color} !important;
        `}
        &::after {
            ${({ $color }) => css`
                border-bottom-color: ${$color} !important;
            `}
        }
    }
`

export const HeaderBarWrap = styled.div<{ $color: string; $height: 44 | 88 }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding-top: 16px;
    margin-bottom: 8px;

    ${({ $color, $height }) => css`
        background: ${$color};
        outline: 8px ${$color} solid;
        color: ${getColorByBgColor($color)};
        height: ${$height}px;
    `};
`
export const HeaderBarTag = styled.div`
    border-radius: 3px;
    padding: 4px 6px;
    font-size: 13px;
    font-weight: 500;
    background: #00000014;
    color: white;
`
export const Row = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
`
