import React, { lazy, Suspense } from 'react'
import { ChannelAnalyticsProps } from './ChannelAnalytics'

const LazyChannelAnalytics = lazy(() => import('./ChannelAnalytics'))

const ChannelAnalytics = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelAnalyticsProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelAnalytics {...props} />
    </Suspense>
)

export default ChannelAnalytics
