import styled, { css } from 'styled-components'

export const MenuIconWrapper = styled.div<{ scale: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & svg {
        ${({ scale }) => css`
            transform: scale(${scale});
        `}
    }
`
