import React, { lazy, Suspense } from 'react'
import {
    ChooseExistingContributorModalProps,
    ChoosePromptQuestionModalProps,
    CommentDisplayProps,
    ContentLibraryProps,
    NewVideoFormProps,
    VideoCommentsModalProps,
    VideoDetailsProps,
    VideoDetailsShareProps,
    VideoDetailWrapperProps,
    VideoFiltersProps,
    VideoRatingProps,
} from './ContentLibrary'
const LazyContentLibrary = lazy(() => import('./ContentLibrary'))

const ContentLibrary = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ContentLibraryProps,
) => (
    <Suspense fallback={null}>
        <LazyContentLibrary {...props} />
    </Suspense>
)

export default ContentLibrary

const LazyVideoDetailsWrapper = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoDetailWrapper })),
)
export const VideoDetailWrapper = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoDetailWrapperProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoDetailsWrapper {...props} />
    </Suspense>
)

const LazyVideoDetails = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoDetails })),
)
export const VideoDetails = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoDetailsProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoDetails {...props} />
    </Suspense>
)

const LazyVideoRating = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoRating })),
)
export const VideoRating = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoRatingProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoRating {...props} />
    </Suspense>
)

const LazyVideoCommentsModal = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoCommentsModal })),
)
export const VideoCommentsModal = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoCommentsModalProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoCommentsModal {...props} />
    </Suspense>
)

const LazyCommentDisplay = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.CommentDisplay })),
)
export const CommentDisplay = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CommentDisplayProps,
) => (
    <Suspense fallback={null}>
        <LazyCommentDisplay {...props} />
    </Suspense>
)
const LazyVideoFilters = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoFilters })),
)
export const VideoFilters = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoFiltersProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoFilters {...props} />
    </Suspense>
)

const LazyNewVideoForm = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.NewVideoForm })),
)
export const NewVideoForm = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & NewVideoFormProps,
) => (
    <Suspense fallback={null}>
        <LazyNewVideoForm {...props} />
    </Suspense>
)

const LazyChooseExistingContributorModal = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.ChooseExistingContributorsModal })),
)
export const ChooseExistingContributorsModal = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ChooseExistingContributorModalProps,
) => (
    <Suspense fallback={null}>
        <LazyChooseExistingContributorModal {...props} />
    </Suspense>
)

const LazyChoosePromptQuestionModal = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.ChoosePromptQuestionModal })),
)
export const ChoosePromptQuestionModal = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ChoosePromptQuestionModalProps,
) => (
    <Suspense fallback={null}>
        <LazyChoosePromptQuestionModal {...props} />
    </Suspense>
)

const LazyVideoDetailsShare = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoDetailsShare })),
)
export const VideoDetailsShare = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoDetailsShareProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoDetailsShare {...props} />
    </Suspense>
)

const LazyVideoFiltersCompact = lazy(() =>
    import('./ContentLibrary').then(m => ({ default: m.VideoFiltersCompact })),
)
export const VideoFiltersCompact = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & VideoFiltersProps,
) => (
    <Suspense fallback={null}>
        <LazyVideoFiltersCompact {...props} />
    </Suspense>
)
