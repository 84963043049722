import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { IntakeFormChoices } from './job_board_candidate_service'

export const getSpoofCompanyIdFromURL = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('c') || ''
}
export enum UserStatus {
    Loading = 'Loading',
    NotLoggedIn = 'NotLoggedIn',
    LoggedInClient = 'LoggedInClient',
    UnverifiedUser = 'UnverifiedUser',
    LoggedInClientPendingTOS = 'LoggedInClientPendingTOS',
    LoggedInStaff = 'LoggedInStaff',
    RequireOtp = 'RequireOtp',
}

const reverseMapStringToEnum = (stringToMatch: string) =>
    Object.entries(UserStatus).find(([key, value]) => value === stringToMatch)?.[1]

export const useCSRFToken = () => {
    //make sure axios knows where to look for it.
    if (window.name === 'wtext') {
        axios.defaults.xsrfCookieName = 'extcsrf'
        axios.defaults.xsrfHeaderName = 'X-ExtCSRF'
    } else {
        axios.defaults.xsrfCookieName = 'csrftoken'
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    }
    return (
        useQuery({
            queryKey: ['CSRFTOKEN'],

            queryFn: async () => {
                // a GET on anything that's a django template should automatically fill in the cookie (once the cookie exists axios knows what to do)
                return await axios({
                    method: 'get',
                    url: '/auth/password_reset/',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-extCSRFReq': '1',
                    },
                })
            },
        }).status === 'success'
    )
}

export const readCookie = (name?: string) => {
    if (!name) return null
    const match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'))
    return match ? decodeURIComponent(match[3]) : null
}

export const submitLogin = async (email: string, password: string, otp: string | undefined) => {
    try {
        const userStatus = await axios.post<UserStatus>(
            '/auth/login',
            {
                email,
                password,
                otp,
            },
            {
                validateStatus: status => status < 500,
            },
        )
        const match = reverseMapStringToEnum(userStatus.data)
        return {
            response: userStatus,
            status: match || UserStatus.NotLoggedIn,
            requireOtp: match === UserStatus.RequireOtp,
        }
    } catch (error) {
        return { status: UserStatus.NotLoggedIn }
    }
}

export const submitResetPasswordRequest = async (
    email: string,
    create?: boolean,
    orgName?: string,
    captcha?: string,
    convertToVerified?: boolean,
    showcaseSourceToken?: string,
    additionalUserInfo?: {
        intakeQuestions?: Record<keyof Omit<IntakeFormChoices, 'promptFormOtherInfo'>, string[]>
    },
) => {
    const formData = new FormData()
    formData.set('email', email)
    if (captcha) {
        formData.set('captcha', captcha ?? '')
    }
    if (orgName) {
        formData.set('org_name', orgName ?? '')
    }
    if (create) {
        formData.set('create', '1')
    }
    if (convertToVerified) {
        formData.set('convertToVerified', '1')
    }
    if (showcaseSourceToken) {
        formData.set('showcaseSourceToken', showcaseSourceToken)
    }
    if (additionalUserInfo) {
        formData.set('additionalUserInfo', JSON.stringify(additionalUserInfo))
    }
    const response = await axios({
        method: 'post',
        url: '/auth/password_reset/',
        data: formData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    if (response.status >= 400) {
        throw response.status
    }
    return response.status < 400
}

export const submitResetPasswordConfirm = async (newPassword: string, uid: string) => {
    const formData = new FormData()
    formData.set('new_password1', newPassword)
    formData.set('new_password2', newPassword)
    const response = await axios({
        method: 'post',
        url: `/auth/reset/${uid}/set-password/`,
        data: formData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    if (response.status >= 400) {
        throw response.status
    }
    return response.status < 400
}

const getUserStatus = async () => {
    const res = await axios.get<{ status: string; extUser: boolean }>('/auth/get_user_status', {
        validateStatus: status => status < 500,
    })
    return res.data
}

export const useUserStatusQuery = () => {
    return useQuery({
        queryKey: ['userStatus'],
        queryFn: getUserStatus,
    })
}
export const useUserStatus = () => {
    const [searchParams] = useSearchParams()
    const userStatusQuery = useUserStatusQuery()
    const { status } = userStatusQuery.data ?? { status: '' }

    try {
        if (status === '' || status === 'loading') {
            return UserStatus.Loading
        }
        const spoofStaffParam = searchParams.get('s') === null || !!Number(searchParams.get('s'))
        const spoofClient = !spoofStaffParam
        const userStatus = reverseMapStringToEnum(status)
        if (userStatus === undefined) {
            return UserStatus.NotLoggedIn
        } else if (userStatus === UserStatus.LoggedInStaff) {
            return spoofClient ? UserStatus.LoggedInClient : UserStatus.LoggedInStaff
        } else {
            return userStatus
        }
    } catch (error) {
        return UserStatus.NotLoggedIn
    }
}

export const logout = async () => {
    await axios.get('/auth/logout')
}

export const useUserIsStaff = () => {
    const userStatus = useUserStatus()
    return userStatus === UserStatus.LoggedInStaff
}

export const useOtpConfigQuery = () => {
    return useQuery({
        queryKey: ['otpConfig'],

        queryFn: async () => {
            return (await axios.get<{ otp_configs: OtpConfig[] }>('/auth/otp_config')).data
                .otp_configs
        },

        staleTime: Infinity,
    })
}

interface ExistingOtpConfig {
    name: string
    created: string
    token: string
}
interface NewOtpConfig {
    name: string
    secret?: string
    secret_uri?: string
}
export type OtpConfig = ExistingOtpConfig & NewOtpConfig
