import React from 'react'
import { NonIndexRouteObject, RouteObject, redirect } from 'react-router-dom'
import { CompanyDetails } from 'services/company_service'

type SettingsTabOptionNames =
    | 'users'
    | 'integrations'
    | 'branding'
    | 'emails'
    | 'candidateDeliverySettings'
    | 'candidateOutreach'
    | 'widget'
    | 'billing'
    | 'feed'
    | 'myAccount'
    | 'company'
    | 'brand'
    | 'notifications'
interface SettingsTabOption1 {
    path: SettingsTabOptionNames
    displayName: string
    component?: RouteObject['Component']
    element?: JSX.Element
    staffOnly?: undefined
    lazy?: NonIndexRouteObject['lazy']
    betaTab?: false
}

export interface SettingsTabOptionStaffOnly {
    path: SettingsTabOptionNames
    displayName: string
    staffOnly: (companyDetails: CompanyDetails) => boolean
    lazy: NonIndexRouteObject['lazy']
    betaTab?: boolean
}

export type SettingsTabOption = SettingsTabOption1 | SettingsTabOptionStaffOnly

export const SettingsTabOptions: SettingsTabOption[] = [
    {
        path: 'users',
        displayName: 'Users',
        lazy: () => import('./pages/Users/Users').then(m => ({ Component: m.default })),
    },
    {
        path: 'branding',
        lazy: () => import('./pages/Branding/Branding').then(m => ({ Component: m.default })),
        displayName: 'Brand',
    },
    {
        path: 'widget',
        displayName: 'Widget Settings',
        lazy: () =>
            import('./pages/WidgetSettings/WidgetSettings').then(m => ({ Component: m.default })),
    },

    {
        path: 'emails',
        lazy: () =>
            import('./pages/CompanyEmailTemplates/CompanyEmailTemplates').then(m => ({
                element: React.createElement(m.default),
            })),
        displayName: 'Emails',
    },
    // {
    //     path: 'candidateDeliverySettings',
    //     // element: <CandidateDeliverySettings />,
    //     lazy: () =>
    //         import('./pages/CandidateDeliverySettings/CandidateDeliverySettings').then(m => ({
    //             Component: m.default,
    //         })),
    //     displayName: 'Delivery',
    // },
    {
        path: 'integrations',
        // element: <ExternalIntegrationsList />,
        lazy: () =>
            import(
                'pages/RolesearchList/components/ExternalIntegrationsList/ExternalIntegrationsList'
            ).then(m => ({ Component: m.default })),
        displayName: 'Integrations',
    },
    {
        displayName: 'My Account',
        path: 'myAccount',
        staffOnly: () => true,
        lazy: () =>
            import('./pages/MyAccount/MyAccount').then(m => ({
                element: React.createElement(m.default),
            })),
    },
    {
        displayName: 'Company',
        path: 'company',
        staffOnly: () => true,
        lazy: () =>
            import('./pages/Company/Company').then(m => ({
                element: React.createElement(m.default),
            })),
    },
    {
        displayName: 'Brand (v2)',
        path: 'brand',
        staffOnly: () => true,
        lazy: () =>
            import('./pages/Brand/Brand').then(m => ({
                element: React.createElement(m.default),
            })),
    },
    {
        displayName: 'Notifications',
        path: 'notifications',
        staffOnly: () => true,
        lazy: () =>
            import('./pages/Notifications/Notifications').then(m => ({
                element: React.createElement(m.default),
            })),
    },
    {
        path: 'candidateOutreach',
        displayName: 'Search Demo',
        lazy: () =>
            import('pages/CandidateOutreach/CandidateOutreach').then(m => ({
                element: React.createElement(m.default),
            })),
        staffOnly: () => true,
        // element: <CandidateOutreach />,
    },
    {
        path: 'billing',
        displayName: 'Billing',
        staffOnly: (companyDetails: CompanyDetails) =>
            !companyDetails?.billing_info?.stripe_customer_id,
        lazy: () =>
            import('./pages/Billing/Billing').then(m => ({
                element: React.createElement(m.default),
            })),
        // betaTab: true,
    },
]

export const SettingsRoutes = [
    ...SettingsTabOptions.map(mo => {
        const lazy = mo.lazy
        return !!mo.staffOnly || !!mo.betaTab
            ? {
                  path: mo.path,
                  lazy: lazy
                      ? async () => {
                            const [ro, settings] = await Promise.all([lazy(), import('./Settings')])
                            // const ro = await lazy();
                            // const settings = await import('./Settings')
                            return {
                                ...ro,
                                element: (
                                    <settings.SettingsPermissionWrapper menuOption={mo}>
                                        {<>{ro.element ? ro.element : ro.Component}</>}
                                    </settings.SettingsPermissionWrapper>
                                ),
                            }
                            // mo.lazy(). <SettingsPermissionWrapper>{ro.element}</SettingsPermissionWrapper>
                        }
                      : undefined,
              }
            : {
                  path: mo.path,
                  element: mo.element,
                  Component: mo.component,
                  lazy,
              }
    }),
    {
        index: true,
        loader: () => {
            return redirect('users' + window.location.search)
        },
    },
]
