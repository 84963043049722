import {
    ArrowLeftOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    ExportOutlined,
    PlusOutlined,
    SaveOutlined,
    SyncOutlined,
    WarningOutlined,
} from '@ant-design/icons'

import {
    useQueryClient,
    useQuery,
    useMutation,
    useSuspenseQuery,
    useIsMutating,
} from '@tanstack/react-query'
import type { SearchForSuggestionsResult } from '@aws-sdk/client-location'
import {
    AutoComplete,
    Button,
    Drawer,
    Input,
    InputNumber,
    InputRef,
    Popover,
    Radio,
    Select,
    Spin,
    Switch,
    Tabs,
    Tooltip,
    Typography,
    Form,
} from 'antd'
import { BrowseCandidates } from 'pages/JobBoard/JobBoard.lazy'
import axios from 'axios'
import { useDebounce } from 'hooks/useDebounce'
import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useMatches, UIMatch } from 'react-router-dom'
import {
    JobPostingDetail as JobPostingDetailType,
    Place,
    jobPostingQueryArg,
    useCreateQualificationQuestion,
    useJobPosting,
    usePromptFormTemplateForJobBoard,
    useUpdateJobPosting,
    useUpdatePromptFormTemplateForJobBoard,
    useUpdateQualificationQuestion,
} from 'services/job_board_job_posting_service'
import {
    RoleRepPromptQuestion,
    getGroupedQuestions,
    useCreateRoleRepPromptFormTemplate,
} from 'services/role_rep_prompt_service'
import { capitalizeWords, pollableEndpoint } from 'services/utils/CommonUtils'
import { WrappedJoditEditor } from 'components/JoditEditor/WrappedJoditEditor.lazy'
import { useUserIsStaff } from 'services/login_service'
import { useIntakeFormChoices } from 'services/job_board_candidate_service'

const QualSubQuestion = ({
    idx,
    question,
    jobPosting,
    editable,
}: {
    idx: number
    question: RoleRepPromptQuestion
    jobPosting: JobPostingDetailType
    editable: boolean
}) => {
    const updateJobPostingMutation = useUpdateJobPosting('candidatePool')
    const updateQuestionMutation = useUpdateQualificationQuestion(jobPosting.token)
    const updateQualificationFormMutation = useUpdatePromptFormTemplateForJobBoard()
    const { data: qualificationForm } = usePromptFormTemplateForJobBoard(
        jobPosting.qualification_form || '',
    )
    const [newChoice, setNewChoice] = useState<string>()
    if (!qualificationForm) {
        return <Spin />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}
            >
                {idx + 1})
                <Input
                    style={{ width: '300px' }}
                    readOnly={!editable}
                    defaultValue={question.prompt}
                    onBlur={e => {
                        if (!editable) {
                            return
                        }
                        updateQuestionMutation.mutate({
                            token: question.token,
                            patch: { prompt: e.target.value.trim() },
                        })
                    }}
                    suffix={updateQuestionMutation.isPending ? <Spin /> : null}
                />
                {editable && question.qtype !== 'text' ? (
                    <Tooltip title="Allow multiple selections">
                        <Switch
                            loading={updateQuestionMutation.isPending}
                            checked={question.info.allowMultipleSelections}
                            onChange={checked => {
                                if (!editable) {
                                    return
                                }
                                updateQuestionMutation.mutate({
                                    token: question.token,
                                    patch: {
                                        info: {
                                            ...question.info,
                                            allowMultipleSelections: checked,
                                        },
                                    },
                                })
                            }}
                        />
                    </Tooltip>
                ) : null}
                {editable ? (
                    <Button
                        icon={<DeleteOutlined />}
                        loading={updateQualificationFormMutation.isPending}
                        onClick={() => {
                            const newQuestions = qualificationForm?.prompt_questions.filter(
                                q => q.token !== question.token,
                            )
                            updateQualificationFormMutation.mutate({
                                token: qualificationForm.token,
                                promptQuestions: newQuestions,
                            })
                        }}
                    />
                ) : null}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    paddingLeft: '5px',
                }}
            >
                {question.info.choices.map((c, cidx) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '5px',
                            alignItems: 'center',
                        }}
                        key={cidx}
                    >
                        <Input
                            style={{ width: '300px' }}
                            readOnly={!editable}
                            defaultValue={c}
                            onBlur={e => {
                                if (!editable) {
                                    return
                                }
                                const newChoices = [...(question.info?.choices ?? [])]
                                newChoices[cidx] = e.target.value.trim()
                                updateQuestionMutation.mutate({
                                    token: question.token,
                                    patch: { info: { ...question.info, choices: newChoices } },
                                })
                            }}
                            suffix={updateQuestionMutation.isPending ? <Spin /> : null}
                        />
                        <Tooltip title="This is an acceptable answer">
                            <Switch
                                loading={updateJobPostingMutation.isPending}
                                checked={jobPosting?.settings?.qualifyingAnswers?.[
                                    question.token
                                ]?.includes(cidx)}
                                onChange={checked => {
                                    let newGoodAnswersForThisQuestion = [
                                        ...(jobPosting.settings?.qualifyingAnswers?.[
                                            question.token
                                        ] ?? []),
                                    ]
                                    if (checked) {
                                        newGoodAnswersForThisQuestion.push(cidx)
                                    } else {
                                        newGoodAnswersForThisQuestion =
                                            newGoodAnswersForThisQuestion.filter(
                                                num => num !== cidx,
                                            )
                                    }

                                    updateJobPostingMutation.mutate({
                                        token: jobPosting.token,
                                        patch: {
                                            settings: {
                                                ...jobPosting.settings,
                                                qualifyingAnswers: {
                                                    ...(jobPosting.settings?.qualifyingAnswers ??
                                                        {}),
                                                    [question.token]: newGoodAnswersForThisQuestion,
                                                },
                                            },
                                        },
                                    })
                                }}
                            />
                        </Tooltip>
                        {editable ? (
                            <Button
                                icon={<DeleteOutlined />}
                                loading={
                                    updateQuestionMutation.isPending ||
                                    updateJobPostingMutation.isPending
                                }
                                onClick={() => {
                                    if (
                                        jobPosting.settings?.qualifyingAnswers?.[
                                            question.token
                                        ].includes(cidx)
                                    ) {
                                        const newGoodAnswersForThisQuestion =
                                            (jobPosting.settings?.qualifyingAnswers?.[
                                                question.token
                                            ]).filter(num => num !== cidx)
                                        updateJobPostingMutation.mutate(
                                            {
                                                token: jobPosting.token,
                                                patch: {
                                                    settings: {
                                                        ...jobPosting.settings,
                                                        qualifyingAnswers: {
                                                            ...(jobPosting.settings
                                                                ?.qualifyingAnswers ?? {}),
                                                            [question.token]:
                                                                newGoodAnswersForThisQuestion,
                                                        },
                                                    },
                                                },
                                            },
                                            {
                                                onSuccess: () => {
                                                    const newChoices = question.info.choices.filter(
                                                        (c, idx_) => idx_ !== cidx,
                                                    )
                                                    updateQuestionMutation.mutate({
                                                        token: question.token,
                                                        patch: {
                                                            info: {
                                                                ...question.info,
                                                                choices: newChoices,
                                                            },
                                                        },
                                                    })
                                                },
                                            },
                                        )
                                    } else {
                                        const newChoices = question.info.choices.filter(
                                            (c, idx_) => idx_ !== cidx,
                                        )
                                        updateQuestionMutation.mutate({
                                            token: question.token,
                                            patch: {
                                                info: { ...question.info, choices: newChoices },
                                            },
                                        })
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                ))}
                {editable && !newChoice && question.qtype === 'multiple_choice' ? (
                    <div>
                        <Button
                            onClick={() => {
                                if (editable) {
                                    setNewChoice('')
                                }
                            }}
                        >
                            <PlusOutlined /> Choice
                        </Button>
                    </div>
                ) : null}
                {newChoice !== undefined ? (
                    <Input
                        autoFocus
                        style={{ width: '300px' }}
                        readOnly={!editable}
                        value={newChoice}
                        onChange={e => {
                            setNewChoice(e.target.value)
                        }}
                        placeholder="New Option"
                        onPressEnter={e => {
                            const newChoices = [...(question.info?.choices ?? [])]
                            newChoices.push(newChoice)
                            updateQuestionMutation.mutate(
                                {
                                    token: question.token,
                                    patch: { info: { ...question.info, choices: newChoices } },
                                },
                                {
                                    onSettled: () => {
                                        setNewChoice(undefined)
                                    },
                                },
                            )
                        }}
                        onBlur={e => {
                            if (!editable) {
                                return
                            }
                            const newChoices = [...(question.info?.choices ?? [])]
                            newChoices.push(newChoice)
                            updateQuestionMutation.mutate(
                                {
                                    token: question.token,
                                    patch: { info: { ...question.info, choices: newChoices } },
                                },
                                {
                                    onSettled: () => {
                                        setNewChoice(undefined)
                                    },
                                },
                            )
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}

const QualQuestionDrawer = ({
    open,
    onClose,
    jobPosting,
}: {
    open: boolean
    onClose: () => void
    jobPosting: JobPostingDetailType
}) => {
    const { data: qualificationForm } = usePromptFormTemplateForJobBoard(
        jobPosting.qualification_form || '',
    )
    const customQuestions = useMemo(
        () => getGroupedQuestions(qualificationForm?.prompt_questions ?? []),

        [qualificationForm],
    )

    const { data: archetypeForm } = usePromptFormTemplateForJobBoard(jobPosting.archetype)
    const archetypeQualQuestions = useMemo(
        () =>
            getGroupedQuestions(archetypeForm?.prompt_questions ?? []).filter(
                q => q.qtype !== 'video',
            ),
        [archetypeForm],
    )

    const createQualificationQuestionMutation = useCreateQualificationQuestion(jobPosting.token)
    const [addQuestionPopoverOpen, setAddQuestionPopoverOpen] = useState(false)
    if (!customQuestions.length || !qualificationForm) {
        return null
    }

    return (
        <Drawer
            closeIcon={false}
            open={open}
            onClose={onClose}
            rootStyle={{
                position: 'absolute',
                marginTop: '46px',
                outline: 'unset',
            }}
            styles={{
                body: {
                    padding: '18px 12px',
                    containerType: 'size',
                    contain: 'strict',
                },
            }}
            width="max(769px, 75vw)"
        >
            <Typography.Title level={3}>Qualification Questions</Typography.Title>
            <Typography.Text>
                You can add custom qualification questions to a candidate's interview. You can also
                mark what answers you're looking for in a candidate - for questions where you have
                done so, a candidate must have answered at least one of the marked answers
            </Typography.Text>
            {archetypeQualQuestions.length ? (
                <>
                    <Typography.Title level={4}>Questions from Job Type Interview</Typography.Title>
                    {archetypeQualQuestions.map(parent =>
                        parent?.subQuestions?.length ? (
                            <div key={parent.token}>
                                {parent.prompt}
                                {parent.subQuestions.map((q, idx) => (
                                    <QualSubQuestion
                                        idx={idx}
                                        question={q}
                                        jobPosting={jobPosting}
                                        editable={false}
                                        key={q.token}
                                    />
                                ))}
                            </div>
                        ) : null,
                    )}
                </>
            ) : null}
            <Typography.Title level={4}>Custom Question</Typography.Title>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                {customQuestions[0]?.subQuestions?.map((q, idx) => (
                    <QualSubQuestion
                        idx={idx}
                        question={q}
                        jobPosting={jobPosting}
                        editable={true}
                        key={q.token}
                    />
                ))}
            </div>
            <div style={{ marginTop: '24px' }}>
                <Popover
                    open={addQuestionPopoverOpen}
                    onOpenChange={open => setAddQuestionPopoverOpen(open)}
                    trigger={['click']}
                    content={
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                            <Button
                                onClick={() => {
                                    createQualificationQuestionMutation.mutate(
                                        {
                                            prompt: '',
                                            qtype: 'multiple_choice',
                                            info: {
                                                choices: [],
                                            },
                                        },
                                        {
                                            onSuccess: () => {
                                                setAddQuestionPopoverOpen(false)
                                            },
                                        },
                                    )
                                }}
                            >
                                Multiple Choice
                            </Button>
                            <Button
                                onClick={() => {
                                    createQualificationQuestionMutation.mutate(
                                        {
                                            prompt: '',
                                            qtype: 'text',
                                            info: {
                                                choices: [],
                                            },
                                        },
                                        {
                                            onSuccess: () => {
                                                setAddQuestionPopoverOpen(false)
                                            },
                                        },
                                    )
                                }}
                            >
                                Open Response
                            </Button>
                        </div>
                    }
                >
                    <Button>
                        <PlusOutlined />
                        Question
                    </Button>
                </Popover>
            </div>
        </Drawer>
    )
}

// export const _JobPostingDetail = ({ jobPosting }: { jobPosting: JobPostingDetailType }) => {
const useJobPostingImport = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: ['jobPostingImport'],
        mutationFn: ({
            jobPostingToken,
            sourceUrl,
        }: {
            jobPostingToken: string
            sourceUrl: string
        }) =>
            pollableEndpoint({
                url: `/jb_api/job_posting_import/${jobPostingToken}`,
                method: 'PUT',
                data: {
                    sourceUrl,
                },
            }),
        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['jobPostings'],
            }),
    })
}
const PostingUrl = ({ jobPostingToken }: { jobPostingToken: string | undefined }) => {
    const jobPostingImport = useJobPostingImport()
    const jobPostingUrlInputRef = useRef<InputRef>(null)
    const [form] = Form.useForm()
    const curSourceUrl = useSuspenseQuery({
        ...jobPostingQueryArg('candidatePool', jobPostingToken),
        select: p => p.info.sourceUrl,
    }).data

    const newSourceUrl = Form.useWatch('url', form)
    const inSync = curSourceUrl === newSourceUrl
    const submitChange = () => {
        const sourceUrl = jobPostingUrlInputRef.current?.input?.value
        if (!jobPostingToken || !sourceUrl) {
            return
        }
        return jobPostingImport.mutate({
            jobPostingToken,
            sourceUrl,
        })
    }
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    outline: '4px solid aliceblue',
                }}
            >
                Current Posting Url
                <Form form={form}>
                    <Form.Item
                        rules={[{ type: 'url' }]}
                        name="url"
                        initialValue={curSourceUrl}
                    >
                        <Input
                            placeholder="Posting Url"
                            ref={jobPostingUrlInputRef}
                            disabled={jobPostingImport.isPending}
                            onPressEnter={submitChange}
                            addonAfter={
                                <Tooltip
                                    title={`${
                                        inSync ? 'Sync from post' : 'Change posting Url'
                                    } (This may overwrite your changes with updates from the posting url)`}
                                >
                                    <Button
                                        type="text"
                                        size="small"
                                        disabled={jobPostingImport.isPending}
                                        style={{ height: 30 }}
                                        onClick={submitChange}
                                        icon={
                                            inSync || jobPostingImport.isPending ? (
                                                <SyncOutlined spin={jobPostingImport.isPending} />
                                            ) : (
                                                <SaveOutlined />
                                            )
                                        }
                                    />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}
export const _JobPostingDetail = () => {
    const { postingToken: jobPostingToken } = useParams()
    const jobPosting = useSuspenseQuery(jobPostingQueryArg('candidatePool', jobPostingToken)).data
    const updateJobPostingMutation = useUpdateJobPosting('candidatePool')
    const createPromptFormMutation = useCreateRoleRepPromptFormTemplate()
    const queryClient = useQueryClient()
    const [qualQuestionFormOpen, setQualQuestionFormOpen] = useState(false)
    const isStaff = useUserIsStaff()
    const { data: intakeQuestions } = useIntakeFormChoices()
    const isImporting = useIsMutating({ mutationKey: ['jobPostingImport'] }) > 0

    return (
        <div style={{ maxWidth: 800, margin: '0 auto 0 auto' }}>
            <QualQuestionDrawer
                open={qualQuestionFormOpen}
                onClose={() => {
                    setQualQuestionFormOpen(false)
                }}
                jobPosting={jobPosting}
            />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {isStaff ? <PostingUrl jobPostingToken={jobPostingToken} /> : null}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    Job Title
                    <Input
                        placeholder="Job Title"
                        defaultValue={jobPosting.name}
                        disabled={isImporting}
                        onBlur={e => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: { name: e.target.value.trim() },
                            })
                        }}
                        suffix={updateJobPostingMutation.isPaused ? <Spin /> : <></>}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    Job Category
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                        <Input
                            disabled={!jobPosting.archetype_is_draft || isImporting}
                            defaultValue={jobPosting.archetype_name}
                            placeholder="What sort of job is this?"
                            onBlur={e => {
                                if (jobPosting.archetype_is_draft) {
                                    updateJobPostingMutation.mutate({
                                        token: jobPosting.token,
                                        patch: { archetype_name: e.target.value.trim() },
                                    })
                                }
                            }}
                            style={{ flex: 1 }}
                        />
                        <div>
                            <Button
                                href={`/record_responses/${jobPosting.archetype}/preview?jp=${jobPosting.token}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Preview Interview <ExportOutlined />
                            </Button>
                        </div>
                    </div>
                    {isStaff && (intakeQuestions?.jobTypes ?? []).length ? (
                        <div style={{ backgroundColor: 'aliceblue' }}>
                            Change Archetype:{' '}
                            <Select
                                style={{ width: '300px' }}
                                options={(intakeQuestions?.jobTypes ?? [])
                                    .sort((jt1, jt2) => jt1.label.localeCompare(jt1.label))
                                    .map(jt => ({
                                        label: jt.label,
                                        value: jt.value,
                                    }))}
                                popupMatchSelectWidth={false}
                                defaultValue={
                                    (intakeQuestions?.jobTypes ?? []).find(
                                        jt => jt.value === jobPosting.archetype_name,
                                    )
                                        ? jobPosting.archetype_name
                                        : undefined
                                }
                                onChange={val => {
                                    if (isStaff) {
                                        updateJobPostingMutation.mutate({
                                            token: jobPosting.token,
                                            patch: { staffArchetypeName: val },
                                        })
                                    }
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    Job Description
                    <WrappedJoditEditor
                        value={jobPosting.info?.description ?? ''}
                        placeholder="Describe the job"
                        disabled={isImporting}
                        onBlurHandler={v => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: {
                                    info: {
                                        ...jobPosting.info,
                                        description: v.trim(),
                                    },
                                },
                            })
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                    }}
                >
                    Skills
                    <Select
                        mode="tags"
                        style={{ width: '350px' }}
                        maxTagCount="responsive"
                        popupMatchSelectWidth={false}
                        disabled={isImporting}
                        defaultValue={jobPosting.info?.skills}
                        onChange={vals => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: {
                                    info: {
                                        ...jobPosting.info,
                                        skills: vals,
                                    },
                                },
                            })
                        }}
                        placeholder="What skills are relevant?"
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                    }}
                >
                    Salary Range
                    <InputNumber
                        precision={0}
                        prefix="$"
                        disabled={isImporting}
                        defaultValue={jobPosting.info?.salaryRange?.[0]}
                        onChange={v => {
                            if (v || v === 0) {
                                updateJobPostingMutation.mutate({
                                    token: jobPosting.token,
                                    patch: {
                                        info: {
                                            ...jobPosting.info,
                                            salaryRange: [
                                                Number(v),
                                                jobPosting.info?.salaryRange?.[1] ?? Number(v) + 1,
                                            ],
                                        },
                                    },
                                })
                            }
                        }}
                        min={0}
                        status={
                            (jobPosting.info?.salaryRange?.[0] ?? 0) >
                            (jobPosting.info?.salaryRange?.[1] ?? 0)
                                ? 'warning'
                                : undefined
                        }
                    />{' '}
                    -{' '}
                    <InputNumber
                        precision={0}
                        prefix="$"
                        min={0}
                        disabled={isImporting}
                        defaultValue={jobPosting.info?.salaryRange?.[1]}
                        onChange={v => {
                            if (v || v === 0) {
                                updateJobPostingMutation.mutate({
                                    token: jobPosting.token,
                                    patch: {
                                        info: {
                                            ...jobPosting.info,
                                            salaryRange: [
                                                jobPosting.info?.salaryRange?.[0] ?? Number(v) - 1,
                                                Number(v),
                                            ],
                                        },
                                    },
                                })
                            }
                        }}
                        status={
                            (jobPosting.info?.salaryRange?.[0] ?? 0) >
                            (jobPosting.info?.salaryRange?.[1] ?? 0)
                                ? 'warning'
                                : undefined
                        }
                    />
                    <Select
                        defaultValue={jobPosting.info?.salaryType}
                        disabled={isImporting}
                        onChange={val => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: {
                                    info: {
                                        ...jobPosting.info,
                                        salaryType: val,
                                    },
                                },
                            })
                        }}
                        style={{ width: '100px' }}
                        popupMatchSelectWidth={false}
                        options={[
                            { label: 'per year', value: 'perYear' },
                            { label: 'per hour', value: 'perHour' },
                            { label: 'per contract', value: 'perContract' },
                        ]}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                    }}
                >
                    Job Type
                    <Radio.Group
                        value={jobPosting.info?.jobType}
                        disabled={isImporting}
                        onChange={e => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: {
                                    info: {
                                        ...jobPosting.info,
                                        jobType: e.target.value,
                                    },
                                },
                            })
                        }}
                    >
                        <Radio value="fullTime">Full-Time</Radio>
                        <Radio value="contract">Contract</Radio>
                        <Radio value="other">Other</Radio>
                    </Radio.Group>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        Location
                        <Radio.Group
                            value={jobPosting.info?.remote}
                            disabled={isImporting}
                            onChange={e => {
                                updateJobPostingMutation.mutate({
                                    token: jobPosting.token,
                                    patch: {
                                        info: {
                                            ...jobPosting.info,
                                            remote: e.target.value,
                                        },
                                    },
                                })
                            }}
                        >
                            <Radio value="remote">100% Remote allowed</Radio>
                            <Radio value="notRemote">Requires Some Office Attendance</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        Office Locations{' '}
                        {jobPosting.info?.remote === 'remote' ? ' (Optional)' : null}
                        {jobPosting.info?.officeLocations?.map((l, idx) => (
                            <LocationPicker
                                mode="office"
                                disabled={isImporting}
                                key={`${l.placeId}-${idx}`}
                                job={jobPosting}
                                place={l}
                            />
                        ))}
                        <LocationPicker
                            mode="office"
                            disabled={isImporting}
                            key={jobPosting.info?.officeLocations?.length ?? 0}
                            job={jobPosting}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        Applicant Location Requirements{' '}
                        {jobPosting.info?.remote !== 'remote' ||
                        jobPosting.info?.officeLocations?.length
                            ? ' (Optional)'
                            : null}
                        {jobPosting.info?.applicantLocations?.map((l, idx) => (
                            <>
                                <LocationPicker
                                    mode="applicant"
                                    disabled={isImporting}
                                    key={`${l.placeId}-${idx}`}
                                    job={jobPosting}
                                    place={l}
                                />
                            </>
                        ))}
                        <LocationPicker
                            mode="applicant"
                            disabled={isImporting}
                            key={jobPosting.info?.applicantLocations?.length ?? 0}
                            job={jobPosting}
                        />
                    </div>
                </div>
                <div>
                    <Button
                        loading={createPromptFormMutation.isPending}
                        disabled={isImporting}
                        onClick={() => {
                            if (!jobPosting.qualification_form) {
                                createPromptFormMutation.mutate(
                                    {
                                        name: 'Qualification Questions',
                                        mode: 'candidate',
                                        jobPostingToken: jobPosting.token,
                                    },
                                    {
                                        onSuccess: () => {
                                            setQualQuestionFormOpen(true)
                                        },
                                        onSettled: () => {
                                            queryClient.invalidateQueries({
                                                queryKey: ['jobPostings'],
                                            })
                                        },
                                    },
                                )
                            } else {
                                setQualQuestionFormOpen(true)
                            }
                        }}
                    >
                        Configure Qualification Questions
                    </Button>
                </div>
            </div>
        </div>
    )
}
const CLIENT_APP_DOMAIN = import.meta.env.VITE_CLIENT_URI

const CopyableInput = ({ text }: { text: string }) => {
    const [copied, setCopied] = useState(false)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Input
                style={{ flex: 1 }}
                readOnly
                value={text}
                onClick={() => {
                    navigator.clipboard.writeText(text)

                    setCopied(true)

                    setTimeout(() => {
                        setCopied(false)
                    }, 300)
                }}
            />
            {copied ? <span>Copied!</span> : null}
        </div>
    )
}

const PostingStatus = ({ jobPosting }: { jobPosting: JobPostingDetailType }) => {
    const updateJobPostingMutation = useUpdateJobPosting('candidatePool')
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '210px' }}>
            <div style={{ fontSize: '16px' }}>Posting is {capitalizeWords(jobPosting.status)}</div>
            {jobPosting.status === 'draft' ? (
                <div>
                    <Button
                        loading={updateJobPostingMutation.isPending}
                        type="primary"
                        onClick={() => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: { status: 'active' },
                            })
                        }}
                    >
                        Activate
                    </Button>
                    {/*TODO: preview posting??*/}
                </div>
            ) : jobPosting.status === 'active' ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center',
                        }}
                    >
                        Apply Link:{' '}
                        <CopyableInput
                            text={`${CLIENT_APP_DOMAIN}/record_responses/${jobPosting.archetype}/terminal?jp=${jobPosting.token}`}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center',
                        }}
                    >
                        Job Link:{' '}
                        <CopyableInput text={`${CLIENT_APP_DOMAIN}/job/${jobPosting.token}`} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                        {/*<Button type="primary">Update</Button> //TODO: this happens automatically, is that right? */}
                        <Button
                            onClick={() => {
                                updateJobPostingMutation.mutate({
                                    token: jobPosting.token,
                                    patch: { status: 'closed' },
                                })
                            }}
                            loading={updateJobPostingMutation.isPending}
                        >
                            Close Posting
                        </Button>
                    </div>
                </>
            ) : jobPosting.status === 'closed' ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Button
                        loading={updateJobPostingMutation.isPending}
                        type="primary"
                        onClick={() => {
                            updateJobPostingMutation.mutate({
                                token: jobPosting.token,
                                patch: { status: 'active' },
                            })
                        }}
                    >
                        Re-activate
                    </Button>
                </div>
            ) : null}
        </div>
    )
}

interface JobPostingDetailProps {
    mode: 'async' | 'candidatePool'
}

const hasJobDetailTab = (item?: UIMatch): item is UIMatch<unknown, { jobDetailTab: string }> =>
    !!(item?.handle as any)?.jobDetailTab
const JobPostingDetail = ({ mode }: JobPostingDetailProps) => {
    const { postingToken: jobPostingToken } = useParams()
    const { data: jobPosting } = useJobPosting(mode, jobPostingToken)
    const navigate = useNavigate()
    const curTab = useMatches().findLast(hasJobDetailTab)?.handle?.jobDetailTab
    if (!jobPosting) {
        return <Spin />
    }
    return (
        <div>
            {mode === 'candidatePool' ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography.Title level={3}>
                            <Link
                                style={{ color: 'black' }}
                                to={{
                                    pathname: '/jobBoard/employers/postings',
                                    search: window.location.search,
                                }}
                            >
                                <ArrowLeftOutlined />
                            </Link>{' '}
                            {jobPosting.name}
                        </Typography.Title>
                        <PostingStatus jobPosting={jobPosting} />
                    </div>

                    <Tabs
                        style={{ height: '100%', width: '100%' }}
                        onChange={e => {
                            navigate({ pathname: e, search: window.location.search })
                        }}
                        activeKey={curTab}
                        items={[
                            {
                                key: 'posting',
                                label: 'Posting',
                                // children: <_JobPostingDetail jobPosting={jobPosting} />,
                            },
                            {
                                key: 'applicants',
                                label: 'Applicants',
                                // children: (
                                //     <BrowseCandidates
                                //         fixedFilters={{ job: [jobPosting.token], promptForm: [] }}
                                //     />
                                // ),
                            },
                            {
                                key: 'pool',
                                label: 'Talent Pool',
                                // children: (
                                //     <BrowseCandidates
                                //         fixedFilters={{ promptForm: [jobPosting.archetype_name], job: [] }}
                                //     />
                                // ),
                            },
                            {
                                key: 'settings',
                                label: 'Settings',
                                // children: <div>settings</div>,
                                disabled: true,
                            },
                        ].map(t => ({ ...t, children: <Outlet /> }))}
                    />
                </>
            ) : (
                <Outlet />
            )}
        </div>
    )
}

export default JobPostingDetail

type LocationMode = 'applicant' | 'office'
const useSuggestedLocations = (query: string, mode: LocationMode) => {
    const debouncedQuery = useDebounce(query, 300)
    const qc = useQueryClient()
    return useQuery({
        queryKey: ['locationSuggestions', debouncedQuery, mode],
        queryFn: async ({ queryKey, signal }) => {
            const search = queryKey[1]
            const mode = queryKey[2]
            await qc.cancelQueries({ queryKey: ['locationSuggestions'] })
            return (
                await axios.post<{ data: SearchForSuggestionsResult[] }>(
                    '/jb_api/location_suggestions',
                    { search, mode },
                    { signal },
                )
            ).data.data
        },
    })
}
const useSetJobLocation = (mode: LocationMode, token: string) => {
    const qc = useQueryClient()
    return useMutation({
        mutationFn: async ({
            placeId,
            label,
            oldPlaceId,
        }: {
            placeId: string | null
            label?: string
            oldPlaceId?: string
        }) => {
            return await axios.post(`/jb_api/location/${token}`, {
                placeId,
                label,
                oldPlaceId,
                mode,
            })
        },
        onSettled: () => {
            return qc.invalidateQueries({
                queryKey: ['jobPostings', token],
            })
        },
    })
}
const LocationPicker = ({
    mode,
    job,
    place,
    disabled,
}: {
    mode: LocationMode
    job: JobPostingDetailType
    place?: Place
    disabled?: boolean
}) => {
    const [searchQuery, setSearchQuery] = useState('')
    const { data: suggestions } = useSuggestedLocations(searchQuery, mode)
    const setJobLocation = useSetJobLocation(mode, job.token)

    return (
        <div style={{ display: 'flex', gap: 4, marginLeft: 8 }}>
            <AutoComplete
                onSearch={setSearchQuery}
                disabled={disabled}
                onSelect={(v, opt) => {
                    setJobLocation.mutate({
                        placeId: opt.placeId ?? '', // hmm why does the suggestor say this might be undefined.
                        label: opt.label,
                        oldPlaceId: place?.placeId,
                    })
                }}
                defaultValue={place?.Label}
                options={suggestions?.map(s => ({
                    label: s.Text,
                    value: s.Text,
                    placeId: s.PlaceId,
                }))}
                style={{ width: 300 }}
            />
            {place ? (
                <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() =>
                        setJobLocation.mutate({
                            placeId: null,
                            oldPlaceId: place.placeId,
                        })
                    }
                />
            ) : null}
        </div>
    )
}
