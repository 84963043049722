import { Spin } from 'antd'
import { useEffect, useRef } from 'react'
const SpinnerCentered = ({
    shrinkTime,
    style,
    ...rest
}: { shrinkTime?: number } & JSX.IntrinsicElements['div']) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (shrinkTime === undefined) return
        const node = ref.current
        if (!node) return
        node.style.transform = 'scale(.3)'
    }, [shrinkTime])
    const newStyle: React.CSSProperties = {
        transform: 'scale(3)',
        transition: shrinkTime ? `transform ${shrinkTime}ms` : '',
        top: '50%',
        left: '50%',
        position: 'absolute',
        ...style,
    }

    return (
        <div ref={ref} style={newStyle} {...rest}>
            <Spin />
        </div>
    )
}

export default SpinnerCentered
