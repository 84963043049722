import React, { lazy, Suspense } from 'react'
import { WrappedJoditEditorProps } from './WrappedJoditEditor'

const LazyWrappedJoditEditor = lazy(() =>
    import('./WrappedJoditEditor').then(m => ({ default: m.WrappedJoditEditor })),
)

export const WrappedJoditEditor = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & WrappedJoditEditorProps,
) => (
    <Suspense fallback={null}>
        <LazyWrappedJoditEditor {...props} />
    </Suspense>
)
