import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { RoleSearch, PaginatedResult } from 'services/role_search_service'
import { ContributorsTableSortColInfo } from 'pages/ContributorsLibrary/ContributorsLibrary'
import { RoleRepTableSortColInfo, RoleRepFilters } from 'pages/Outbox/Outbox'
import { useParams } from 'react-router-dom'
import { UserInfo } from 'services/user_service'
import { RoleRepPromptQuestion } from './role_rep_prompt_service'
import { RoleRepResponseVideo } from './role_rep_response_video_service'
import { createRoleRepsEvent } from './intercom_event_tracking'

type RoleRepOwner = Pick<UserInfo, 'email' | 'friendly_name'>
export interface ResumeTextResponse {
    position?: string
    startDate?: string
    endDate?: string
    companyName?: string
    companySize?: string
}
export interface RoleRep {
    friendly_name: string
    email: string
    title: string
    uploaded_video: boolean
    personalized_survey_link: string
    readonly num_emails_received?: number // this will always be present from the backend but must not be set by the frontend
    role_rep_prompt_form_token: string | null
    token: string
    role_search_token?: string
    trusted: boolean
    readonly created: string // isostring
    readonly initial_email_time: string | null // isostring
    readonly initial_video_record_time: string | null // isostring
    info: {
        scheduled_interview?: string[] // start and end as isostrings
        answers?: Record<string, string | string[]>
        showcaseSummary?: string
        showcaseTagline?: string
        showcaseJustification?: string
        jobBoardInternalScore?: number
        jobBoardAcceptance?: boolean
        jobBoardAcceptanceTime?: string // isostring
        jobBoardAcceptanceUser?: string // email
        jobBoardUnsubscribeDate?: string
        jobBoardIntakeQuestions?: {
            industries?: string[]
            companySizes?: string[]
        }
        jobBoardWorkExperience?: ResumeTextResponse[] // this is the 'sorted' list of text info they entered in wtva
        resumeVideoTokensByResumeToken?: Record<string, string> // this is a map of 'resume token' (of the form AY-VIDEO-RESUME-IDX) to video token
    }
    jobBoardResumeVideoUrlsByIdx?: Record<number, string> // these are urls of videos recorded about the resume, idx corresponds to the text answers list
    readonly terminal_taker: boolean
    readonly initial_email_queued: boolean
    tags: string[]
    campaign_name: string
    owner?: RoleRepOwner
    role_rep_prompt_form_editable: boolean
    project: string
    programs: string[]
    readonly num_targets: number
    candidate_token?: string
    showcases: string[]
    readonly email_verified: boolean
}
export const useCountsByContributorEmail = () => {
    const query = useQuery({
        queryKey: ['counts_by_contributor_email'],
        queryFn: getCountsByContributorEmail,
    })
    return query
}

export interface Contributor {
    readonly email: string
    friendly_name?: string
    title?: string
    token: string
    readonly num_total_contribution_requests: number
    readonly num_outstanding_contribution_requests: number
    readonly terminal_only: boolean
    readonly employee_termed: boolean
    tags: string[]
    readonly campaign_names: string[]
    readonly posterUrl?: string
    archived: boolean
}

const getCountsByContributorEmail = async () => {
    const res = await axios.get<{
        data: Record<string, { numVideos: number; numChannels: number }>
    }>('/api/counts_by_contributor_email')
    return res.data.data
}

const getRoleRepOrderingString = (orderObj?: RoleRepTableSortColInfo) => {
    if (!orderObj || !orderObj.key) {
        return undefined
    }
    const field = orderObj.key
    const orderString = orderObj.order === 'descend' ? '-' : ''
    return orderString + field
}

export const useRoleRepTags = (type: 'Contributor' | 'Candidate' | 'All') => {
    const _get = async () => {
        const res = await axios.get<{ tags: string[] }>(
            `/api/contribution_request_tags?type=${type}`,
        )
        return res.data.tags
    }

    const query = useQuery({ queryKey: ['roleReps', 'tags', type], queryFn: _get })
    return query
}

export const useRoleRepCampaignNames = () => {
    const _get = async () => {
        const res = await axios.get<{ campaign_names: string[] }>('/api/crq_campaign_names')
        return res.data.campaign_names
    }
    const query = useQuery({ queryKey: ['roleReps', 'campaign_names'], queryFn: _get })
    return query
}

export const useRoleRepsPaged = (
    pageNumber: number,
    pageSize: number = 20,
    type: 'Contributor' | 'Candidate' | 'all',
    filters?: RoleRepFilters,
    order?: RoleRepTableSortColInfo,
    searchQuery?: string,
    enabled = true,
) => {
    const _get = async () => {
        const res = await axios.get<PaginatedResult<RoleRep>>('/api/role_reps_paged', {
            params: {
                page_size: pageSize,
                page: pageNumber,
                ...(filters ?? {}),
                ordering: getRoleRepOrderingString(order),
                search: searchQuery,
                type,
            },
        })
        return { data: res.data.results, total: res.data.count }
    }

    const query = useQuery({
        queryKey: [
            'roleReps',
            'paged',
            pageNumber,
            pageSize,
            JSON.stringify(Object.entries(filters ?? {}).sort()),
            order,
            searchQuery,
            type,
        ],
        queryFn: _get,
        enabled,
    })
    return query
}

export const useRoleRepQueryArgs = (token: string, enabled?: boolean) => {
    const _get = async (token: string) => {
        const res = await axios.get<RoleRep>(`/api/role_rep/${token}`)
        return res.data
    }
    return {
        queryKey: ['roleReps', token],
        queryFn: () => _get(token),
        enabled: enabled ?? !!token,
    }
}

export const useRoleRep = (token?: string, enabled?: boolean) => {
    const { crqToken: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    const query = useQuery(useRoleRepQueryArgs(tokenToUse, enabled))
    return query
}

export const useRoleReps = (roleSearchToken?: string, enabled?: boolean, projectToken?: string) => {
    const queryKey = ['roleReps']
    if (roleSearchToken) {
        queryKey.push(roleSearchToken)
    }
    if (projectToken) {
        queryKey.push(projectToken)
    }

    const query = useQuery({
        queryKey,
        queryFn: () => getRoleReps(roleSearchToken || '', projectToken),
        enabled: enabled !== undefined ? enabled : true,
    })
    return query
}

export const pregenerateVideoaskForm = async (role_rep_token: string) => {
    const res = await axios.get(`/api/pregenerate_role_rep_prompt_form/${role_rep_token}`)
    return res.data
}

const getRoleReps = async (roleSearchToken?: string, projectToken?: string, allReps?: boolean) => {
    const res = await axios.get<RoleRep[]>(
        `/api/role_reps/${roleSearchToken ? roleSearchToken : ''}${projectToken ?? ''}`,
        { params: { all: allReps ? 'true' : undefined } },
    )
    return res.data
}
export const addRoleRep = async (
    roleRep: Omit<RoleRep, 'token' | 'terminal_taker'>,
    roleSearchToken?: string,
) => {
    const res = await axios.post<RoleRep>(`/api/role_reps/${roleSearchToken ?? ''}`, roleRep)
    return res.data
}

export const useCreateRoleRepsMutation = (mode: 'Contributors' | 'Candidates') => {
    const queryClient = useQueryClient()
    const _create = async (
        roleReps: Partial<RoleRep>[],
        sendEmail: boolean,
        programToken: string | undefined,
    ) => {
        const res = await axios.post<{ numEmailsQueued: number; messages: string[] }>(
            '/api/bulk_create_role_reps',
            {
                roleReps,
                sendEmail,
                programToken,
                mode,
            },
        )
        return res.data
    }
    const mutation = useMutation({
        mutationFn: ({
            roleReps,
            sendEmail,
            programToken,
        }: {
            roleReps: Partial<RoleRep>[]
            sendEmail: boolean
            programToken: string | undefined
        }) => _create(roleReps, sendEmail, programToken),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
            await queryClient.cancelQueries({
                queryKey: ['programs'],
            })
            await queryClient.cancelQueries({
                queryKey: ['jobBoard', 'candidates'],
            })
            await queryClient.cancelQueries({
                queryKey: ['company_details_for_company'],
            })
        },
        onSuccess: (
            res,
            {
                roleReps,
                sendEmail,
                programToken,
            }: {
                roleReps: Partial<RoleRep>[]
                sendEmail: boolean
                programToken: string | undefined
            },
        ) => {
            createRoleRepsEvent(roleReps.length, sendEmail)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
            queryClient.invalidateQueries({
                queryKey: ['programs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['jobBoard', 'candidates'],
            })
            queryClient.invalidateQueries({
                queryKey: ['company_details_for_company'],
            })
        },
    })
    return mutation
}

export const uploadRoleRepCSV = async (form: FormData) => {
    const res = await axios.post<{ msgs: string[] }>('/api/upload_role_rep_csv', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data
}

export const useForceVerifyEmail = () => {
    const _post = async (token: string) => {
        const res = await axios.post(`/api/force_verify_email/${token}`)
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: (token: string) => _post(token),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
        },
    })
    return mutation
}

export const updateRoleRep = async (roleRepToken: string, patch: Partial<RoleRep>) => {
    const res = await axios.patch<RoleRep>(`/api/role_rep/${roleRepToken}`, patch)
    return res.data
} // TODO change over things that use this to the mutation

export const useUpdateRoleRep = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ token, patch }: { token: string; patch: Partial<RoleRep> }) =>
            updateRoleRep(token, patch),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
        },
    })
    return mutation
}

export const useDeleteRoleRep = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (token: string) => deleteRoleRep(token),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
        },
    })
    return mutation
}

const deleteRoleRep = async (roleRepToken?: string) => {
    const res = await axios.delete(`/api/role_rep/${roleRepToken}`)
    return res.data
}

export const useSendNextEmailToRoleReps = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            roleRepTokens,
            allowResendTemplate,
        }: {
            roleRepTokens: string[]
            allowResendTemplate?: number
        }) => sendNextEmailToRoleReps(roleRepTokens, allowResendTemplate),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
        },
    })
    return mutation
}

const sendNextEmailToRoleReps = async (roleRepTokens: string[], allowResendTemplate?: number) => {
    await axios.post<string>('/api/send_role_rep_emails', { roleRepTokens, allowResendTemplate })
    return roleRepTokens
}

type EmailTemplateType = 'roleRep' | 'candidatePool' | 'asyncCandidate' | 'candidate'
export interface EmailInfo {
    sequence_number: number
    subject: string
    html_body: string
    emailType?: EmailTemplateType
    default_personalizations: { subject: string[]; body: string[] } // these are the allowed *||*'s for this email
    wave_name: string
    campaign_token: string | null
    role_search_token: string | null
    job_posting_token: string | null
}

const getEmailTemplates = async (type: EmailTemplateType, token?: string): Promise<EmailInfo[]> => {
    const res = await axios.get<EmailInfo[]>(
        `/api/role_rep_email_templates/${token ?? ''}?type=${type}`,
    )
    return res.data.map(e => ({ ...e, emailType: type }))
}

export const useRoleRepEmailTemplates = (token?: string) => {
    const queryKey = ['role_rep_email_templates', 'roleRep']
    if (token) {
        queryKey.push(token)
    }
    const query = useQuery({
        queryKey,
        queryFn: () => getEmailTemplates('roleRep', token ?? ''),
        enabled: token === undefined ? true : !!token,
    })
    return query
}

export const useAsyncCandidateEmailTemplates = (token?: string) => {
    const queryKey = ['role_rep_email_templates', 'asyncCandidate']
    if (token) {
        queryKey.push(token)
    }
    const query = useQuery({
        queryKey,
        queryFn: () => getEmailTemplates('asyncCandidate', token ?? ''),
        enabled: token === undefined ? true : !!token,
    })
    return query
}

export const useRoleRepEmailMutation = (objectToken?: string) => {
    const queryClient = useQueryClient()
    const queryKey = ['role_rep_email_templates']
    if (objectToken) {
        queryKey.push(objectToken)
    }
    const mutation = useMutation({
        mutationFn: (email: EmailInfo) => updateRoleRepEmailTemplate(email, objectToken),

        onMutate: async (email: EmailInfo) => {
            await queryClient.cancelQueries({
                queryKey: queryKey,
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKey,
            })
        },
    })
    return mutation
}

export const updateRoleRepEmailTemplate = async (
    email: EmailInfo,
    token?: string,
): Promise<EmailInfo> => {
    const res = await axios.put<EmailInfo>(
        `/api/role_rep_email_templates/${email.sequence_number}/${token ?? ''}`,
        email,
    )
    return { ...res.data, emailType: email.emailType }
}

export const useDeleteCustomRoleRepEmailTemplateMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ token, sequence_number }: { token: string; sequence_number: number }) =>
            deleteCustomEmailTemplate(token, sequence_number),

        onMutate: async ({
            token,
            sequence_number,
        }: {
            token: string
            sequence_number: number
        }) => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_email_templates', token],
            })
        },
        onSettled: (
            data: any,
            error: any,
            { token, sequence_number }: { token: string; sequence_number: number },
        ) => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_email_templates', token],
            })
        },
    })
    return mutation
}

const deleteCustomEmailTemplate = async (token: string, sequence_number: number) => {
    const res = await axios.delete(`/api/role_rep_email_templates/${sequence_number}/${token}`)
    return res.data
}

const getContributorOrderingString = (orderObj?: ContributorsTableSortColInfo) => {
    const orderKeyMap: Record<string, string> = {
        contributorInfo: 'email',
        outstandingRequests: 'num_outstanding_contribution_requests',
        totalRequests: 'num_total_contribution_requests',
        terminalContributor: 'terminal_only',
    }
    if (!orderObj || !orderObj.key) {
        return undefined
    }
    const field = orderKeyMap?.[orderObj.key ?? ''] ?? orderObj.key
    const orderString = orderObj.order === 'descend' ? '-' : ''
    return orderString + field
}

export const useUniqueContributorIdents = (type: 'Contributor' | 'Candidate') => {
    const _get = async () => {
        const res = await axios.get<{ idents: { email: string; name?: string; title?: string }[] }>(
            `/api/unique_contributor_idents?type=${type}`,
        )
        return res.data.idents
    }
    const query = useQuery({ queryKey: ['contributors', 'idents', type], queryFn: _get })
    return query
}

export const useContributors = (
    pageNumber: number,
    pageSize: number | null, // null tells the backend to not page
    filters?: Record<string, string | boolean>,
    order?: ContributorsTableSortColInfo,
    searchQuery?: string,
) => {
    const _get = async () => {
        const res = await axios.get<PaginatedResult<Contributor>>('/api/contributors', {
            params: {
                page_size: pageSize,
                page: pageNumber,
                ...(filters ?? {}),
                ordering: getContributorOrderingString(order),
                search: searchQuery,
            },
        })
        return { data: res.data.results, total: res.data.count }
    }
    const query = useQuery({
        queryKey: ['contributors', pageNumber, filters, order, searchQuery],
        queryFn: _get,
    })

    return query
}
export const usePotentialDuplicateContributors = (token: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<{ contributors: Contributor[] }>(
            `/api/get_potential_duplicate_contributors/${token}`,
        )
        return res.data.contributors
    }
    const query = useQuery({
        queryKey: ['contributors', 'duplicates', token],
        queryFn: () => _get(token),
        enabled: !!token,
    })
    return query
}
export const useMergeContributors = () => {
    const _merge = async (emails: string[], baseEmail: string) => {
        const res = await axios.post('/api/merge_contributors', { emails, baseEmail })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ emails, baseEmail }: { emails: string[]; baseEmail: string }) =>
            _merge(emails, baseEmail),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['contributors'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['contributors'],
            })
        },
    })
    return mutation
}

export const useUpdateContributor = () => {
    const _update = async (
        contributorToken: string,
        patch: Partial<Contributor> & { name?: string; title?: string },
    ) => {
        const updatedPatch = { ...patch, raw_fname: patch.name ? patch.name : undefined }
        const res = await axios.patch(`/api/contributor/${contributorToken}`, updatedPatch)
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            contributorToken,
            patch,
        }: {
            contributorToken: string
            patch: Partial<Contributor> & { name?: string; title?: string }
        }) => _update(contributorToken, patch),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['contributors'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['contributors'],
            })
        },
    })
    return mutation
}

export const useChannelsContributorVideosAreIn = (contributorToken: string) => {
    const _get = async (contributorToken: string) => {
        const res = await axios.get<{
            roleSearches: { name: string; token: string; digests: RoleSearch['digests'] }[]
        }>(`/api/channels_contributor_is_in/${contributorToken}`)
        return res.data.roleSearches
    }

    const query = useQuery({
        queryKey: ['roleSearches', contributorToken],
        queryFn: () => _get(contributorToken),
        enabled: !!contributorToken,
    })
    return query
}

export const useAddTagsToContributorVideos = () => {
    const _update = async (contributorToken: string, tag: string) => {
        const res = await axios.post(`/api/add_tag_to_contribtor/${contributorToken}`, {
            tag,
        })
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({ contributorToken, tag }: { contributorToken: string; tag: string }) =>
            _update(contributorToken, tag),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['video_filters_tags'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['video_filters_tags'],
            })
        },
    })

    return mutation
}

export const UseAchiveContributor = () => {
    const _remove = async (
        contributorToken: string,
        archiveOptions: {
            removeVideos: boolean
            republishChannels: boolean
            archiveVideos: boolean
        },
    ) => {
        const res = await axios.post('/api/remove_contributor_videos_from_channels', {
            contributorToken,
            ...archiveOptions,
        })
        return res.data
    }

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            contributorToken,
            archiveOptions,
        }: {
            contributorToken: string
            archiveOptions: {
                removeVideos: boolean
                republishChannels: boolean
                archiveVideos: boolean
            }
        }) => _remove(contributorToken, archiveOptions),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleSearches'],
            })
            await queryClient.cancelQueries({
                queryKey: ['contributors'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleSearches'],
            })
            queryClient.invalidateQueries({
                queryKey: ['contributors'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
        },
    })
    return mutation
}

export const useBulkUpdateRoleReps = () => {
    const _patch = async (
        tokens: string[],
        patch?: {
            campaignName?: string
            role_rep_prompt_form_token?: string
            emailInfo?: EmailInfo
        },
        deleteReps: boolean = false, // sort of out of place but making a whole separate endpiont just for deletes seemed a waste
    ) => {
        const res = await axios.patch('/api/bulk_update_role_reps', { tokens, patch, deleteReps })
        return res.data
    }

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            tokens,
            patch,
            deleteReps,
        }: {
            tokens: string[]
            patch?: {
                campaignName?: string
                role_rep_prompt_form_token?: string
                emailInfo?: EmailInfo
            }
            deleteReps?: boolean
        }) => _patch(tokens, patch, deleteReps),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
        },
    })
    return mutation
}

interface CRQTarget {
    feed_token: string
    auto_publish: boolean
}

export const useCRQTargets = (crqToken: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<CRQTarget[]>('/api/crq_targets/' + token)
        return res.data
    }

    const query = useQuery({
        queryKey: ['crqTargets', crqToken],
        queryFn: () => _get(crqToken),
        enabled: !!crqToken,
    })
    return query
}

export interface CreateTargetType {
    auto_publish: boolean
    mode: 'v8' | 'v16'
    newChannelName?: string
    existingChannelToken?: string
}

export const useCreateCRQTarget = () => {
    const _post = async (crqToken: string, data: CreateTargetType) => {
        const res = await axios.post<CRQTarget>('/api/crq_targets/' + crqToken, data)
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ crqToken, data }: { crqToken: string; data: CreateTargetType }) =>
            _post(crqToken, data),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['crqTargets'],
            })
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['crqTargets'],
            })
            queryClient.invalidateQueries({
                queryKey: ['feeds'],
            })
        },
    })
    return mutation
}

export const useDeleteCRQTarget = () => {
    const _delete = async (crqToken: string, feedToken: string) => {
        const res = await axios.delete(`/api/crq_target/${crqToken}`, {
            data: { feed_token: feedToken },
        })
        return res.data
    }

    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({ crqToken, feedToken }: { crqToken: string; feedToken: string }) =>
            _delete(crqToken, feedToken),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['crqTargets'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['crqTargets'],
            })
        },
    })
    return mutation
}

export const useUpdateCRQTarget = () => {
    const _patch = async (
        crqToken: string,
        feedToken: string,
        patch: Partial<Omit<CRQTarget, 'feed_token'>>,
    ) => {
        const res = await axios.patch<CRQTarget>(`/api/crq_target/${crqToken}`, {
            ...patch,
            feed_token: feedToken,
        })
        return res.data
    }
    const queryClient = useQueryClient()

    const mutation = useMutation({
        mutationFn: ({
            crqToken,
            feedToken,
            patch,
        }: {
            crqToken: string
            feedToken: string
            patch: Partial<Omit<CRQTarget, 'feed_token'>>
        }) => _patch(crqToken, feedToken, patch),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['crqTargets'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['crqTargets'],
            })
        },
    })
    return mutation
}

export interface RoleRepVideoResponse {
    question: RoleRepPromptQuestion & { qtype: 'video' }
    answer: RoleRepResponseVideo
}
export interface RoleRepTextResponse {
    question: RoleRepPromptQuestion & { qtype: 'text' | 'multiple_choice' }
    answer: string[] | string
    answered?: string
}

type RoleRepResponse = RoleRepTextResponse | RoleRepVideoResponse
export const isVideoResponse = (r: RoleRepResponse): r is RoleRepVideoResponse => {
    return r.question.qtype === 'video'
}

export const useRoleRepResponses = (crqToken: string) => {
    const _get = async (token: string) => {
        const res = await axios.get<{ data: RoleRepResponse[] }>(
            '/api/responses_for_role_rep/' + token,
        )
        return res.data.data
    }

    const query = useQuery({
        queryKey: ['role_rep_responses', crqToken],
        queryFn: () => _get(crqToken),
        enabled: !!crqToken,
    })
    return query
}

export const useContributorImage = (contributorToken: string) => {
    return useQuery({
        queryKey: ['roleReps', contributorToken, 'image'],
        queryFn: async () => {
            return (
                await axios.get<{ url?: string }>(`/api/contributor/${contributorToken}/avatar`)
            ).data.url
        },
    }).data
}
