import React, { lazy, Suspense } from 'react'
import { ChannelsTable2Props } from './ChannelsTable'

const LazyChannelsTable = lazy(() => import('./ChannelsTable'))

const ChannelsTable = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
    <Suspense fallback={null}>
        <LazyChannelsTable {...props} />
    </Suspense>
)

export default ChannelsTable

const LazyChannelsTable2 = lazy(() =>
    import('./ChannelsTable').then(m => ({ default: m.ChannelsTable2 })),
)

export const ChannelsTable2 = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelsTable2Props,
) => <LazyChannelsTable2 {...props} />
