import React, { lazy, Suspense } from 'react'
import { ChannelInboxProps, CRQInboxProps } from './ChannelInbox'
const LazyChannelInbox = lazy(() => import('./ChannelInbox'))

const ChannelInbox = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ChannelInboxProps,
) => (
    <Suspense fallback={null}>
        <LazyChannelInbox {...props} />
    </Suspense>
)

export default ChannelInbox

const LazyCRQInbox = lazy(() => import('./ChannelInbox').then(m => ({ default: m.CRQInbox })))

export const CRQInbox = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CRQInboxProps,
) => (
    <Suspense fallback={null}>
        <LazyCRQInbox {...props} />
    </Suspense>
)
