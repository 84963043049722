import axios from 'axios'
import {
    useQuery,
    useQueryClient,
    useMutation,
    QueryClient,
    UseQueryOptions,
    MutationOptions,
} from '@tanstack/react-query'
import { createInterviewEvent } from './intercom_event_tracking'

export type RoleRepPromptQuestionQType = 'video' | 'info' | 'text' | 'multiple_choice'

export interface RoleRepPromptQuestionInfo {
    choices: string[]
    allowMultipleSelections?: boolean
    tag?: boolean
    maxDuration?: number
    thinkTime?: number
    takeLimit?: number
    required?: boolean
}

export interface RoleRepPromptQuestion {
    url: string
    prompt: string
    topic: string // it happens to be a python enum, but theyre read-only here, so i dont think we need to care
    token: string
    description?: string
    contributor_tips?: string
    example_video_urls?: string[]
    in_use: boolean
    mode: 'contributor' | 'candidate'
    qtype: RoleRepPromptQuestionQType
    info: RoleRepPromptQuestionInfo
}

export interface GroupedRoleRepPromptQuestion extends RoleRepPromptQuestion {
    subQuestions?: RoleRepPromptQuestion[] // strictly speaking only info type questions should have this filled out
}

export const getGroupedQuestions = (rawQuestions: RoleRepPromptQuestion[]) => {
    const groupedQuestions: GroupedRoleRepPromptQuestion[] = []
    let lastTextInfoQuestionIdx = 0

    rawQuestions.forEach((q_, idx) => {
        const q = { ...q_ }
        if (q.qtype === 'video') {
            groupedQuestions.push(q)
        } else if (q.qtype === 'info') {
            groupedQuestions.push(q)
            lastTextInfoQuestionIdx = groupedQuestions.length - 1
        } else {
            const curGroup = (groupedQuestions[lastTextInfoQuestionIdx]?.subQuestions ?? []).filter(
                q_ => q_.token !== q.token,
            ) // someone was giving me dupes, i suspect the mildly weird way things are tossed around in FormDetail component
            if (!groupedQuestions?.[lastTextInfoQuestionIdx]) {
            } else {
                groupedQuestions[lastTextInfoQuestionIdx].subQuestions = [...curGroup, q]
            }
        }
    })
    return groupedQuestions
}

export const useTemplateIndustryTags = () => {
    const _get = async () => {
        const res = await axios.get<{ tags: string[] }>('/api/get_archetype_industry_options')
        return res.data.tags
    }
    const query = useQuery({
        queryKey: ['role_rep_prompt_form_templates', 'tags'],
        queryFn: _get,
    })
    return query
}

export interface RoleRepPromptFormTemplate {
    token: string
    prompt_questions: RoleRepPromptQuestion[]
    name: string
    is_default: boolean
    parent?: string
    reusable_links_by_token: Record<string, string>
    archived: boolean
    intro_video_url: string | null
    preview_channel_in_wtva: boolean
    uneditable: boolean
    created: string
    mode: 'contributor' | 'candidate'
    user_choice: boolean
    archetype_draft: boolean
    is_new_requested_archetype: boolean
    industry_tag: string
    description: string
    job_postings_info: { companyToken: string; name: string; token: string; companyName: string }[]
    brand_template?: string | null
    num_responses: number
    last_response_date: string
}

const getRoleRepPromptQuestionBank = async (
    mode: 'candidate' | 'contributor',
    qtype: RoleRepPromptQuestionQType,
) => {
    const res = await axios.get<{ questions: RoleRepPromptQuestion[] }>(
        `/api/get_prompt_question_bank/${mode}/${qtype}`,
    )
    return res.data.questions
}
export const usePromptQuestionBank = (
    mode: 'candidate' | 'contributor',
    qtype: RoleRepPromptQuestionQType,
) => {
    const query = useQuery({
        queryKey: ['prompt_question_bank', mode, qtype],
        queryFn: () => getRoleRepPromptQuestionBank(mode, qtype),
    })
    return query
}
export const updateRoleRepPromptForm = async (
    roleRepPromptFormToken: string,
    patch: Partial<RoleRepPromptFormTemplate>,
    rolesearchToken?: string,
) => {
    const res = await axios.patch<RoleRepPromptFormTemplate>(
        `/api/prompt_form_templates/${roleRepPromptFormToken}`,
        patch,
        { params: { rs: rolesearchToken } },
    )
    return res.data
}
export const useUpdateRoleRepPromptForm = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (
            form: Partial<RoleRepPromptFormTemplate> & Pick<RoleRepPromptFormTemplate, 'token'>,
            roleSearchToken?: string,
        ) => updateRoleRepPromptForm(form.token, { ...form, token: undefined }, roleSearchToken),

        onMutate: async patch => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
            queryClient.setQueriesData<RoleRepPromptFormTemplate[]>(
                {
                    predicate: ({ queryKey: k }) =>
                        k[0] === 'role_rep_prompt_form_templates' && k[2] === patch.token,
                },
                v => v?.map(f => (f.token === patch.token ? { ...f, ...patch } : f)),
            )
        },
        onSettled: () =>
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            }),
    })
    return mutation
}

export const useDeleteRoleRepPromptForm = () => {
    const queryClient = useQueryClient()
    const deleteRoleRepPromptForm = useMutation({
        mutationFn: (roleRepPromptFormToken: string) => {
            return axios.delete(`/api/prompt_form_templates/${roleRepPromptFormToken}`)
        },

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
    })
    return deleteRoleRepPromptForm
}

export const useConvertArchetypeRequestToArchetype = () => {
    const _post = async (archetypeToken: string, postingTokensToConvert: string[]) => {
        const res = await axios.post('/api/convert_archetype_request_to_actual/' + archetypeToken, {
            postingTokensToConvert,
        })
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            archetypeToken,
            postingTokensToConvert,
        }: {
            archetypeToken: string
            postingTokensToConvert: string[]
        }) => _post(archetypeToken, postingTokensToConvert),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: ['role_rep_prompt_form_templates'] })
            await queryClient.cancelQueries({ queryKey: ['job_postings'] })
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['role_rep_prompt_form_templates'] })
            queryClient.invalidateQueries({ queryKey: ['job_postings'] })
        },
    })
    return mutation
}
export const useCreateRoleRepPromptFormTemplate = () => {
    const createRoleRepPromptForm = async (
        name: string,
        mode: 'candidate' | 'contributor',
        jobPostingToken?: string,
        sourceFormToken?: string,
    ) => {
        const res = await axios.post<RoleRepPromptFormTemplate>(
            `/api/prompt_form_templates/${mode}`,
            {
                name,
                jobPostingToken,
                sourceFormToken,
            },
        )
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            name,
            mode,
            jobPostingToken,
            sourceFormToken,
        }: {
            name: string
            mode: 'candidate' | 'contributor'
            jobPostingToken?: string
            sourceFormToken?: string
        }) => createRoleRepPromptForm(name, mode, jobPostingToken, sourceFormToken),
        onMutate: () =>
            queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            }),
        onSuccess: (
            form,
            {
                name,
                mode,
                jobPostingToken,
            }: {
                name: string
                mode: 'candidate' | 'contributor'
                jobPostingToken?: string
                sourceFormToken?: string
            },
        ) => {
            createInterviewEvent(form.name, mode)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
    })
    return mutation
}

export const useCreateTerminalPromptFormMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({
            templateToken,
            roleSearchToken,
        }: {
            templateToken: string
            roleSearchToken?: string
        }) => {
            const res = await axios.post<{ url: string }>(`/api/terminal_prompt_form`, {
                templateToken,
                roleSearchToken,
            })
            return res.data
        },

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        onSettled: async () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
    })
}

const createCustomRoleRepPrompt = async (roleRepPromptFormToken: string, inputForm: FormData) => {
    const res = await axios.post<RoleRepPromptQuestion>(
        `/api/custom_role_rep_prompt_question/${roleRepPromptFormToken}`,
        inputForm,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    return res.data
}

export const useCreateCustomRoleRepPrompt = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ rrpfToken, form }: { rrpfToken: string; form: FormData }) =>
            createCustomRoleRepPrompt(rrpfToken, form),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['prompt_question_bank'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['prompt_question_bank'],
            })
        },
    })
    return mutation
}

export const updateCustomRoleRepPrompt = async (
    roleRepPromptFormToken: string,
    roleRepPromptQuestionToken: string,
    patch: Partial<RoleRepPromptQuestion>,
) => {
    const res = await axios.patch<RoleRepPromptQuestion>(
        `/api/custom_role_rep_prompt_question/${roleRepPromptFormToken}/${roleRepPromptQuestionToken}`,
        patch,
    )
    return res.data
}

export const useUpdateCustomRoleRepPromptVideo = () => {
    const queryClient = useQueryClient()
    const _post = async (token: string, rrpfToken: string, inputForm: FormData) => {
        const res = await axios.post<RoleRepPromptQuestion>(
            `/api/custom_role_rep_prompt_question_video/${rrpfToken}/${token}`,
            inputForm,
            { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        return res.data
    }

    const mutation = useMutation({
        mutationFn: ({
            token,
            form,
            rrpfToken,
        }: {
            token: string
            rrpfToken: string
            form: FormData
        }) => _post(token, rrpfToken, form),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['prompt_question_bank'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['prompt_question_bank'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
    })
    return mutation
}

export interface UpdateCustomPromptMutationVars {
    rrpfToken: string
    rrpqToken: string
    patch: Partial<RoleRepPromptQuestion>
}

export const useUpdateCustomRoleRepPrompt = (
    opts?: Partial<MutationOptions<RoleRepPromptQuestion, unknown, UpdateCustomPromptMutationVars>>,
) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ rrpfToken, rrpqToken, patch }: UpdateCustomPromptMutationVars) =>
            updateCustomRoleRepPrompt(rrpfToken, rrpqToken, patch),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['prompt_question_bank'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        onSettled: newQuestion => {
            if (newQuestion) {
                queryClient
                    .getQueriesData<RoleRepPromptQuestion[]>({
                        queryKey: ['prompt_question_bank'],
                    })
                    .forEach(([queryKey, v]) => {
                        if (v === undefined) {
                            return
                        }
                        const qIdx = v.findIndex(q => q.token === newQuestion.token)
                        if (qIdx > -1) {
                            const newData = [...v.slice(0, qIdx), newQuestion, ...v.slice(qIdx + 1)]
                            queryClient.setQueryData<RoleRepPromptQuestion[]>(queryKey, newData)
                        }
                    })
                queryClient
                    .getQueriesData<RoleRepPromptFormTemplate[]>({
                        queryKey: ['role_rep_prompt_form_templates'],
                    })
                    .forEach(([queryKey, v]) => {
                        if (v === undefined) {
                            return
                        }
                        const newData = [...v]
                        let needToSave = false
                        v.forEach((template, tIdx) => {
                            const qIdx = template.prompt_questions.findIndex(
                                q => q.token === newQuestion.token,
                            )
                            if (qIdx > -1) {
                                needToSave = true
                                const newTemplate = {
                                    ...template,
                                    prompt_questions: [
                                        ...template.prompt_questions.slice(0, qIdx),
                                        newQuestion,
                                        ...template.prompt_questions.slice(qIdx + 1),
                                    ],
                                }
                                newData[tIdx] = newTemplate
                            }
                        })
                        if (needToSave) {
                            queryClient.setQueryData(queryKey, newData)
                        }
                    })
            }
            queryClient.invalidateQueries({
                queryKey: ['prompt_question_bank'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        ...opts,
    })
    return mutation
}

export const useAvailablePromptTopics = (mode: 'contributor' | 'candidate') => {
    const _get = async (mode: 'contributor' | 'candidate') => {
        const res = await axios.get<{ topics: string[] }>('/api/get_available_topics/' + mode)
        return res.data.topics
    }
    const query = useQuery({ queryKey: ['topics', mode], queryFn: () => _get(mode) })
    return query
}

export const useRoleRepPromptFormTemplate = (
    mode: 'contributor' | 'candidate' | 'all',
    token: string,
) => {
    const _get = async () => {
        const res = await axios.get<RoleRepPromptFormTemplate[]>(
            '/api/prompt_form_templates/' + mode,
        )
        return res.data
    }

    const query = useQuery({
        queryKey: ['role_rep_prompt_form_templates', mode, token],
        queryFn: _get,
        select: data => data?.find(f => f.token === token),
        enabled: !!token,
    })
    return query
}

export const roleRepPromptFormTemplatesQueryArg = (mode: 'contributor' | 'candidate' | 'all') => {
    return {
        queryKey: ['role_rep_prompt_form_templates', mode],
        queryFn: async () => {
            const res = await axios.get<RoleRepPromptFormTemplate[]>(
                '/api/prompt_form_templates/' + mode,
            )
            return res.data.sort((f1, f2) => f1.name.localeCompare(f2.name))
        },
    }
}
export const roleRepPromptFormTemplateQueryArg = (
    mode: 'contributor' | 'candidate',
    token: string,
    qc: QueryClient,
) => ({
    ...roleRepPromptFormTemplatesQueryArg(mode),
    queryKey: ['role_rep_prompt_form_templates', mode, token],

    select: (data?: RoleRepPromptFormTemplate[]) => data?.find(f => f.token === token) ?? null,
})
export const useRoleRepPromptFormTemplates = (mode: 'contributor' | 'candidate' | 'all') => {
    return useQuery(roleRepPromptFormTemplatesQueryArg(mode))
}

export const useUpdateRoleRepPromptFormTemplateIntroVideoMutation = () => {
    const queryClient = useQueryClient()
    const _update = async (token: string, video: File | null) => {
        const form = new FormData()
        form.append('video', video || '')
        const res = await axios.post(`/api/update_rrpft_intro_video/${token}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return res.data
    }
    const mutation = useMutation({
        mutationFn: ({ token, video }: { token: string; video: File | null }) =>
            _update(token, video),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['role_rep_prompt_form_templates'],
            })
        },
    })
    return mutation
}

export const usePromptFormOfCRQ = (crqToken: string) => {
    const _get = async (crqToken: string) => {
        const res = await axios.get<{ form: RoleRepPromptFormTemplate }>(
            '/api/prompt_form_of_crq/' + crqToken,
        )
        return res.data.form
    }

    const query = useQuery({
        queryKey: ['role_rep_prompt_form_templates', crqToken],
        queryFn: () => _get(crqToken),
        enabled: !!crqToken,
    })
    return query
}
