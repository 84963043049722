import React, { lazy, Suspense } from 'react'
import { CollectionSettingsProps } from './CollectionSettings'

const LazyCollectionSettings = lazy(() => import('./CollectionSettings'))

const CollectionSettings = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CollectionSettingsProps,
) => (
    <Suspense fallback={null}>
        <LazyCollectionSettings {...props} />
    </Suspense>
)

export default CollectionSettings
