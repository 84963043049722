import React, { lazy } from 'react'
import { JobPostingsProps } from './JobPostings'
const LazyJobPostings = lazy(() => import('./JobPostings'))

const JobPostings = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & JobPostingsProps,
) => <LazyJobPostings {...props} />

export default JobPostings
