import styled, { css } from 'styled-components'
import { Menu } from 'antd'

export const INACTIVE_MENU_ITEM_COLOR = '#9ca0ab'

export const HeaderIcon = styled.div<{ $active?: boolean; $staffOnly?: boolean }>`
    color: ${INACTIVE_MENU_ITEM_COLOR};
    cursor: pointer;
    &:hover {
        color: black;
    }
    ${({ $active }) =>
        $active &&
        css`
            color: black;
        `}
    ${({ $staffOnly }) =>
        $staffOnly &&
        css`
            color: yellowgreen;
            &:hover {
                color: yellowgreen;
            }
        `}
    & svg {
        transform: scale(0.75);
        color: ${INACTIVE_MENU_ITEM_COLOR};
        :hover {
            color: black;
        }
        ${({ $staffOnly }) =>
            $staffOnly &&
            css`
                color: yellowgreen;
                &:hover {
                    color: yellowgreen;
                }
            `}
        ${({ $active }) =>
            $active &&
            css`
                color: black;
            `}
    }
`
// Hack to make it have the alternative style on a set of given route; Ideally, this would happen as a prop,
// but that implies changing the component structure quite a bit, so I'm solving it like this in the interest of time.

export const CustomMenu = styled(Menu)`
    height: 61px;
    line-height: 100%;
    align-items: center;
    border-bottom: none;
    li.ant-menu-item {
        padding-left: 10px !important;
        padding-right: 10px !important;
        color: ${INACTIVE_MENU_ITEM_COLOR} !important;
    }
    li.ant-menu-item-staff {
        color: yellowgreen !important;
    }
    li.ant-menu-item-selected {
        color: black !important;
    }
    li.ant-menu-item:hover {
        color: black !important;
        border-bottom-color: black !important;
    }
    li.ant-menu-item:after {
        content: none;
        display: none;
    }
`
