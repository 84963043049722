import React, { lazy } from 'react'
import { InterviewDetailsSettingsProps } from './InterviewDetailsSettings'
const LazyInterviewDetailsSettings = lazy(() => import('./InterviewDetailsSettings'))

const InterviewDetailsSettings = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsSettingsProps,
) => <LazyInterviewDetailsSettings {...props} />

export default InterviewDetailsSettings
