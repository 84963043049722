import axios from 'axios'
import {
    useQuery,
    UseQueryOptions,
    useMutation,
    useQueryClient,
    useSuspenseQuery,
} from '@tanstack/react-query'
import { CandidateDeliveryMethods, PaginatedResult } from 'services/role_search_service'
import { PDLEnrichedCompanyResponse } from 'services/icp_service'
import { ContentItem } from './artifact_service'
import { DateTime } from 'luxon'
import { CandidateContactStatus } from './job_board_candidate_service'
import { AsyncAccountLimits } from './billing_service'
import { OnboardingSteps } from 'components/SelfSignupOnboardingChecklist/SelfSignupOnboardingChecklist'

export type CustomDomainRecord = {
    url: string
    verified?: boolean | 'DNE'
}
export interface CompanyBranding {
    accentColor?: string
    accentColorCandidates?: string[]
    feedBackgroundColor?: string
    careersSiteIntroText?: string
    careersSiteTitle?: string
    careersSiteIntroTextCandidates?: string[]
    companyUrl?: string
    customDomains?: CustomDomainRecord[]
    icon?: string
    employeeCount?: string
    additionalDefaultSources?: { name: string; slug: string }[]
    removeWednesdayBranding?: boolean
    logoBackdropConfig?: {
        color?: string
    }
}
export interface BrandTemplate {
    token: string
    name: string
    branding: CompanyBranding
    logo_url?: string
    banner_url?: string
    archived?: boolean
}
export interface WatermarkSettings {
    enabled?: boolean
    width?: number
    Opacity?: number
    Duration?: number
    FadeIn?: number
    position?: 'tl' | 'tr' | 'br' | 'bl' | 'custom'
    top?: number
    right?: number
    bottom?: number
    left?: number
}
export enum CompanyStatus {
    SALES_DEMO = 1,
    INTERNAL_ACCOUNT = 2,
    RED = 3,
    YELLOW = 4,
    GREEN = 5,
}

export const getCompanyStatusName = (potentialEnumVal: string | CompanyStatus) => {
    const res = CompanyStatus[potentialEnumVal as any as CompanyStatus] // TODO audit all callers to make them pass a proper CompanyStatus to begin with
    // const res = Object.entries(CompanyStatus).find(
    //     ([stringVal, stringName]) => stringVal === potentialEnumVal,
    // ) as [string, string]
    return (res ?? 'Unknown Status')
        .split('_')
        .map(w => w[0] + w.slice(1).toLowerCase())
        .join(' ')
}

export const _WTProductNames = ['jobBoard', 'saas'] as const
export type WTProductNames = (typeof _WTProductNames)[number]

interface JBPriceComponent {
    use: boolean
    tiers: { low: number; high: number; pricePer: number }[]
}

export interface CompanyDetails {
    name: string
    slug_name?: string
    show_initial_setup_screen?: boolean
    branding?: CompanyBranding
    watermark_settings?: WatermarkSettings
    candidate_delivery_methods: CandidateDeliveryMethods
    token: string
    pdl_info?: PDLEnrichedCompanyResponse
    account_launched: string // iso date
    onboarding_completed: boolean
    billing_info: {
        stripe_customer_id?: string
        subscription_id?: string
        free_responses_per_month?: number

        // anything below here is deprecated
        stripe_price_id?: string
        stripe_payment_intent?: string
        stripe_payment_method_confirmed?: 'pending' | 'confirmed'
        // end stripe deprecated

        jobBoardPriceConfig?: {
            applicants: JBPriceComponent
            contactRequests: JBPriceComponent
            intros: JBPriceComponent
            // jobPosts?  live job posts? we would need to track when they were live better, then?
            uniquePerson: boolean // ie if a person applies to 2 jobs, are you charged for 1 person or for 2 applications.  true means 1, false means 2
        }
    }
    settings: {
        channelsTableColumns?: string[] | null
        channelGoalsByMonth?: Record<string, number> // string is iso _date_, 20xx-yy-zz
        hiddenTabNames?: string[]
        meaningfulQueryParamsForWidgetMapper?: string[]
        allowedTopics?: string[]
        firstTimeIntro?: string
        widgetFallbackUrl?: string
        widgetFallbackPatterns?: { pattern: string; url: string }[]
        usersCanTogglesidebar?: boolean
        sidebarDefaultOnForUsers?: boolean
        forceLayout202409?: boolean
        activeBetaTabs?: string[]
        selfSignup?: boolean
        wtvaDisclaimerText?: string
    }
    onboarding_steps_completed?: (typeof OnboardingSteps)[number]['key'][]
    status: CompanyStatus
    status_last_updated: string // iso string
    account_limit_info: {
        rrrvMaxActive?: number
        rrrvActive?: number
        products?: WTProductNames[]
    } & Partial<AsyncAccountLimits>
    info?: {
        company_name?: string
        url?: string
        icon?: string
        employeeCount?: string
        description?: string
    }
    virtual_backgrounds?: string[]
}
export const ACCOUNT_TIERS = ['free', undefined]
export type AccountTiers = (typeof ACCOUNT_TIERS)[number]
export interface Company extends Omit<CompanyDetails, 'account_limit_info'> {
    created?: string
    rs_active?: number
    rs_total?: number
    user_total?: number
    user_loggedin?: number
    last_login?: string
    expiration_datetime?: string
    campaign_total: number
    recent_candidates: number
    account_tier?: AccountTiers
    account_limits: {
        products?: WTProductNames[]
    }
}

export const useCompanyListQuery = <T extends keyof Company>(fields: T[]) => {
    const query = useQuery({
        queryKey: ['companyList', fields],

        queryFn: async () => {
            const res = await axios.get<Pick<Company, T>[]>('/api/company', {
                params: { fields: fields.join(',') },
            })
            return res.data
        },
    })
    return query
}

export const addCompany = async (company: { name: string }) => {
    const res = await axios.post<Company>('/api/company', company)
    return res.data
}

export const updateCompany = async (
    companyPatch: Partial<Company>,
    companyToken: string | undefined,
) => {
    const res = await axios.patch<CompanyDetails>(
        `/api/company_details/${companyToken}`,
        companyPatch,
    )
    return res.data
}
export const useUpdateCompanyMutation = (token: string) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (companyPatch: Partial<CompanyDetails> | Partial<Company>) =>
            updateCompany(companyPatch, token),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['company_details_for_company'],
            })
            await queryClient.cancelQueries({
                queryKey: ['hudPageData'],
            })
            await queryClient.cancelQueries({
                queryKey: ['companyList'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['company_details_for_company'],
            })
            queryClient.invalidateQueries({
                queryKey: ['hudPageData'],
            })
            queryClient.invalidateQueries({
                queryKey: ['companyList'],
            })
        },
    })
    return mutation
}

type UpdateSpecificVirtualBackgroundArgs = { form: FormData } | { url: string }

export const useUpdateSpecificVirtualBackground = () => {
    const queryClient = useQueryClient()
    const _send = async (arg: UpdateSpecificVirtualBackgroundArgs) => {
        if ((arg as { form: FormData })?.form) {
            const formData = (arg as { form: FormData }).form
            const res = await axios.post('/api/update_specific_virtual_background', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            return res.data
        } else {
            const url = (arg as { url: string }).url
            const res = await axios.delete('/api/update_specific_virtual_background', {
                data: { imageToDelete: url }, // delete doesnt have a nice shortcut way of passing a body
            })
            return res.data
        }
    }
    const mutation = useMutation({
        mutationFn: (arg: UpdateSpecificVirtualBackgroundArgs) => _send(arg),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['company_details_for_company'],
            })
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['company_details_for_company'],
            })
        },
    })
    return mutation
}

export const useSimilarCompanyNames = (token: string) => {
    const _get = async () => {
        const res = await axios.get<{ similarNames: string[] }>('/api/find_similar_names/' + token)
        return res.data.similarNames
    }

    const query = useQuery({
        queryKey: ['similarCompanyNames', token],
        queryFn: _get,
    })
    return query
}

const getCompanyDetails = async (token?: string) => {
    // can pass a token if backstage.  backend checks that you are staff
    const res = await axios.get<CompanyDetails>('/api/company_details' + (token ? `/${token}` : ''))
    return res.data
}

export const generateCareersSite = async () =>
    // accentColor?: string,
    // careersSiteIntroText?: string,
    // additionalDefaultSources?: { name: string; slug: string }[],
    // removeWednesdayBranding?: boolean,
    {
        const res = await axios.get('/api/generate_careers_site_data')
        return res.data
    }

export interface AnonymousCompanyInfo {
    logo: string
    name: string
    unverified: boolean
    pageColor?: string
    accentColor?: string
    removeWednesdayBranding?: boolean
    jobBoardCompany: boolean
    disclaimerText?: string
    extraInfo?: {
        companyInfo?: {
            accentColor?: string
            url?: string
            icon?: string
            description?: string
            employeeCount?: string
        }
        roleSearchInfo?: {
            description?: string
            location?: string
            experienceLevel?: string
            name?: string
            compensation?: string
        }
    }
    tags?: string[]
}

export const useCompanyDetailsSuspense = (enabled?: boolean) => {
    const spoofCompanyId = useQuery<string | null>({ queryKey: ['spoofCompanyId'] }).data
    const query = useSuspenseQuery({
        queryKey: ['company_details_for_company', enabled ? spoofCompanyId : 'disabled'],
        queryFn: () => (enabled ? getCompanyDetails() : null),
    })
    return query
}
export const useCompanyDetails = ({
    enabled,
    token,
}: { token?: string } & Partial<UseQueryOptions> = {}) => {
    const query = useQuery({
        queryKey: ['company_details_for_company', token],
        queryFn: () => getCompanyDetails(token),
        enabled,
    })
    return query
}

export const useCompanyInfoAnonymous = (promptFormToken: string) => {
    const _get = async (promptFormToken: string) => {
        const res = await axios.get<AnonymousCompanyInfo>(
            `/api/get_company_info_anonymous/${promptFormToken}`,
        )
        return res.data
    }

    const query = useQuery({
        queryKey: ['company_info_anonymous', promptFormToken],
        queryFn: () => _get(promptFormToken),
        enabled: !!promptFormToken,
    })
    return query
}

export type LogoVariants = 'darkbackground' | 'watermark' | 'banner'
export const getLogoApiPath = (brandTemplateToken?: string, variant?: LogoVariants) => {
    const FieldNameByVariant: Record<LogoVariants, string> = {
        darkbackground: 'dark_background_logo',
        watermark: 'watermark_logo',
        banner: 'banner',
    }
    return `/api/company_logo/${brandTemplateToken ? brandTemplateToken + '/' : ''}${
        variant ? FieldNameByVariant[variant] : ''
    }`
    // return brandTemplateToken
    //     ? `/api/company_logo/${brandTemplateToken}/${variant ? FieldNameByVariant[variant] : ''}`
    //     : variant === 'darkbackground'
    //     ? '/api/company_logo_dark_background'
    //     : variant === 'watermark'
    //     ? 'api/company_logo_watermark'
    //     : '/api/company_logo'
}
export const useCompanyLogo = (variant?: LogoVariants, brandTemplateToken?: string) => {
    const { data: companyDetails, status: cdStatus } = useCompanyDetails()
    const query = useQuery({
        queryKey: ['companyLogo', companyDetails?.slug_name, brandTemplateToken, variant],

        queryFn: async () => {
            const result = await axios.get<{ url: string }>(
                getLogoApiPath(brandTemplateToken, variant),
            )
            return result.data.url
        },

        enabled: cdStatus === 'success',
    })
    return query.data
}

export const useCompanyIntroVideo = () => {
    const query = useQuery({
        queryKey: ['companyIntroVideo'],

        queryFn: async () => {
            const result = await axios.get<{ url: string }>('/api/company_intro_video')
            return result.data.url
        },
    })
    return query.data
}

interface HUDPageData {
    crqTimeSeriesInfo: {
        created: string
        companyName: string
        uploaded: string | null
        invited: string | null
    }[]
    companyInfo: Record<number, string[]>
    crqInfo: Record<string, { uploaded: number; total: number; invited: number }>
}
const getHUDPageData = async () => {
    const res = await axios.get<HUDPageData>('/api/get_hud_data')
    return res.data
}

export const useHUDPageStats = () => {
    const query = useQuery({
        queryKey: ['hudPageData'],
        queryFn: getHUDPageData,
    })
    return query
}

export const useJBInvoiceData = (dateStart: string, dateEnd: string, uniquePeople: boolean) => {
    const _get = async () => {
        const res = await axios.post<{
            applicants: {
                crq__token: string
                created: string
                status: CandidateContactStatus
                contacted_for__name: string
                contacted_for__token: string
            }[]
            contactRequests: {
                crq__token: string
                created: string
                status: CandidateContactStatus
                contacted_for__name: string | null
                contacted_for__token: string | null
            }[]
            intros: {
                crq__token: string
                responded_time: string
                status: CandidateContactStatus
                contacted_for__token: string | null
                contacted_for__name: string | null
            }[]
        }>('/jb_api/get_jb_invoice_data', {
            dateStart,
            dateEnd,
            uniquePeople,
        })
        return res.data
    }

    const query = useQuery({
        queryKey: ['invoiceHelper', dateStart, dateEnd, uniquePeople],
        enabled: !!dateStart && !!dateEnd,
        queryFn: _get,
    })
    return query
}

export const useLoadSalesData = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (mode: 'candidates' | 'contributors') => {
            const countArtifacts = async () => {
                return (
                    await axios.get<PaginatedResult<ContentItem>>('/api/artifacts', {
                        params: {
                            page_size: 1,
                        },
                    })
                ).data.count
            }
            const initialCount = await countArtifacts()
            await axios.post<{}>('/api/load_sales_data', { mode })
            let curCount = initialCount
            for (const i of new Array(10)) {
                await new Promise(resolve => setTimeout(resolve, 2000))
                curCount = await countArtifacts()
                if (curCount > initialCount) {
                    break
                }
            }
        },
        onSettled: () => {
            return Promise.allSettled([
                queryClient.invalidateQueries({
                    queryKey: ['contentList'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['artifacts'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['roleReps'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['contributors'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['role_rep_response_library'],
                }),
            ])
        },
    })
}

export const useStripeClientSecret = (enabled: boolean) => {
    const _get = async () => {
        const res = await axios.get<{ client_secret: string }>('/auth/get_stripe_client_secret')
        return res.data.client_secret || ''
    }
    const query = useQuery({
        queryKey: ['stripe_client_secret'],
        queryFn: _get,
        enabled: enabled,
    })
    return query
}
