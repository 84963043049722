import React, { lazy, Suspense } from 'react'
import { ContributorsLibraryProps } from './ContributorsLibrary'
const LazyContributorsLibrary = lazy(() => import('./ContributorsLibrary'))

export const ContributorsLibrary = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ContributorsLibraryProps,
) => (
    <Suspense fallback={null}>
        <LazyContributorsLibrary {...props} />
    </Suspense>
)
