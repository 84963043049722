import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { UserInfo } from './user_service'
import { AnonymousCompanyInfo } from './company_service'
import { RoleRep } from './role_rep_service'
import { RoleRepPromptQuestionQType } from './role_rep_prompt_service'
import { useMemo } from 'react'
import { getColorByBgColor } from './utils/CommonUtils'
import { DEFAULT_ACCENT_COLOR } from 'pages/Settings/pages/Branding/useBrandTemplates'
import { publicShowcaseQueryArg } from './publicShowcaseQueryArg'
import { getNewSessionExpirationTime, getNewSessionId } from './session_utils'
import { createdShowcaseEvent } from './intercom_event_tracking'

type ShowcaseCreator = Pick<UserInfo, 'email' | 'friendly_name'>

export enum ShowcaseEvent {
    VISIT_SHOWCASE = 1,
    MAIN_CTA = 2,

    VIEW_CANDIDATE_DETAIL = 3,
    FAVORITE_CANDIDATE = 4,
    CANDIDATE_CTA = 5,

    SELECTED_QUESTION_FILTER = 6,

    STARTED_VIDEO = 7,
    FINISHED_VIDEO = 8,
}

interface PublicShowcaseRoleRep {
    title: string
    name: string
    friendly_name: string
    token: string
    tags: string[]
    candidate_token: string
    info: RoleRep['info']
    email: string // its hard overridden to '' in the backend
}

export interface CandidateShowcase {
    name: string
    token: string
    num_candidates: number
    is_active: boolean
    creator?: ShowcaseCreator
    created: string
}

export interface ShowcaseQuestion {
    token: string
    prompt: string
    descr?: string
    topic?: string
    subQuestions?: ShowcaseQuestion[]
    qtype: RoleRepPromptQuestionQType
}

export const showcaseReplaceTemplateVars = (candidate: ShowcaseCandidate, text: string) => {
    return text
        .replaceAll('*|candidateName|*', candidate.name)
        .replaceAll('*|candidateToken|*', candidate.crqToken)
        .replaceAll('*|candidateEmail|*', candidate.email)
}

type ShowcaseFormat = 'compare' | 'highlight'

export interface CandidateShowcaseDetails extends CandidateShowcase {
    crqs: RoleRep[]
    info: {
        CTAConfig?: {
            enabled?: boolean
            positiveText?: string
            positiveLink?: string
            negativeText?: string
            negativeLink?: string
            globalCTAText?: string
            globalCTALink?: string
        }
        candidateInfoByToken: Record<
            string,
            {
                notesByQToken?: Record<string, string>
                note?: string
                promptTokenBlacklist?: string[]
                cta?: {
                    displayText: string
                    url?: string // possibly we'll allow for templating cta url, so this doesnt _have_ to exist always
                }
            }
        >
        promptQuestions: ShowcaseQuestion[]
        promptTokenBlackList: string[]
        description?: string
        requireLogin?: boolean
        marketingMode?: boolean // hides email of candidates and disabled comments in published showcase
        format?: ShowcaseFormat
    }
    videosByCRQToken: Record<string, ShowcaseVideo[]>
}
export interface ShowcaseVideo {
    url: string
    token: string
    prompt: string
    name: string
    email: string
    crqToken: string
    questionToken: string
}
export interface PublicCandidateShowcaseDetails extends Omit<CandidateShowcaseDetails, 'crqs'> {
    companyInfo: AnonymousCompanyInfo
    crqs: PublicShowcaseRoleRep[]
}
export interface ShowcaseCandidate {
    name: string
    crqToken: string
    email: string
    info: PublicCandidateShowcaseDetails['info']['candidateInfoByToken'][string]
    videosByQToken: Record<string, ShowcaseVideo>
    textResponsesByQToken: Record<string, string | string[]>
    globalInfo: {
        summary?: string
        tagline?: string
        tags?: string[]
        justification?: string
        title?: string
    }
}

export const useShowcaseBranding = (showcase: PublicCandidateShowcaseDetails) => {
    const getBranding = (showcase: PublicCandidateShowcaseDetails) => {
        const companyInfo = showcase.companyInfo
        const pageColor = companyInfo?.pageColor || '#ffffff'
        const contrastColor = getColorByBgColor(pageColor)
        const accentColor = companyInfo.accentColor || DEFAULT_ACCENT_COLOR
        const accentContrastColor = getColorByBgColor(accentColor)
        const contrastColorMix = (opacity: number) => {
            const rC = parseInt(contrastColor.slice(1, 3), 16)
            const gC = parseInt(contrastColor.slice(3, 5), 16)
            const bC = parseInt(contrastColor.slice(5, 7), 16)

            const rP = parseInt(pageColor.slice(1, 3), 16)
            const gP = parseInt(pageColor.slice(3, 5), 16)
            const bP = parseInt(pageColor.slice(5, 7), 16)

            const r = rC * opacity + rP * (1 - opacity)
            const g = gC * opacity + gP * (1 - opacity)
            const b = bC * opacity + bP * (1 - opacity)

            // calculate what the color would be if the contrast color was given 20% opacity on top of page color
            // i would just use regular opacity but then when you hover over a row, the sticky column because translucent and you see the scrolled things under it

            return `rgb(${r}, ${g}, ${b})`
        }

        return {
            pageColor,
            contrastColor,
            contrastColorMix,
            accentColor,
            accentContrastColor,
        }
    }
    const branding = useMemo(() => getBranding(showcase), [showcase])
    return branding
}

export const useShowcaseCandidates = (
    showcase: PublicCandidateShowcaseDetails | CandidateShowcaseDetails,
) => useMemo(() => getShowcaseCandidates(showcase), [showcase])
export const getShowcaseCandidates = (
    showcase: PublicCandidateShowcaseDetails | CandidateShowcaseDetails,
) => {
    const candidates: ShowcaseCandidate[] = [...showcase.crqs].map(c => ({
        name: c.friendly_name,
        crqToken: c.token,
        email: c.email,
        globalInfo: {
            summary: c.info?.showcaseSummary,
            tagline: c.info?.showcaseTagline,
            justification: c.info?.showcaseJustification,
            tags: c?.tags,
            title: c?.title,
        },
        textResponsesByQToken: Object.fromEntries(
            Object.entries(c.info.answers ?? {}).filter(([token, response]) => {
                const blackListedQuestions = showcase.info.promptQuestions.filter(
                    q =>
                        showcase.info.promptTokenBlackList.includes(q.token) ||
                        showcase.info.candidateInfoByToken?.[
                            c.token
                        ]?.promptTokenBlacklist?.includes(q.token),
                )
                const blacklistedSubQuestions = blackListedQuestions.flatMap(
                    q => q?.subQuestions?.map(q_ => q_.token) ?? [],
                )
                return !blacklistedSubQuestions.includes(token)
            }),
        ),
        videosByQToken: Object.fromEntries(
            (showcase.videosByCRQToken?.[c.token] ?? [])
                .map(v => [v.questionToken, v])
                .filter(
                    ([token, v]) =>
                        !showcase.info.promptTokenBlackList.includes(
                            (v as ShowcaseVideo).questionToken,
                        ) &&
                        !showcase.info.candidateInfoByToken?.[
                            c.token
                        ]?.promptTokenBlacklist?.includes((v as ShowcaseVideo).questionToken),
                ),
        ),
        info: showcase.info.candidateInfoByToken?.[c.token] ?? {},
    }))
    return candidates
}

export const questionIsUsed = (
    showcase: CandidateShowcaseDetails | PublicCandidateShowcaseDetails,
    candidates: ShowcaseCandidate[],
    question: ShowcaseQuestion,
) => {
    let isUsed = false
    const subQuestionTokens = question?.subQuestions?.map(q => q.token) ?? []
    const tokens = [question.token, ...subQuestionTokens]
    candidates.forEach(c => {
        const video = tokens.some(t => !!c.videosByQToken[t])
        const text = tokens.some(t => !!c.textResponsesByQToken[t])
        const blacklisted = showcase.info.candidateInfoByToken?.[
            c.crqToken
        ]?.promptTokenBlacklist?.includes(question.token)

        if (!blacklisted && (video || text)) {
            isUsed = true
        }
    })
    return isUsed
}

export interface PublicCandidateShowcaseNotAllowedDetails {
    is_active: boolean
    token: string
    require_login: boolean
}

export const useShowcases = (programToken?: string) => {
    const _get = async () => {
        const res = await axios.get<CandidateShowcase[]>(
            '/api/candidate_showcase' + (programToken ? `/${programToken}` : ''),
        )
        return res.data
    }

    const query = useQuery({ queryKey: ['showcases', programToken], queryFn: _get })
    return query
}

export const showcaseDetailQueryArg = (token?: string) => ({
    queryFn: async () => {
        const res = await axios.get<CandidateShowcaseDetails>('/api/candidate_showcase/' + token)
        return res.data
    },
    queryKey: ['showcases', token],
    enabled: !!token,
})
export const useShowcase = (token: string) => {
    return useQuery(showcaseDetailQueryArg(token))
}

export const useShowcasePublic = (token: string) => {
    return useQuery(publicShowcaseQueryArg(token))
}

interface CreateShowcaseType {
    name: string
    crqTokens: string[]
}

export const useCreateShowcase = () => {
    const _post = async (showcaseData: CreateShowcaseType) => {
        const res = await axios.post<CandidateShowcase>('/api/candidate_showcase', showcaseData)
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (showcaseData: CreateShowcaseType) => _post(showcaseData),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['showcases'],
            })
        },
        onSuccess: showcaseData => {
            createdShowcaseEvent(showcaseData.token)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['showcases'],
            })
        },
    })
    return mutation
}

export const useUpdateShowcase = () => {
    const _patch = async (
        token: string,
        patch: Partial<Omit<CandidateShowcaseDetails, 'videos'> & { videos: string[] }>,
    ) => {
        const res = await axios.patch<CandidateShowcaseDetails>(
            '/api/candidate_showcase/' + token,
            patch,
        )
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            token,
            patch,
        }: {
            token: string
            patch: Partial<Omit<CandidateShowcaseDetails, 'videos'> & { videos: string[] }>
        }) => _patch(token, patch),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['showcases'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['showcases'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export const useAddShowcaseContributors = () => {
    const _post = async (token: string, contributorTokens: string[]) => {
        const res = await axios.post('/api/add_showcase_contributors/' + token, {
            tokens: contributorTokens,
        })
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            token,
            contributorTokens,
        }: {
            token: string
            contributorTokens: string[]
        }) => _post(token, contributorTokens),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['showcases'],
            })
            await queryClient.cancelQueries({
                queryKey: ['roleReps'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['showcases'],
            })
            queryClient.invalidateQueries({
                queryKey: ['roleReps'],
            })
        },
    })
    return mutation
}

export const logShowcaseEvent = (
    showcaseToken: string,
    eventType: ShowcaseEvent,
    associatedItemToken?: string,
) => {
    const searchParams = new URLSearchParams(window.location.search)

    if (window?.sessionIdentifier?.includes('1qn5x9014vq4h11phms3w6nkm9i')) {
        // this seems to be the id some bot generates, by having a fake implementation of js RNG
        return
    }
    if (searchParams.get('noLog')) {
        return
    }

    if (!window?.sessionExpirationTime) {
        window.sessionExpirationTime = getNewSessionExpirationTime()
    }
    const now = new Date()
    if (window.sessionExpirationTime < now || !window.sessionIdentifier) {
        window.sessionIdentifier = getNewSessionId()
    }
    window.sessionExpirationTime = getNewSessionExpirationTime()

    const url = (import.meta.env.VITE_API_URL_BASE || '') + '/api/showcase_event'
    const data = {
        sessionIdentifier: window.sessionIdentifier,
        showcaseToken,
        eventType,
        associatedItemToken,
        source: searchParams.get('s') || undefined,
    }
    if (navigator.sendBeacon) {
        navigator.sendBeacon(url, JSON.stringify(data))
    } else {
        axios.post(url, data)
    }
}

export const useCreateCommentFromShowcase = () => {
    const _post = async (
        showcaseToken: string,
        comment: string,
        itemToken: string,
        commenterName?: string,
    ) => {
        const res = await axios.post('/api/create_comment_from_showcase/' + showcaseToken, {
            comment,
            itemToken,
            commenterName,
        })
        return res.data
    }

    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            showcaseToken,
            comment,
            itemToken,
            commenterName,
        }: {
            showcaseToken: string
            comment: string
            itemToken: string
            commenterName?: string
        }) => _post(showcaseToken, comment, itemToken, commenterName),

        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['comments'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['comments'],
            })
        },
    })
    return mutation
}
