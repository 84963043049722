import React, { lazy, Suspense } from 'react'
import { CandidatesListProps } from './CandidatesList'
const LazyCandidatesList = lazy(() => import('./CandidatesList'))

const CandidatesList = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & CandidatesListProps,
) => (
    <Suspense fallback={null}>
        <LazyCandidatesList {...props} />
    </Suspense>
)

export default CandidatesList
