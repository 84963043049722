import React, { lazy, Suspense } from 'react'
import { _ContributorsProps, ContributorsProps, RoleRepPromptFormPaneProps } from './Contributors'
const LazyContributors = lazy(() => import('./Contributors'))

const Contributors = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ContributorsProps,
) => (
    <Suspense fallback={null}>
        <LazyContributors {...props} />
    </Suspense>
)

export default Contributors

const LazyRoleRepPromptFormPane = lazy(() =>
    import('./Contributors').then(m => ({ default: m.RoleRepPromptFormPane })),
)

export const RoleRepPromptFormPane = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & RoleRepPromptFormPaneProps,
) => (
    <Suspense fallback={null}>
        <LazyRoleRepPromptFormPane {...props} />
    </Suspense>
)

const Lazy_Contributors = lazy(() =>
    import('./Contributors').then(m => ({ default: m._Contributors })),
)

export const _Contributors = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & _ContributorsProps,
) => (
    <Suspense fallback={null}>
        <Lazy_Contributors {...props} />
    </Suspense>
)
