import React, { lazy, Suspense } from 'react'
import { PublishedContentDetailsProps } from './PublishedContentDetails'

const LazyPublishedContentDetails = lazy(() => import('./PublishedContentDetails'))

const PublishedContentDetails = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PublishedContentDetailsProps,
) => (
    <Suspense fallback={null}>
        <LazyPublishedContentDetails {...props} />
    </Suspense>
)

export default PublishedContentDetails
