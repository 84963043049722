import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { UserInfo } from 'services/user_service'
import { useParams } from 'react-router-dom'
import {
    ExternalService,
    ImportableJobInfo,
    JobListJob,
} from 'services/external_integrations_service'

export const PROGRAM_TYPE_DEFAULT_OPTIONS = [
    'Employee Spotlight Series',
    'Role Channel',
    'Interview Prep',
    'Employee Testimonial',
    'Onboarding Channel',
    'Branding',
] as const
type ProgramOwner = Pick<UserInfo, 'email' | 'friendly_name'>

export interface Program {
    name: string
    token: string
    included_prompt_template_tokens?: string[]
    included_contribution_request_tokens: string[]
    included_collection_tokens: string[]
    included_video_tokens: string[]
    owner?: ProgramOwner
    created: string
    program_type: string
    options: {}
    linked_external_integrations: Record<
        ExternalService['id'],
        { url: string; job_id: { job_id: string; internal_id: string } }[]
    >
    linked_external_job_details?: JobListJob[]
}
export const isProgram = (item: any): item is Program => !!item?.token?.startsWith('PR_')

export const usePrograms = (templateName?: string) => {
    const _get = async () => {
        const res = await axios.get<Program[]>(
            '/api/programs' + (templateName ? `/${templateName}` : ''),
        )
        return res.data
    }
    const query = useQuery({
        queryKey: ['programs', templateName ?? 'all'],
        queryFn: () => _get(),
    })
    return query
}

export const programQueryArg = (token?: string) => ({
    
        queryKey: ['programs', token],
        queryFn: async () => {
            return (await axios.get<Program>('/api/programs/' + (token ? token : ''))).data
        },
})
export const useProgram = (token?: string) => {
    const { programToken: urlToken } = useParams()
    const tokenToUse = token || urlToken || ''
    return useQuery({...programQueryArg(tokenToUse), enabled: !!tokenToUse})
}

export type CreateProgramType = Pick<
    Program,
    'name' | 'owner' | 'included_prompt_template_tokens' | 'options'
> & { linkedJobInfo?: ImportableJobInfo }

export const useCreateProgram = () => {
    const _post = async (initialProgram: CreateProgramType) => {
        const res = await axios.post<Program>('/api/programs', { ...initialProgram })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (initialProgram: CreateProgramType) => _post(initialProgram),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['programs'],
            })
            await queryClient.cancelQueries({
                queryKey: ['availableProgramFilters'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['programs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['availableProgramFilters'],
            })
        },
    })
    return mutation
}

export const useAvailableProgramFilters = () => {
    const _get = async () => {
        const res = await axios.get<Record<string, { value: string; label: string }[]>>(
            '/api/get_program_filters',
        )
        return res.data
    }

    const query = useQuery({
        queryKey: ['availableProgramFilters'],
        queryFn: _get,
    })
    return query
}

export const useUpdateProgram = () => {
    const _patch = async (token: string, patch: Partial<Program>) => {
        const res = await axios.patch<Program>('/api/programs/' + token, patch)
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({ token, patch }: { token: string; patch: Partial<Program> }) =>
            _patch(token, patch),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['programs'],
            })
            await queryClient.cancelQueries({
                queryKey: ['availableProgramFilters'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['programs'],
            })
            queryClient.invalidateQueries({
                queryKey: ['availableProgramFilters'],
            })
        },
    })
    return mutation
}
