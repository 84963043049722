import React, { Suspense, lazy } from 'react'
import { AsyncJobPostingSettingsProps } from './AsyncJobPostingSettings'
const LazyAsyncJobPostingSettings = lazy(() => import('./AsyncJobPostingSettings'))

const AsyncJobPostingSettings = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AsyncJobPostingSettingsProps,
) => (
    <Suspense fallback={<div>ok</div>}>
        <LazyAsyncJobPostingSettings {...props} />
    </Suspense>
)

export default AsyncJobPostingSettings
