import React, { lazy, Suspense } from 'react'
import {
    AddContributionRequestsModalProps,
    CopyDirectLinkButtonProps,
    OutboxInnerProps,
    OutboxProps,
    OutboxResponseWrapperProps,
    OutboxTablePromptFormProps,
} from './Outbox'

const LazyOutbox = lazy(() => import('./Outbox'))

const Outbox = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & OutboxProps) => (
    <LazyOutbox {...props} />
)

export default Outbox

const LazyOutboxResponseWrapper = lazy(() =>
    import('./Outbox').then(m => ({ default: m.OutboxResponseWrapper })),
)
export const OutboxResponseWrapper = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & OutboxResponseWrapperProps,
) => <LazyOutboxResponseWrapper {...props} />

const LazyOutboxInner = lazy(() => import('./Outbox').then(m => ({ default: m.OutboxInner })))
export const OutboxInner = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & OutboxInnerProps,
) => <LazyOutboxInner {...props} />

const LazyCRQTargets = lazy(() => import('./Outbox').then(m => ({ default: m.CRQTargets })))
export const CRQTargets = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
    <Suspense fallback={null}>
        <LazyCRQTargets {...props} />
    </Suspense>
)

const LazyCandidateDetails = lazy(() =>
    import('./Outbox').then(m => ({ default: m.CandidateDetails })),
)
export const CandidateDetails = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode },
) => (
    <Suspense fallback={null}>
        <LazyCandidateDetails {...props} />
    </Suspense>
)

const LazyAddContributionRequestModal = lazy(() =>
    import('./Outbox').then(m => ({ default: m.AddContributionRequestModal })),
)
export const AddContributionRequestModal = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AddContributionRequestsModalProps,
) => (
    <Suspense fallback={null}>
        <LazyAddContributionRequestModal {...props} />
    </Suspense>
)

const LazyCopyDirectLinkButon = lazy(() =>
    import('./Outbox').then(m => ({ default: m.CopyDirectLinkButton })),
)
export const CopyDirectLinkButton = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CopyDirectLinkButtonProps,
) => (
    <Suspense fallback={null}>
        <LazyCopyDirectLinkButon {...props} />
    </Suspense>
)

const LazyOutboxTablePromptForm = lazy(() =>
    import('./Outbox').then(m => ({ default: m.OutboxTablePromptForm })),
)
export const OutboxTablePromptForm = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & OutboxTablePromptFormProps,
) => (
    <Suspense fallback={null}>
        <LazyOutboxTablePromptForm {...props} />
    </Suspense>
)
