import axios from 'axios'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { CSSProperties } from 'react'
import { useParams } from 'react-router-dom'
import { RoleRepResponseVideo } from 'services/role_rep_response_video_service'
import { RoleSearch, PaginatedResult } from 'services/role_search_service'
import { storageDomain } from './BackstageUtils'
import { ExternalService, JobListJob } from 'services/external_integrations_service'
import { Contributor as ContributorRrs } from 'services/role_rep_service'

import * as batshit from '@yornaath/batshit'
import { CollectionDisplayOptions, CollectionAllowedFilterCategories } from './feed_services_consts'
import { NameOverrideType } from 'pages/PagesDetail/pages/PagesBuilder/PagesBuilder'
import { createChannelEvent, publishedChannelEvent } from './intercom_event_tracking'

export interface Feed {
    name: string
    token: string
    num_attached_items: number
    num_new_attached_items: number
    v2?: boolean
    description?: string // only can be filled out from new builder
    created: string
    programs: string[]
    location: string
    department: string
    team: string
    tags?: string[]
    v8?: boolean
    v16?: 'grid' | 'playlist' | 'grid-playlist'

    archived: boolean
    is_active: boolean
    active_since: string | null // isoformatted string, if it exists
    linked_external_integrations: Record<
        ExternalService['id'],
        { url: string; job_id: { job_id: string; internal_id: string } } | undefined
    >
}

export interface FeedChannelInfo {
    name: string
    token: string
    last_update: string
    poster_url: string
    team?: string
    location?: string
}

export interface FeedSection {
    name: string
    items: (RoleRepResponseVideo | FeedChannelInfo)[] // # TODO, soon this will be RRRV|RoleSearch, im just not sure what parts of RoleSearch we definitely need yet
    type: 'content' | 'fullWidth'
    key: string
    shortName?: string // (ie relationship betweeen contributor and job (e.g hiring manager/team lead/ceo) )
    contributor?: Contributor
}
interface Contributor {
    token: string
    name?: string
    title?: string
    additionalTitles?: string[]
    posterUrl?: string
}

export interface FeedDetails extends Feed {
    options: {
        featuredLinks?: { name: string; link: string }[]
        externalUrls?: string[]
        customWidgetCoverText?: string
        widgetMinimizedSize?: number
        widgetPositionLeft?: number
        widgetPositionRight?: number
        widgetPositionBottom?: number
        style?: (typeof CollectionDisplayOptions)[number]['value']
        allowedFilterCategories?: (typeof CollectionAllowedFilterCategories)[number][]
        allowSearch?: boolean
        showTOC?: boolean
        displayName?: boolean
        description?: string
        backgroundColor?: string
        pageBackgroundGradient?: boolean
        accentColor?: string
        externalCTA?: { url: string; text: string }
        displayText?: string
        isReel?: boolean
        page_config?: {
            ordered_sections?: FeedSection[]
            itemNameOverride?: NameOverrideType
            v4?: boolean
            v8?: boolean
            v16?: 'grid' | 'playlist' | 'grid-playlist'
        }
        logoBackdropConfig?: {
            color?: string
            blur?: number
        }
        logoConfig?: {
            height?: number
            flexDirection?: CSSProperties['flexDirection']
            alignItems?: CSSProperties['alignItems']
            justifyContent?: CSSProperties['justifyContent']
            gap?: CSSProperties['gap']
        }
        tagsToPublish?: string[]
        brand_template_token?: string
        layout?: 'page' | 'playlist'
        jobDetails?: JobDetails
        type?: string
        hideOnCareersSite?: boolean
        externalJobDetails?: JobListJob
    }
    logo_url: string
    banner_url: string
}
interface JobDetails {
    location?: string
    experienceLevel?: string
    compensation?: string
}
interface ItemInFeed {
    token: string
    role_search: RoleSearch | null
    video: RoleRepResponseVideo | null
}

const getFeeds = async (tokens?: string[]) => {
    const res = await axios.get<Feed[]>('/api/feed_configs', {
        params: { tokens: tokens?.join(','), page_size: 'null' },
    })
    return res.data
}
export const feedsQueryArg = (tokens?: string[], enabled?: boolean) => {
    return {
        queryKey: ['feeds', JSON.stringify((tokens ?? []).sort())],
        queryFn: () => getFeeds(tokens),
        enabled: enabled ?? true,
    }
}
export const useFeeds = (tokens?: string[], enabled?: boolean) =>
    useQuery(feedsQueryArg(tokens, enabled))

export const useFeedsPaged = (
    pageNumber: number,
    pageSize: number = 20,
    searchQuery?: string,
    filters?: Record<string, string | boolean>,
    options?: { enabled: boolean },
) => {
    const _get = async () => {
        const res = await axios.get<PaginatedResult<Feed>>('/api/feed_configs', {
            params: {
                page_size: pageSize,
                page: pageNumber,
                ...(filters ?? {}),
                search: searchQuery,
            },
        })
        return { data: res.data.results, totalNumFeeds: res.data.count }
    }

    const query = useQuery({
        queryKey: [
            'feeds',
            'paged',
            pageNumber,
            pageSize,
            JSON.stringify(Object.entries(filters ?? {}).sort()),
            searchQuery,
        ],

        queryFn: _get,
    })
    return query
}

export const updateFeed = async (
    feedToken: string,
    patch: Partial<Feed>,
    updateManifest: boolean | 'preview',
) => {
    const res = await axios.patch<FeedDetails>(
        `/api/feed_configs/${feedToken}?updateManifest=${updateManifest}`,
        patch,
    )
    return res.data
}

export const useUpdateAnyFeed = (updateManifest: boolean | 'preview') => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (patch: Partial<FeedDetails> & Pick<FeedDetails, 'token'>) =>
            updateFeed(patch.token, patch, updateManifest),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['feeds'],
            })
        },
    })
    return mutation
}
export const useUpdateFeedSection = (feedToken: string | undefined) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async ({
            addSection,
            deleteSection,
            patch,
            sectionOrder,
            orderItems,
        }: {
            addSection?: { contributor?: ContributorRrs; name?: string }
            deleteSection?: { sectionKey: string }
            patch?: { key: string } & Partial<Omit<FeedSection, 'items'>>
            sectionOrder?: string[]
            addItems?: { sectionKey: string; itemTokens: string[] }
            orderItems?: { sectionKey: string; itemTokens: string[] }
        }) => {
            if (!feedToken) {
                return null
            }
            if (addSection) {
                const { contributor, name } = addSection
                return (
                    await axios.post<FeedDetails>(`/api/feed_configs/${feedToken}/section`, {
                        name:
                            name ??
                            contributor?.friendly_name ??
                            contributor?.title ??
                            contributor?.email ??
                            'New Section',
                        contributor,
                    })
                ).data
            } else if (deleteSection) {
                return (
                    await axios.delete<FeedDetails>(
                        `/api/feed_configs/${feedToken}/section/${deleteSection.sectionKey}`,
                    )
                ).data
            } else if (patch) {
                return (
                    await axios.patch<FeedDetails>(
                        `/api/feed_configs/${feedToken}/section/${patch.key}`,
                        patch,
                    )
                ).data
            } else if (sectionOrder) {
                return (
                    await axios.put<FeedDetails>(
                        `/api/feed_configs/${feedToken}/section`,
                        sectionOrder,
                    )
                ).data
            } else if (orderItems) {
                return (
                    await axios.put<FeedDetails>(
                        `/api/feed_configs/${feedToken}/section/${orderItems.sectionKey}`,
                        orderItems.itemTokens,
                    )
                ).data
            }

            return null
        },
        onMutate: () =>
            queryClient.cancelQueries({
                queryKey: ['feeds'],
            }),
        onSettled: async fc => {
            if (fc) {
                await queryClient.setQueryData(['feeds', fc.token], fc)
            }
            await queryClient.invalidateQueries({
                queryKey: ['feeds'],
            }) // feels a little sad to invalidate even the one we may have just written nicely, but we've got some funny structure to these keys so there are a few different ones that may need invalidations
        },
    })
}
export const useUpdateFeed = (feedToken: string, updateManifest: boolean | 'preview') => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (patch: Partial<FeedDetails>) => updateFeed(feedToken, patch, updateManifest),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSuccess: () => {
            if (updateManifest) {
                publishedChannelEvent(feedToken)
            }
        },
        onSettled: async () => {
            await Promise.allSettled([
                queryClient.invalidateQueries({
                    queryKey: ['feeds'],
                }),
                queryClient.invalidateQueries({
                    queryKey: ['artifacts'],
                }),
            ])
        },
    })
    return mutation
}

export type FeedImageFields = 'logo' | 'banner' | 'poster'

export const usePageImage = (page: FeedDetails, field: FeedImageFields) => {
    return useQuery({
        queryKey: ['feeds', page.token, field],

        queryFn: async () => {
            const result = await axios.get<{ url: string }>(
                `${import.meta.env.VITE_API_URL_BASE ?? ''}/api/feed_config_image/${
                    page.token
                }/${field}`,
                { validateStatus: status => status === 404 || status < 400 },
            )
            return result.data.url
        },
    }).data
}

export const useToggleItemsInFeed = () => {
    const _update = async (feedToken: string, itemTokens: string[], remove: boolean) => {
        const res = await axios.post(`/api/toggle_items_in_feed/${feedToken}`, {
            itemTokens,
            remove,
        })
        return res.data
    }
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: ({
            feedToken,
            itemTokens,
            remove,
        }: {
            feedToken: string
            itemTokens: string[]
            remove: boolean
        }) => _update(feedToken, itemTokens, remove),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
            await queryClient.cancelQueries({
                queryKey: ['role_rep_response_library'],
            })
            await queryClient.cancelQueries({
                queryKey: ['items_in_feed'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['feeds'],
            })
            queryClient.invalidateQueries({
                queryKey: ['role_rep_response_library'],
            })
            queryClient.invalidateQueries({
                queryKey: ['items_in_feed'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

export const useItemsInFeed = (feedToken: string) => {
    const _get = async (feedToken: string) => {
        const res = await axios.get<ItemInFeed[]>(`/api/item_in_feed/${feedToken}`)
        return res.data
    }

    const query = useQuery({
        queryKey: ['items_in_feed', feedToken],
        queryFn: () => _get(feedToken),
    })
    return query
}

const transformFeed = (rawFeed: FeedDetails) => {
    const options = rawFeed.options
    return {
        ...rawFeed,
        options: {
            ...options,
            allowedFilterCategories:
                options.allowedFilterCategories ?? (options.page_config?.v16 ? [] : undefined),
        },
    }
}
export const useFeedFromUrl = (token?: string, enabled?: boolean) => {
    const _get = async (token: string) => {
        const res = await axios.get<FeedDetails>(`/api/feed_configs/${token}`)
        return res.data
    }
    const { token: _token } = useParams()
    const realToken = token ?? _token
    const query = useQuery({
        queryKey: ['feeds', realToken],
        queryFn: async () => transformFeed(await _get(realToken ?? '')),
        enabled: enabled ?? !!realToken,
    })
    return query
}

export const feedDetailsQueryArg = (token?: string) => ({
    queryKey: ['feeds', token],
    queryFn: async () => {
        return transformFeed((await axios.get<FeedDetails>(`/api/feed_configs/${token}`)).data)
    },
    enabled: !!token,
})
export const useFeedDetails = (token: string, enabled?: boolean) => {
    const query = useQuery({ ...feedDetailsQueryArg(token), enabled: enabled ?? !!token })
    return query
}

export const useCreateFeed = () => {
    const queryClient = useQueryClient()
    const _post = async (
        name: string,
        initialItemTokens?: string[],
        isReel?: boolean,
        is_ad_hoc?: boolean,
        startAsV8?: boolean,
        programs?: string[],
        type?: string,
        tags?: string[],
        startAsV16?: boolean,
    ) => {
        const res = await axios.post<Feed>('/api/feed_configs', {
            name,
            initialItemTokens,
            startAsV8,
            startAsV16,
            isReel,
            is_ad_hoc,
            programs,
            type,
            tags,
        })
        return res.data
    }
    const mutation = useMutation({
        mutationFn: ({
            name,
            initialItemTokens,
            startAsV8,
            isReel,
            is_ad_hoc,
            startAsV16,
            programs,
            type,
            tags,
        }: {
            name: string
            initialItemTokens?: string[]
            startAsV8?: boolean
            startAsV16?: boolean
            isReel?: boolean
            is_ad_hoc?: boolean
            programs?: string[]
            type?: string
            tags?: string[]
        }) =>
            _post(
                name,
                initialItemTokens,
                isReel,
                is_ad_hoc,
                startAsV8,
                programs,
                type,
                tags,
                startAsV16,
            ),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: ['feeds'],
            })
            await queryClient.cancelQueries({
                queryKey: ['artifacts'],
            })
        },
        onSuccess: feed => {
            createChannelEvent(feed.token)
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['feeds'],
            })
            queryClient.invalidateQueries({
                queryKey: ['artifacts'],
            })
        },
    })
    return mutation
}

interface MakeGifParams {
    numClips: number
    resMax: [number, number]
    fps: number
    duration: number
    fuzz: number
    token: string
    program: 'imageio' | 'ImageMagick' | 'ffmpeg' | string
    opt: 'nq' | 'wu' | 'optimizeplus' | 'OptimizeTransparency' | undefined
    gifMode?: number
    clipsPerVideo?: number
    timePerVideo?: number
    playbackRate?: number
}
export const useMakeGif = () => {
    return useMutation({
        mutationFn: async (p: MakeGifParams) => {
            return axios.post('/api/make_gif', p)
        },
    })
}
export const useGifHead = (token: string | undefined) => {
    return useQuery({
        queryKey: ['gifhead', token],

        queryFn: async () => {
            // const client = axios.create() // we want this request to be 'simple' for CORS; none of the default headers or params
            // delete client.defaults.headers.common
            // delete client.defaults.params
            // const res = await axios.head(`${import.meta.env.VITE_CA_BASE_URI}/candidateAppData/${token}/manifest.json.gif`,
            // {
            //     params: {c: undefined},
            //     headers: {
            //         "x-wt-c": undefined,
            //         "x-csrftoken": undefined,
            //     }

            // }
            // )
            const res = await fetch(
                `${storageDomain}/candidateAppData/${token}/manifest.json.gif`,
                {
                    method: 'HEAD',
                },
            )
            return res
        },

        enabled: !!token,
        refetchInterval: 30000,
    })
}

export const useFeedTags = () => {
    return useQuery({
        queryKey: ['feeds', 'tags'],

        queryFn: async () => {
            return (await axios.get<{ tags: string[] }>('/api/feed_tags')).data.tags
        },
    })
}

const getFeedViewss = async (tokens: string[]) => {
    return (
        await axios.get<Record<string, number[]>>('/api/feed_viewss', {
            params: { tokens },
        })
    ).data
}

const getViewss = batshit.create({
    fetcher: getFeedViewss,
    resolver: batshit.indexedResolver(),
    scheduler: batshit.windowedFiniteBatchScheduler({
        windowMs: 500,
        maxBatchSize: 10,
    }),
})

export const useFeedViewss = (token: string | undefined) => {
    return useQuery({
        queryKey: ['feeds', token, 'viewss'],
        queryFn: async () => {
            return token ? getViewss.fetch(token) : undefined
        },
        enabled: !!token,
    })
}
