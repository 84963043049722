import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useCompanyDetails } from "services/company_service";


export const useLayout202409 = () => {
    const { data: companyDetails, isPending } = useCompanyDetails();
    const sb = useSearchParams()[0].get('sb');
    const isStripeClient = useMemo(
        () => !!companyDetails?.billing_info.stripe_customer_id,
        [companyDetails]
    );
    if (sb && sb === 't') {
        return { value: true, isPending: false };
    } else if (sb && sb === 'f') {
        return { value: false, isPending: false };
    }


    return {
        value: companyDetails?.settings.forceLayout202409 ?? isStripeClient,
        isPending
    };
};
