import React, { lazy } from 'react'
import { InterviewDetailsQuestionsProps } from './InterviewDetailsQuestions'
const LazyInterviewDetailsQuestions = lazy(() => import('./InterviewDetailsQuestions'))

const InterviewDetailsQuestions = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & InterviewDetailsQuestionsProps,
) => <LazyInterviewDetailsQuestions {...props} />

export default InterviewDetailsQuestions
