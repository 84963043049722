import React, { lazy, Suspense } from 'react'
import { PagesMetricsProps } from './PagesMetrics'
const LazyPagesMetrics = lazy(() => import('./PagesMetrics'))

const PagesMetrics = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & PagesMetricsProps,
) => (
    <Suspense fallback={null}>
        <LazyPagesMetrics {...props} />
    </Suspense>
)

export default PagesMetrics
