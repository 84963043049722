import React, { lazy, Suspense } from 'react'
import { ProgramBannerProps, ProgramsProps } from './Programs'

const LazyPrograms = lazy(() => import('./Programs'))

const Programs = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ProgramsProps,
) => (
    <Suspense fallback={null}>
        <LazyPrograms {...props} />
    </Suspense>
)

export default Programs

const LazyProgramBanner = lazy(() => import('./Programs').then(m => ({ default: m.ProgramBanner })))

export const ProgramBanner = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ProgramBannerProps,
) => (
    <Suspense fallback={null}>
        <LazyProgramBanner {...props} />
    </Suspense>
)
