import React, { lazy } from 'react'
import { CollectionsListProps } from './CollectionsList'
const LazyCollectionsList = lazy(() => import('./CollectionsList'))

const CollectionsList = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & CollectionsListProps,
) => <LazyCollectionsList {...props} />

export default CollectionsList
