import React, { lazy, Suspense } from 'react'
import { ApplicantsProps } from './Applicants'
const LazyApplicants = lazy(() => import('./Applicants'))

const Applicants = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & ApplicantsProps,
) => (
    <Suspense fallback={null}>
        <LazyApplicants {...props} />
    </Suspense>
)

export default Applicants
