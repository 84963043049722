import React, { lazy, Suspense } from 'react'
import { ProgramDetailsProps } from './ProgramDetails'
const LazyProgramDetails = lazy(() => import('./ProgramDetails'))

const ProgramDetails = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & ProgramDetailsProps,
) => (
    <Suspense fallback={null}>
        <LazyProgramDetails {...props} />
    </Suspense>
)

export default ProgramDetails
