export const generateNonDBToken = (numBytes: number) => {
    const array = new Uint32Array(numBytes)
    window.crypto.getRandomValues(array)
    const str = Array.from(array.values())
        .map(v => v.toString(36))
        .join('')
    return str
}

export const getNewSessionId = () => {
    const str = generateNonDBToken(4)
    const BAD_TOKENS = ['1qn5x9014vq4h11phms3w6nkm9i']
    return 'wta-' + BAD_TOKENS.every(bt => !str.includes(bt)) ? str : 'bt-' + str
}

export const getNewSessionExpirationTime = () => {
    const now = new Date()
    const sessionExpirationTime = new Date()
    sessionExpirationTime.setTime(now.getTime() + 30 * 60 * 1000)
    return sessionExpirationTime
}
