import React, { lazy, Suspense } from 'react'
import { CollectionVideosProps } from './CollectionVideos'

const LazyCollectionVideos = lazy(() => import('./CollectionVideos'))

const CollectionVideos = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CollectionVideosProps,
) => (
    <Suspense fallback={null}>
        <LazyCollectionVideos {...props} />
    </Suspense>
)

export default CollectionVideos
