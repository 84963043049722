import React, { lazy, Suspense } from 'react'
import {
    QuestionsScreenPromptPhaseVideoPlayerModalProps,
    RecordResponsesProps,
    V2LayoutProps,
} from './RecordResponses'

const LazyRecordResponse = lazy(() => import('./RecordResponses'))

const RecordResponses = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & RecordResponsesProps,
) => (
    <Suspense fallback={null}>
        <LazyRecordResponse {...props} />
    </Suspense>
)

export default RecordResponses

const LazyV2Layout = lazy(() => import('./RecordResponses').then(m => ({ default: m.V2Layout })))

export const V2Layout = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & V2LayoutProps,
) => (
    <Suspense fallback={null}>
        <LazyV2Layout {...props} />
    </Suspense>
)

const LazyPromptPlayerModal = lazy(() =>
    import('./RecordResponses').then(m => ({
        default: m.QuestionsScreenPromptPhaseVideoPlayerModal,
    })),
)

export const QuestionsScreenPromptPhaseVideoPlayerModal = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & QuestionsScreenPromptPhaseVideoPlayerModalProps,
) => (
    <Suspense fallback={null}>
        <LazyPromptPlayerModal {...props} />
    </Suspense>
)
