import React, { lazy } from 'react'
import { AsyncCandidatesHomeProps } from './AsyncCandidatesHome'
const LazyAsyncCandidatesHome = lazy(() => import('./AsyncCandidatesHome'))

const AsyncCandidatesHome = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & AsyncCandidatesHomeProps,
) => <LazyAsyncCandidatesHome {...props} />

export default AsyncCandidatesHome
