import React, { lazy, Suspense } from 'react'
import { CollectionsProps } from './Collections'

const LazyCollections = lazy(() => import('./Collections'))

const Collections = (
    props: JSX.IntrinsicAttributes & { children?: React.ReactNode } & CollectionsProps,
) => (
    <Suspense fallback={null}>
        <LazyCollections {...props} />
    </Suspense>
)

export default Collections
