import { BasePage } from 'components/BasePage'
import LoginPage from 'pages/Login/LoginPage.lazy'
import React, { useMemo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { WTProductNames, useCompanyDetails } from 'services/company_service'
import { useUserStatus, UserStatus } from 'services/login_service'

interface LoginRequiredProps {
    children?: JSX.Element

    product?: WTProductNames
}

const LoginRequired = ({ children, product }: LoginRequiredProps) => {
    // TODO check TOS agreement?  we have some terms.. somewhere to show them if they didn't yet agree?
    const userStatus = useUserStatus()
    const { data: company } = useCompanyDetails({
        enabled: ![UserStatus.NotLoggedIn, UserStatus.Loading].includes(userStatus),
    })
    const allowedProducts = useMemo(
        () => company?.account_limit_info?.products ?? ['saas'],
        [company],
    )
    if (userStatus === UserStatus.Loading) {
        return null
    }
    if (userStatus === UserStatus.NotLoggedIn) {
        return (
            <BasePage>
                <LoginPage />
            </BasePage>
        )
    }

    if (product && !allowedProducts.includes(product)) {
        // probably need some default page??
        return (
            <Navigate
                replace
                to={{ pathname: '/jobBoard/employers', search: window.location.search }}
            />
        )
    }

    return children ? children : <Outlet />
}

export default LoginRequired
