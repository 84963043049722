import axios from 'axios'
import { storageDomain } from './BackstageUtils'
import {
    PublicCandidateShowcaseDetails,
    PublicCandidateShowcaseNotAllowedDetails,
} from './showcase_service'

const axiosExt = axios.create({
    withCredentials: false,
    params: '',
    validateStatus: status => (status >= 200 && status < 400) || status === 404,
})
export const publicShowcaseQueryArg = (token?: string) => ({
    queryKey: ['showcases', 'public', token],
    queryFn: async () => {
        const s3url = `${storageDomain}/showcaseData/${token}/showcase.json`
        const r1 = await axiosExt.get<PublicCandidateShowcaseDetails>(s3url)
        if (r1.status === 200) {
            return r1.data
        }
        const res = await axios.get<
            PublicCandidateShowcaseDetails | PublicCandidateShowcaseNotAllowedDetails
        >('/api/candidate_showcase_public/' + token)
        return res.data
    },

    enabled: !!token,
})
