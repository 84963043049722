import React, { lazy, Suspense } from 'react'
import { CreateProps } from './Create'

const LazyCreate = lazy(() => import('./Create'))

const Create = (
    props: JSX.IntrinsicAttributes & {
        children?: React.ReactNode
    } & CreateProps,
) => (
    <Suspense fallback={null}>
        <LazyCreate {...props} />
    </Suspense>
)

export default Create
