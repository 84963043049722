import type { ReactNode } from 'react'
import { ClientPermissions } from 'services/permission_service'
import { createSearchParams, NavigateFunction } from 'react-router-dom'
import { Tooltip } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import LinkIcon from 'assets/jam-master/icons/link.svg?react'
import SettingsIcon from 'assets/jam-master/icons/cog.svg?react'
import ContentIcon from 'assets/grid-view.svg?react'
import OutboxIcon from 'assets/send-message-1.svg?react'
import ChannelsIcon from 'assets/video-gallery-2-2.svg?react'
import CollectionsIcon from 'assets/wireframe-1.svg?react'
import AnalyticsIcon from 'assets/chart-bar-32-2.svg?react'
import ContributorsIcon from 'assets/multiple-19.svg?react'
import type { CompanyDetails } from 'services/company_service'
import ApplicantsIcon from 'assets/ic24-users.svg?react'
import type { MenuItem } from './Header'
import { HeaderIcon } from './HeaderIcon'
import { MenuIconWrapper } from './MenuIconWrapper'
const CANDIDATE_APP_DOMAIN = import.meta.env.VITE_CA_BASE_URI
export const MainMenuOptions: MenuItem[] = [
    {
        name: 'content',
        displayName: 'Content',
        icon: (
            <MenuIconWrapper scale={0.65}>
                <ContentIcon />
            </MenuIconWrapper>
        ),
        defaultVisibility: true,
        accountToggleable: false,
    },
    // {
    //     name: 'library',
    //     displayName: 'Library',
    //     icon: (
    //         <MenuIconWrapper scale={0.65}>
    //             <ContentIcon />
    //         </MenuIconWrapper>
    //     ),
    //     defaultVisibility: true,
    //     accountToggleable: false,
    // },
    {
        name: 'outbox',
        displayName: 'Requests',
        defaultVisibility: true,
        icon: (
            <MenuIconWrapper scale={0.65}>
                <OutboxIcon />
            </MenuIconWrapper>
        ),
    },

    {
        name: 'channels',
        displayName: `Channels`,
        icon: (
            <MenuIconWrapper scale={0.65}>
                <ChannelsIcon />
            </MenuIconWrapper>
        ),
        defaultVisibility: true,
    },
    {
        name: 'collections',
        displayName: 'Pages',
        icon: (
            <MenuIconWrapper scale={0.65}>
                {' '}
                <CollectionsIcon style={{ color: 'yellowgreen' }} />
            </MenuIconWrapper>
        ),
        defaultVisibility: true,
        staffOnly: true,
    },
    {
        name: 'analytics',
        displayName: 'Analytics',
        icon: (
            <MenuIconWrapper scale={0.65}>
                <AnalyticsIcon />
            </MenuIconWrapper>
        ),
        defaultVisibility: true,
        accountToggleable: false,
    },

    {
        name: 'applicants',
        displayName: `Talent`,
        icon: <ApplicantsIcon />,
        defaultVisibility: true,
        accountToggleable: true,
    },
    {
        name: 'artifacts',
        displayName: 'Share',
        icon: <SendOutlined style={{ color: 'yellowgreen' }} />,
        staffOnly: true,
        defaultVisibility: true,
        accountToggleable: false,
    },
]
export const MainMenuIcons: {
    name: string
    displayName: string
    userPermissionsAllowed: (perms: ClientPermissions[], isStaff: boolean) => boolean
    component: (
        location: any,
        navigate: NavigateFunction,
        searchParams: URLSearchParams,
        careersPageExists?: boolean,
        companyDetails?: CompanyDetails,
    ) => ReactNode
    accountToggleable?: boolean
}[] = [
    {
        name: 'contributor',
        displayName: 'Contributors',
        userPermissionsAllowed: (perms: ClientPermissions[], isStaff: boolean) => true,
        component: (
            location: any,
            navigate: NavigateFunction,
            searchParams: URLSearchParams,
            careersPageExists?: boolean,
            companyDetails?: CompanyDetails,
        ) => (
            <Tooltip
                title="Manage Contributors"
                key="contributors"
            >
                <HeaderIcon
                    key="settings"
                    $active={location.pathname.indexOf('/contributors') > -1}
                    onClick={() => {
                        navigate({
                            pathname: '/contributors',
                            search: `?${createSearchParams(searchParams)}`,
                        })
                    }}
                >
                    <ContributorsIcon />
                </HeaderIcon>
            </Tooltip>
        ),
    },

    {
        name: 'quickLinks',
        displayName: 'Quick Links',
        userPermissionsAllowed: (perms: ClientPermissions[], isStaff: boolean) => true,
        component: (
            location: any,
            navigate: NavigateFunction,
            searchParams: URLSearchParams,
            careersPageExists?: boolean,
            companyDetails?: CompanyDetails,
        ) => (
            <Tooltip
                title="Open Quick Links"
                key="quicklinks"
            >
                <a
                    style={{
                        pointerEvents: !careersPageExists || !companyDetails ? 'none' : undefined,
                    }}
                    href={`${CANDIDATE_APP_DOMAIN}/quick-links/${companyDetails?.slug_name ?? ''}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <HeaderIcon>
                        <LinkIcon />
                    </HeaderIcon>
                </a>
            </Tooltip>
        ),
    },

    {
        name: 'settings',
        displayName: 'Settings',
        userPermissionsAllowed: (perms: ClientPermissions[], isStaff: boolean) =>
            perms?.includes(ClientPermissions.CAN_VIEW_SETTINGS) || isStaff,
        component: (
            location: any,
            navigate: NavigateFunction,
            searchParams: URLSearchParams,
            careersPageExists?: boolean,
            companyDetails?: CompanyDetails,
        ) => (
            <HeaderIcon
                key="settings"
                $active={location.pathname.indexOf('/settings') > -1}
                onClick={() => {
                    navigate({
                        pathname: '/settings',
                        search: `?${createSearchParams(searchParams)}`,
                    })
                }}
            >
                <SettingsIcon />
            </HeaderIcon>
        ),
    },
]
